import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import ErrorMessage from "../../Components/errormsg";
import { API } from "aws-amplify";
import { useAppContext } from "../../useContext";
import "../HelpContact/helpContact.css";
import mixpanel from "mixpanel-browser";

// imports originally here but not used
// import ReactPlayer from "react-player";

const HelpContact = (props) => {
  const { email, provider, providerId, userId } = useAppContext();

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const [sansAuthUserData, setSansAuthUserData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(true);
  const size = props.windowSize;

  mixpanel.track("P HelpContact PgVw", {
    "Event Type": "Page View",
  });

  var submitForm = (data) => {
    // console.log(data);
    setSansAuthUserData(data);
    API.post("referall-provider", "addhelpform", {
      body: {
        data,
        user_id: userId,
        email: email,
        provider_name: provider,
        provider_id: providerId,
      },
    })
      .then((response) => {
        // console.log(response);
        mixpanel.track("P Contact Submit", {
          "Event Type": "User Action",
        });
      })
      .catch((error) => {
        console.log(error);
        mixpanel.track("P ConsoleLog Error", {
          "Event Type": "API Call",
          "Error Message": error,
          "API Name": "addhelpform",
          "File Name": "HelpContact.js",
          "File Line": 47,
        });
      });
    setFormSubmitted(!formSubmitted);
  };

  return (
    <div
      className={
        size[0] < 768
          ? "width98Percent helpContact"
          : "width80Percent helpContact"
      }
    >
      <h1 className="header">Contact Us</h1>
      {formSubmitted ? (
        <p>We'll get back to you in 1-2 business days.</p>
      ) : (
        <>
          <p>Thank you for your message.</p>
          <p>We'll get back to you in 1-2 business days.</p>
          {provider.length !== 0 && (
            <p>
              Look for a response in your{" "}
              <span className="bold">
                {provider.length === 0 ? sansAuthUserData.email : email}
              </span>{" "}
              email.
            </p>
          )}
        </>
      )}
      {formSubmitted ? (
        <form className={size[0] < 768 ? "width95Percent" : " width50Percent "}>
          {/* <label>Your name</label>
                <input
                    className="width100Percent height35 border1Grey0 marginBottom15"
                    name="provider_user_name"
                    ref={register({ required: true })}
                />
                <ErrorMessage error={errors.provider_user_name} />
                <label>Your organization</label>
                <input
                    className="width100Percent height35 border1Grey0 marginBottom15"
                    name="provider_organization_name"
                    ref={register({ required: true })}
                />
                <ErrorMessage error={errors.provider_organization_name} />
                <label>Your email</label>
                <input
                    className="width100Percent height35 border1Grey0 marginBottom15"
                    name="provider_email"
                    ref={register({ required: true })}
                />
                <ErrorMessage error={errors.provider_email} /> */}
          {provider.length === 0 && (
            <>
              <p>
                <label htmlFor="email">
                  <strong>Your email:</strong>
                </label>
                <input
                  name="email"
                  ref={register({
                    required: true,
                    // maxLength: 50,
                    validate: {
                      maxLength: (v) =>
                        v.length <= 50 ||
                        "The email should have at most 50 characters",
                      matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Email address must be a valid address",
                    },
                  })}
                  className={
                    size[0] >= 768
                      ? "width100Percent border1Grey0"
                      : "border1Grey0 width95vw"
                  }
                ></input>
                {formState.errors.email?.message && (
                  <small className="colorRed">
                    {formState.errors.email.message}
                  </small>
                )}
              </p>
              <p>
                <label htmlFor="provider_name">
                  <strong>Organization Name:</strong>
                </label>
                <input
                  name="provider_name"
                  ref={register({
                    required: true,
                    // maxLength: 50, // commented out so validation error would kick in
                    validate: {
                      maxLength: (v) =>
                        v.length <= 30 ||
                        "The organization name should have at most 30 characters",
                      matchPattern: (v) =>
                        /^[a-zA-Z0-9 .+]*$/.test(v) ||
                        "The message must have valid text.",
                    },
                  })}
                  className={
                    size[0] >= 768
                      ? "width100Percent border1Grey0"
                      : "border1Grey0 width95vw"
                  }
                ></input>
                {formState.errors.provider_name?.message && (
                  <small className="colorRed">
                    {formState.errors.provider_name.message}
                  </small>
                )}
              </p>
            </>
          )}
          <p className="marginBottom0">
            <label htmlFor="provider_message">
              <strong>Add your message</strong>
              <br></br>
              {/* {!provider ||
                (provider.length === 0 && (
                  <span className="label-help-text">
                    If you would like a response, please add your email address.
                  </span>
                ))} */}
            </label>
          </p>
          <div className="marginBottom26">
            <textarea
              className={
                size[0] >= 768
                  ? "width100Percent border1Grey0"
                  : "border1Grey0 marginBottom26 width95vw"
              }
              name="provider_message"
              type="text"
              ref={register({
                required: true,
                // maxLength: 500, //commented out so the validation message would kick in.
                validate: {
                  maxLength: (v) =>
                    v.length <= 500 ||
                    "The message should have at most 500 characters",
                  matchPattern: (v) =>
                    /^[a-zA-Z ,.0-9]*$/.test(v) ||
                    "The message must have valid text.",
                },
              })}
              rows="12"
              cols={size[0] >= 768 ? "60" : ""}
            />
            {formState.errors.provider_message?.message && (
              <small className="colorRed marginTop0">
                {formState.errors.provider_message.message}
              </small>
            )}
          </div>
          <div
            className={
              size[0] >= 768
                ? "flexRow justifySpaceBetween width100Percent"
                : "flexRow justifySpaceBetween width95vw"
            }
          >
            <Link to="/help">
              <button className="cancel-button">Cancel</button>
            </Link>
            <Link to="/help">
              <button
                className={
                  !formState.isValid
                    ? "save-helpform-disabled"
                    : "save-helpform-button"
                }
                type="submit"
                disabled={!formState.isValid}
                onClick={handleSubmit(submitForm)}
              >
                Submit
              </button>
            </Link>
          </div>

          {/* For button dimming: Will need conditional rendering for the className/color. If string in the useState for yourName, yourOrg, yourEmail, and addYourMessage all have a .length that is >= 2, then give the button a className for the #A10070 color, else give the button a className for the #D5D8DD color. */}
        </form>
      ) : null}
    </div>
  );
};

export default HelpContact;
