import React from "react";
import { Switch } from "react-router-dom";
import Login from "./Pages/Login/Login";
import LeftDrawer from "./Components/LeftDrawer";
import NewUser from "./Pages/Login/NewUser";
import UnauthenticatedRoute from "./Components/Routes/UnauthenticatedRoute";
import AuthenticatedRoute from "./Components/Routes/AuthenticatedRoute";
import ProviderSignUp from "./Pages/ProviderSignUp/ProviderSignUp"
import { useAppContext } from "./useContext";
import config from "./config";

export default function Routes(props) {
  const { provider } = useAppContext();
  const { role } = useAppContext();
  const size = props.windowSize;

  return (
    <Switch>
      <UnauthenticatedRoute path="/newuser">
        <NewUser windowSize={size} />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/" exact>
        <Login windowSize={size} />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/providersignup" exact>
        <ProviderSignUp windowSize={size} />
      </UnauthenticatedRoute>
      {provider.length > 0 ? (
        <AuthenticatedRoute path="/serviceproviders" exact>
          <LeftDrawer windowSize={size} />
        </AuthenticatedRoute>
      ) : (
        <UnauthenticatedRoute path="/serviceproviders" exact>
          <LeftDrawer windowSize={size} />
        </UnauthenticatedRoute>
      )}
      <AuthenticatedRoute path="/people" exact>
        <LeftDrawer windowSize={size} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/ourprofile" exact>
        <LeftDrawer windowSize={size} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings" exact>
        <LeftDrawer windowSize={size} />
      </AuthenticatedRoute>
      {provider.length > 0 ? (
        <AuthenticatedRoute path="/help" exact>
          <LeftDrawer windowSize={size} />
        </AuthenticatedRoute>
      ) : (
        <UnauthenticatedRoute path="/help" exact>
          <LeftDrawer windowSize={size} />
        </UnauthenticatedRoute>
      )}
      {provider.length > 0 ? (
        <AuthenticatedRoute path="/helpcontact" exact>
          <LeftDrawer windowSize={size} />
        </AuthenticatedRoute>
      ) : (
        <UnauthenticatedRoute path="/helpcontact" exact>
          <LeftDrawer windowSize={size} />
        </UnauthenticatedRoute>
      )}
      {(provider === process.env.REACT_APP_PROVIDER && role === "Admin") ||
      (provider === config.provider && role === "Admin") ? (
        <AuthenticatedRoute path="/addprovider" exact>
          <LeftDrawer windowSize={size} />
        </AuthenticatedRoute>
      ) : null}
    </Switch>
  );
}
