import API from "@aws-amplify/api";
import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import React, { Component } from "react";
import editbutton from "../../Assets/editbutton.png";

export class SavedSearchesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      savedSearches: [],
    };

    this.mounted = false;
  }

  componentDidMount() {
    // console.log("savedModal props: ", this.props);
    this.mounted = true;
    if (this.mounted && this.props.appContext.email) {
      API.post("referall-provider", "getproviderssavedsearches", {
        body: {
          provider_email: this.props.appContext.email,
          provider_id: this.props.appContext.providerId,
        },
      }).then((response) => {
        // console.log("savedSearchesRes", response);
        const sortedSearches = response.sort((a, b) =>
          a.search_name.localeCompare(b.search_name)
        );
        this.mounted && this.setState({ savedSearches: sortedSearches });
        this.props.saveAllSearches(sortedSearches);
      });
    }
  }

  componentWillUnmount() {
    // cancel subscriptions
    this.mounted = false;
  }

  // When a filter selection is made, update the filters in providerTableToolbar and reflect onto the table data
  savedFiltersClick = (filterArray) => {
    // Get the filter state names for the providerTableToolbar
    var stateNamesToUpdate = [];
    filterArray.map((item) => {
      // console.log(item);
      // handle age range and exact age
      if (item.includes("Age")) {
        return stateNamesToUpdate.push(item.split(":")[0].replace(/\s/g, ""));
      } else {
        return stateNamesToUpdate.push(item.replace(/ /g, ""));
      }
    });
    this.props.savedFiltersClick(filterArray, stateNamesToUpdate);
    this.props.saveAllSearches(filterArray);
  };

  saveObj = (obj, i) => {
    // console.log(obj, this.props);
    this.props.setSavedSearchObj(obj, i);
  };

  render() {
    const { savedSearchesModalOpen } = this.props;
    // console.log(this.props);

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"modalPlacement"}
        open={savedSearchesModalOpen}
        // onClose={}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={savedSearchesModalOpen}>
          <div className="minWidth320 width42vw maxHeight85vh backgroundColorWhite borderRadius8 padding30-40-20-30">
            <h2 id="transition-modal-title" className="marginTop0">
              Saved Searches
            </h2>

            <p className="fontSize16 lineHeight18 marginBottom36">
              Select a saved search to see a list.
            </p>

            {this.state.savedSearches.length > 0 ? (
              <div className="maxHeight55vh overflowScroll">
                {this.state.savedSearches.map((obj, i) => {
                  return (
                    <div className="flexRow" key={i}>
                      <button
                        className="btnNoStyle displayBlock marginBottom30 textAlignLeft cursorPointer"
                        onClick={() => {
                          this.savedFiltersClick(
                            obj.search_filter_configuration
                          );
                          // console.log(this.props);
                          // this.props.setSavedSearchObj(obj);
                        }}
                      >
                        <p className="bold marginBottom5">{obj.search_name}</p>
                        <p>{obj.search_filter_configuration.join(", ")}</p>
                      </button>
                      <div className="edit-button">
                        <Button
                          className="edit-button"
                          onClick={() => {
                            this.saveObj(obj, i);
                            this.props.closeSavedSearchesModal();
                            this.props.openSavedSearchItem();
                          }}
                        >
                          <figure className="margin0 marginTop5">
                            <img src={editbutton} alt="Icon" />
                            <figcaption className="fontSize12 lineHeight5">
                              Edit
                            </figcaption>
                          </figure>
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="marginBottom30">You have 0 saved searches.</p>
            )}

            <button
              className="btnOffWhite border1colorBlack1 colorBlack3 fontSize14 lineHeight16 borderRadius4 padding12-16"
              onClick={this.props.closeSavedSearchesModal}
            >
              Close
            </button>
          </div>
        </Fade>
      </Modal>
    );
  }
}
