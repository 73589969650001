import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import editbutton from "../../Assets/editbutton.png";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  Button,
} from "@material-ui/core";
// import ErrorMessage from "../../Components/errormsg";
import mixpanel from "mixpanel-browser";

import NumberFormat from "react-number-format";
import "../OurProfile/ourprofile.css";
import InstagramIcon from "@material-ui/icons/Instagram";
import { API } from "aws-amplify";
import { SketchPicker } from "react-color";
import Tippy from "@tippyjs/react";
// import locationIconColor8F98A3 from "../../Assets/locationIconColor#8F98A3.png";
import { useAppContext } from "../../useContext";
// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import Slider from "@material-ui/core/Slider";
import { AddressSearchBar } from "../../Components/AddressSearchBar";

const ColorButton = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "40px",
    textAlign: "left",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "smaller",
    fontWeight: "bolder",
    letterSpacing: "normal",
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#A10070",
    "&:hover": {
      backgroundColor: "#A10070",
    },
    "&:disabled": {
      backgroundColor: "#A10070",
      opacity: 0.3,
      color: "white",
    },
  },
}))(Button);

const Basics1 = (props) => {
  const [profileData, setProfileData] = useState(null); // Data from the database. Will not be changed locally
  const [changeData, setChangeData] = useState(null); // Data from the database that CAN be changed locally from text field onChange.
  const [colorData, setColor] = useState("white");
  const [colorData2, setColor2] = useState("white");
  const [open, setOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(true);
  const { providerId, setColors } = useAppContext();

  // const [hasFocus, setFocus] = useState(false);

  const [hours, setHours] = useState("");

  // helper text for hyperlinks (website, facebook, instagram)
  const [error, setError] = useState({
    provider_website: "",
    provider_facebook: "",
    provider_instagram: "",
  });

  useEffect(() => {
    API.post("referall-provider", "getproviderprofiledata", {
      body: {
        provider_id: providerId,
      },
    }).then((response) => {
      console.log("response: ", response);
      if (
        response[0].provider_start_hours ||
        response[0].provider_close_hours
      ) {
        let startHours = response[0].provider_start_hours;
        let startHoursArray = startHours.split(":");
        let startAmPm = startHoursArray[0] >= 12 ? "PM" : "AM";
        startHoursArray[0] = startHoursArray[0] % 12 || 12;
        let startTime = startHoursArray.join(":") + ` ${startAmPm}`;
        let closeHours = response[0].provider_close_hours;
        let closeHoursArray = closeHours.split(":");
        let closeAmPm = closeHoursArray[0] >= 12 ? "PM" : "AM";
        closeHoursArray[0] = closeHoursArray[0] % 12 || 12;
        let closeTime = closeHoursArray.join(":") + ` ${closeAmPm}`;
        setHours(`${startTime} - ${closeTime}`);
      }
      setProfileData(response[0]);
      setChangeData(response[0]);
      if (response[0]) {
        setColor(response[0].avatar_colors[1]);
        setColor2(response[0].avatar_colors[0]);
      }
    });
  }, [providerId]);

  // console.log(profileData.avatar1);

  const saveProviderAddresses = (addresses, index, stateName) => {
    console.log(index, addresses);
    let addressesCopy = [...addresses];
    let addressesFiltered = addressesCopy.filter((item) => item.checked);
    let citiesSorted = addressesFiltered.sort((a, b) =>
      a.city > b.city ? 1 : -1
    );
    setChangeData({
      ...changeData,
      [stateName]:
        stateName === "provider_cities_served" ? citiesSorted : addresses,
    });
  };

  const isUrlValid = (stateName, value) => {
    if (
      (stateName === "provider_website" &&
        value.substring(0, 8) !== "https://") ||
      (stateName === "provider_instagram" &&
        value.substring(0, 8) !== "https://") ||
      (stateName === "provider_facebook" &&
        value.substring(0, 8) !== "https://")
    ) {
      setError({ ...error, [stateName]: "Https needed" });
      // console.log(error);
      return false;
    } else {
      setError({ ...error, [stateName]: "" });
      // console.log(error);
      return true;
    }
  };

  var handleEditButtonClick = () => {
    setFormSubmitted(!formSubmitted);
  };

  var handleChange = (event) => {
    var newData = { ...changeData };
    if (
      event.target.name === "provider_website" ||
      event.target.name === "provider_facebook" ||
      event.target.name === "provider_instagram"
    ) {
      isUrlValid(event.target.name, event.target.value);
    }
    newData[event.target.name] = event.target.value;
    setChangeData(newData);
  };

  const handleSingleCheckbox = (e) => {
    console.log(e.target.name, e.target.checked);
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.checked,
    });
    setChangeData({
      ...changeData,
      [e.target.name]: e.target.checked,
    });
  };

  var handleCheckboxServices = (event) => {
    var newData = { ...profileData };
    if (event.target.checked) {
      newData.provider_services_offered.push(event.target.name);
    } else {
      newData.provider_services_offered.splice(
        newData.provider_services_offered.indexOf(event.target.name),
        1
      );
    }
    setProfileData(newData);
  };

  var handleCheckboxGender = (event) => {
    var newData = { ...profileData };
    if (event.target.checked) {
      newData.provider_genders_served.push(event.target.name);
    } else {
      newData.provider_genders_served.splice(
        newData.provider_genders_served.indexOf(event.target.name),
        1
      );
    }
    setProfileData(newData);
  };

  var handleCheckboxCharact = (event) => {
    var newData = { ...profileData };
    if (event.target.checked) {
      newData.provider_other_characteristics.push(event.target.name);
    } else {
      newData.provider_other_characteristics.splice(
        newData.provider_other_characteristics.indexOf(event.target.name),
        1
      );
    }
    setProfileData(newData);
  };

  // var handleCheckboxLocation = (event) => {
  //   var newData = { ...profileData };
  //   if (event.target.checked) {
  //     newData.provider_counties_served.push(event.target.name);
  //   } else {
  //     newData.provider_counties_served.splice(
  //       newData.provider_counties_served.indexOf(event.target.name),
  //       1
  //     );
  //   }
  //   setProfileData(newData);
  // };

  var handleSubmit = () => {
    // console.log(changeData);
    API.post("referall-provider", "addproviderprofilebasics", {
      body: {
        profileData: changeData,
        provider_id: providerId,
        avatar_colors: [colorData2, colorData],
      },
    })
      .then((response) => {
        // console.log(response);
        mixpanel.track("P OurProfBasicUpdate Save", {
          "Event Type": "User Action",
        });
        setColors([colorData2, colorData]);
        setChangeData(null);
      })
      .catch((error) => {
        console.log(error);
        mixpanel.track("P ConsoleLog Error", {
          "Event Type": "API Call",
          "Error Message": error,
          "API Name": "addproviderprofilebasics",
          "File Name": "Basics1.js",
          "File Line": 229,
        });
      });
  };

  // const useStyles = makeStyles({
  //   root: {
  //     width: 300,
  //   },
  // });

  // // Age Slider
  // const classes = useStyles();
  // const [value, setValue] = React.useState([16, 65]);

  // // Age Slider
  // const handleChangeAge = (event, newValue) => {
  //   event.preventDefault();
  //   console.log(newValue, changeData);
  //   setValue(newValue);
  //   var newData = changeData;
  //   newData.provider_ages_served = newValue;
  //   // console.log(newData);
  //   setChangeData(newData);
  //   // var newValue = { ...profileData };
  //   // if (event.target.checked) {
  //   //   newValue.provider_ages_served.push(event.target.name);
  //   // } else {
  //   //   newValue.provider_ages_served.splice(
  //   //     newValue.provider_ages_served.indexOf(event.target.name)
  //   //   );
  //   // }
  //   // setProfileData(newData);
  // };

  const handleAgeInput = (e) => {
    const ageValue = +e.target.value;
    const newData = { ...changeData };
    // console.log(ageValue, e.target.name);
    if (e.target.name === "AgeRangeMin") {
      newData.provider_ages_served[0] = ageValue;
    } else {
      newData.provider_ages_served[1] = ageValue;
    }
    setProfileData(newData);
  };

  return (
    // TODO: remove all the default value for the checkboxes

    <div className=" fontSize16 lineHeight21">
      {/* Button, on click, change state to allow user to see form */}
      {formSubmitted === true && profileData ? (
        <div className="basicsform">
          <div>
            <p className="marginBottom10 bold">Hours</p>
            {profileData.provider_start_hours &&
            profileData.provider_close_hours ? (
              // <p>
              //   {profileData.provider_start_hours} -{" "}
              //   {profileData.provider_close_hours}
              // </p>
              <p>{hours}</p>
            ) : null}

            <p
              className={
                !profileData.provider_phone
                  ? "colorGrey3 marginBottom10 bold"
                  : "marginBottom10 bold"
              }
            >
              Phone
            </p>
            <div className="flexRow">
              <a href={`tel:${profileData.provider_phone}`}>
                <p className={!profileData.provider_phone ? "colorGrey3" : ""}>
                  {profileData.provider_phone}
                </p>
              </a>
              {profileData.provider_phone_ext && (
                <>
                  <span className="paddingLeft8 bold paddingRight5">Ext.</span>
                  <p>{profileData.provider_phone_ext}</p>
                </>
              )}
            </div>
            <p
              className={
                !profileData.provider_hotline
                  ? "colorGrey3 marginBottom10 bold"
                  : "marginBottom10 bold"
              }
            >
              24-hr Hotline
            </p>
            <div className="flexRow">
              <a href={`tel:${profileData.provider_hotline}`}>
                <p
                  className={!profileData.provider_hotline ? "colorGrey3" : ""}
                >
                  {profileData.provider_hotline}
                </p>
              </a>
              {profileData.provider_hotline_ext && (
                <>
                  <span className="paddingLeft8 bold paddingRight5">Ext.</span>
                  <p>{profileData.provider_hotline_ext}</p>
                </>
              )}
            </div>
            <p className="marginBottom10 bold">Address</p>
            <AddressSearchBar
              name="provider_addresses"
              index="provider_addresses"
              state="display"
              parentData={
                profileData.provider_addresses
                  ? profileData.provider_addresses
                  : []
              }
              updateParent={saveProviderAddresses}
              redSubLabel="Do not disclose confidential locations."
            />

            {/* <div className="flexRow">
              <p>{profileData.provider_address1}</p>
              <img
                className="height20 marginLeft15"
                src={locationIconColor8F98A3}
                alt="location-icon"
              />
            </div>

            <div className="flexRow">
              <p>{profileData.provider_address2}</p>
              <img
                className="height20 marginLeft15"
                src={locationIconColor8F98A3}
                alt="location-icon"
              />
            </div>

            <div className="flexRow">
              <p>{profileData.provider_address3}</p>
              <img
                className="height20 marginLeft15"
                src={locationIconColor8F98A3}
                alt="location-icon"
              />
            </div> */}

            <br></br>
            <p className="marginBottom10 bold">Ages Served</p>

            {/* Age Slider */}
            <p>
              We serve people ages{" "}
              {profileData.provider_ages_served.length > 0
                ? profileData.provider_ages_served[0]
                : 0}{" "}
              to{" "}
              {profileData.provider_ages_served.length > 0
                ? profileData.provider_ages_served[1]
                : 65}
              .
              {/* Age slider should be connected to backend in order to update the values when Save button is clicked.  
              {/* <p> We serve people ages {profileData.provider_ages_served.value[0]}{" "}
              to {profileData.provider_ages_served.value[1]}. </p>*/}
            </p>
            <br></br>
            <p className="marginBottom10 bold">Website &amp; Social Media </p>
            <a
              href={profileData.provider_website}
              target="_blank"
              rel="noreferrer"
            >
              <p className="url-css">{profileData.provider_website}</p>
            </a>
            <a
              href={profileData.provider_facebook}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href={profileData.provider_instagram}
              target="_blank"
              rel="noreferrer"
              className="marginBottom10"
            >
              <InstagramIcon />
            </a>
            {/* </div> //closing for hours */}

            {/* --------------------------- non edit Profile -------------------------------------------- */}
            <div className="nonEditFormProfile ">
              <p className="bold marginTop25 marginBottom10  ">
                Locations Served
              </p>
              {profileData.is_national === true && (
                <span>
                  This organization serves anyone located in the United States.
                </span>
              )}
              {/* <FormGroup className="marginBottom20">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Alameda County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Alameda County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Alameda County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Contra Costa County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Contra Costa County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Contra Costa County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Marin County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Marin County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Marin County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Monterey County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Monterey County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Monterey County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "San Diego County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="San Diego County"
                      color="primary"
                      disabled
                    />
                  }
                  label="San Diego County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "San Francisco County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="San Francisco County"
                      color="primary"
                      disabled
                    />
                  }
                  label="San Francisco County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "San Mateo County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="San Mateo County"
                      color="primary"
                      disabled
                    />
                  }
                  label="San Mateo County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Santa Cruz County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Santa Cruz County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Santa Cruz County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Solano County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Solano County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Solano County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Stanislaus County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Stanislaus County"
                      color="primary"
                      disabled
                    />
                  }
                  label="Stanislaus County"
                />
              </FormGroup> */}
              <AddressSearchBar
                name="provider_cities_served"
                index="provider_cities_served"
                state="display"
                parentData={
                  profileData.provider_cities_served
                    ? profileData.provider_cities_served
                    : []
                }
                updateParent={saveProviderAddresses}
              />
            </div>
          </div>

          {/* --------------------------- non edit Profile -------------------------------------------- */}
          <div>
            {/* <p className="marginBottom10 bold">Ages Served</p> */}
            {/* <FormGroup className="marginBottom20"> */}
            {/* <FormControlLabel
                className={profileData.under18 ? "labelbold" : "labeldefault"}
                control={
                  <Checkbox
                    checked={profileData.provider_ages_served.includes(
                      "Under 18 yrs old"
                    )}
                    name="under18"
                    color="primary"
                    disabled
                  />
                }
                label="Under 18 yrs old"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_ages_served.includes(
                      "16 - 24 years old"
                    )}
                    defaultValue={profileData.under24}
                    name="under24"
                    color="primary"
                    disabled
                  />
                }
                label="16 - 24 years old"
              />
              {/* Example: If "18 years old and older" exist in the provider_ages_served array check true if not false */}
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.provider_ages_served.includes(
                    "18 years old and older"
                  )}
                  // defaultValue={profileData.ages_served}
                  name="over18"
                  color="primary"
                  disabled
                />
              }
              label="18 years old and older"
            />{" "}
            */}{" "}
            {/* </FormGroup> */}
            {/* --------------------------- non edit Profile -------------------------------------------- */}
            <p className="marginBottom10 bold">Types of Services offered</p>
            <FormGroup className="marginBottom20 basicsServices">
              <FormControlLabel
                control={
                  <Checkbox
                    className={
                      profileData.housing ? "labelbold" : "labeldefault"
                    }
                    checked={profileData.provider_services_offered.includes(
                      "Housing and Shelter"
                    )}
                    defaultValue={profileData.housing}
                    name="housing"
                    color="primary"
                    disabled
                  />
                }
                label="Housing and Shelter"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Workforce Development"
                    )}
                    defaultValue={profileData.workforcedevelopment}
                    name="workforcedevelopment"
                    color="primary"
                    disabled
                  />
                }
                label="Workforce Development"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Legal Aid"
                    )}
                    defaultValue={profileData.legal}
                    name="legal"
                    color="primary"
                    disabled
                  />
                }
                label="Legal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Financial Assistance"
                    )}
                    name="financial"
                    color="primary"
                    disabled
                  />
                }
                label="Financial Assistance"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Mental Health"
                    )}
                    name="Mental Health"
                    color="primary"
                    disabled
                  />
                }
                label="Mental Health"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Case Management"
                    )}
                    name="casemanagement"
                    color="primary"
                    disabled
                  />
                }
                label="Case Management"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Healthcare"
                    )}
                    name="healthcare"
                    color="primary"
                    disabled
                  />
                }
                label="Healthcare"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "24-Hour Hotlines"
                    )}
                    name="24-Hour Hotlines"
                    color="primary"
                    disabled
                  />
                }
                label="24-Hour Hotlines"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Drop-In Center"
                    )}
                    name="Drop In Center"
                    color="primary"
                    disabled
                  />
                }
                label="Drop-In Center"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Peer Support"
                    )}
                    name="Peer Support"
                    color="primary"
                    disabled
                  />
                }
                label="Peer Support"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Education"
                    )}
                    name="education"
                    color="primary"
                    disabled
                  />
                }
                label="Education"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Healing Arts"
                    )}
                    name="Healing Arts"
                    color="primary"
                    disabled
                  />
                }
                label="Healing Arts"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Youth Development Services"
                    )}
                    name="Youth Development Services"
                    color="primary"
                    disabled
                  />
                }
                label="Youth Development Services"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Substance Overuse Services"
                    )}
                    name="Substance Overuse Services"
                    color="primary"
                    disabled
                  />
                }
                label="Substance Overuse Services"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Public Benefits"
                    )}
                    name="Public Benefits"
                    color="primary"
                    disabled
                  />
                }
                label="Public Benefits"
              />{" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Peer Support"
                    )}
                    name="Peer Support"
                    color="primary"
                    disabled
                  />
                }
                label="Peer Support"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Basic Needs Resource"
                    )}
                    name="Basic Needs Resource"
                    color="primary"
                    disabled
                  />
                }
                label="Basic Needs Resource"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Crisis Intervention"
                    )}
                    name="Crisis Intervention"
                    color="primary"
                    disabled
                  />
                }
                label="Crisis Intervention"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Entrepreneurship"
                    )}
                    name="Entrepreneurship"
                    color="primary"
                    disabled
                  />
                }
                label="Entrepreneurship"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Leadership Development"
                    )}
                    name="Leadership Development"
                    color="primary"
                    disabled
                  />
                }
                label="Leadership Development"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Food Access"
                    )}
                    name="Food Access"
                    color="primary"
                    disabled
                  />
                }
                label="Food Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Child Welfare and Child Protection"
                    )}
                    name="Child Welfare and Child Protection"
                    color="primary"
                    disabled
                  />
                }
                label="Child Welfare and Child Protection"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_services_offered.includes(
                      "Telephone Hotline"
                    )}
                    name="Telephone Hotline"
                    color="primary"
                    disabled
                  />
                }
                label="Telephone Hotline"
              />
            </FormGroup>
            {/* --------------------------- non edit Profile -------------------------------------------- */}
            <p className="marginBottom10 bold">Genders Served</p>
            <FormGroup className="marginBottom20">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Woman"
                    )}
                    name="woman"
                    color="primary"
                    disabled
                  />
                }
                label="Woman"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Trans-Woman"
                    )}
                    name="transwoman"
                    color="primary"
                    disabled
                  />
                }
                label="Trans-Woman"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Man"
                    )}
                    name="man"
                    color="primary"
                    disabled
                  />
                }
                label="Man"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Trans-Man"
                    )}
                    name="transman"
                    color="primary"
                    disabled
                  />
                }
                label="Trans-Man"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Gender Queer"
                    )}
                    className={profileData.nonbinary ? "disablecheck" : ""}
                    name="genderqueer"
                    color="primary"
                    disabled
                  />
                }
                label="Gender Queer"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Gender Non-Conforming"
                    )}
                    className={profileData.nonbinary ? "disablecheck" : ""}
                    name="gendernonconforming"
                    color="primary"
                    disabled
                  />
                }
                label="Gender Non-Conforming"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Gender Non-Binary"
                    )}
                    className={profileData.nonbinary ? "disablecheck" : ""}
                    name="gendernonbinary"
                    color="primary"
                    disabled
                  />
                }
                label="Gender Non-Binary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "Agender"
                    )}
                    name="agender"
                    color="primary"
                    disabled
                  />
                }
                label="Agender"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_genders_served.includes(
                      "None of these"
                    )}
                    className={profileData.nonbinary ? "disablecheck" : ""}
                    name="noneofthese"
                    color="primary"
                    disabled
                  />
                }
                label="None of these"
              />
            </FormGroup>
            {/* --------------------------- non edit Profile -------------------------------------------- */}
            <p className="marginBottom10 bold">Other Characteristics</p>
            <FormGroup className="marginBottom20">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_other_characteristics.includes(
                      "Virtual Services"
                    )}
                    defaultValue={profileData.virtualservices}
                    name="virtualservices"
                    color="primary"
                    disabled
                  />
                }
                label="Virtual Services"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_other_characteristics.includes(
                      "Open Now"
                    )}
                    defaultValue={profileData.opennow}
                    name="opennow"
                    color="primary"
                    disabled
                  />
                }
                label="Open Now"
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_other_characteristics.includes(
                      "Drop-in Available"
                    )}
                    defaultValue={profileData.dropin}
                    name="dropin"
                    color="primary"
                    disabled
                  />
                }
                label="Drop-in Available"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_other_characteristics.includes(
                      "Faith-based"
                    )}
                    defaultValue={profileData.faithbased}
                    name="faithbased"
                    color="primary"
                    disabled
                  />
                }
                label="Faith-based"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileData.provider_other_characteristics.includes(
                      "Children Allowed"
                    )}
                    defaultValue={profileData.childrenallowed}
                    name="Children Allowed"
                    color="primary"
                    disabled
                  />
                }
                label="Children Allowed"
              />
            </FormGroup>
          </div>

          <div className="edit-button">
            <Button className="edit-button" onClick={handleEditButtonClick}>
              <figure>
                <img src={editbutton} alt="Icon" />
                <figcaption>Edit</figcaption>
              </figure>
            </Button>
          </div>
        </div>
      ) : null}
      {/* -------------------------// editable Our Profile form ---------------------------*/}
      {formSubmitted === false && profileData ? (
        <div>
          <div className="basic-edit-form">
            <div>
              <div className="label-basics">
                <p className="bold marginBottom10 ">
                  Pick the colors of your organization
                </p>
                <div className="displayBlock flexRow justifySpaceBetween width80Percent">
                  <Tippy
                    interactive={true}
                    placement={"bottom"}
                    content={
                      <SketchPicker
                        false
                        color={colorData2}
                        onChange={(color) => {
                          setColor2(color.hex);
                        }}
                      />
                    }
                  >
                    <div
                      className="blog-left"
                      style={{ backgroundColor: colorData2 }}
                      onClick={() => setOpen(!open)}
                    ></div>
                  </Tippy>{" "}
                  &emsp;
                  <Tippy
                    interactive={true}
                    placement={"bottom"}
                    content={
                      <SketchPicker
                        false
                        color={colorData}
                        onChangeComplete={(color) => setColor(color.hex)}
                      />
                    }
                  >
                    <div
                      className="blog-right"
                      style={{ backgroundColor: colorData }}
                      onClick={() => setOpen(!open)}
                    ></div>
                  </Tippy>
                </div>
                <br></br>
                <p className="bold marginBottom10 ">Hours</p>
                <div className="flexRow alignCenter">
                  <input
                    type="time"
                    name="provider_start_hours"
                    defaultValue={profileData.provider_start_hours}
                    onChange={(e) => handleChange(e, "edit time input")}
                    id="provider_start_hours"
                    className="hours"
                  />
                  <span className="hours-span">to</span>
                  <input
                    type="time"
                    name="provider_close_hours"
                    defaultValue={profileData.provider_close_hours}
                    onChange={(e) => handleChange(e, "edit time input")}
                    id="provider_close_hours"
                    className="hours"
                  />
                </div>
                {/* <TextField
                  inputProps={{ style: { textAlign: "center" } }}
                  name="provider_start_hours"
                  defaultValue={profileData.provider_start_hours}
                  placeholder="8:00am"
                  id="provider_start_hours"
                  onChange={handleChange}
                /> 
                &ensp;<span>to</span>&ensp;
                <br></br>
                <TextField
                  inputProps={{ style: { textAlign: "center" } }}
                  name="provider_close_hours"
                  defaultValue={profileData.provider_close_hours}
                  placeholder="6:00pm"
                  id="provider_close_hours"
                  onChange={handleChange}
                /> */}
                <br></br>
              </div>
              <div className="label-basics">
                <p className="bold marginBottom10">Phone</p>
                <div className="flexRow alignCenter">
                  <NumberFormat
                    customInput={TextField}
                    type="tel"
                    name="provider_phone"
                    defaultValue={profileData.provider_phone}
                    onChange={handleChange}
                    format="(###) ###-####"
                    allowEmptyFormatting
                    mask="_"
                  />
                  <span className="bold paddingLeft8">Ext</span>
                  <input
                    type="number"
                    name="provider_phone_ext"
                    defaultValue={profileData.provider_phone_ext}
                    onChange={handleChange}
                    className="width100"
                  />
                </div>
              </div>
              <div className="label-basics">
                <p className="bold marginBottom10 ">24-hr Hotline</p>
                <div className="flexRow alignCenter">
                  <NumberFormat
                    customInput={TextField}
                    type="tel"
                    name="provider_hotline"
                    defaultValue={profileData.provider_hotline}
                    onChange={handleChange}
                    format="(###) ###-####"
                    allowEmptyFormatting
                    mask="_"
                  />
                  <span className="bold paddingLeft8">Ext</span>
                  <input
                    type="number"
                    name="provider_hotline_ext"
                    defaultValue={profileData.provider_hotline_ext}
                    onChange={handleChange}
                    className="width100"
                  />
                </div>
              </div>
              <div className="label-basics">
                <p className="bold marginBottom10">Address</p>
                <AddressSearchBar
                  name="provider_addresses"
                  index="provider_addresses"
                  state="edit"
                  parentData={
                    profileData.provider_addresses
                      ? profileData.provider_addresses
                      : []
                  }
                  updateParent={saveProviderAddresses}
                  redSubLabel={`This information is public to users.\n 
                    Do not disclose confidential locations.`}
                />
                {/* {hasFocus ? (
                  <div className="addresswarning">
                    <p>This information is public to users.</p>
                    <p>Do not disclose confidential information.</p>
                  </div>
                ) : null}
                <input
                  name="provider_address1"
                  className="marginBottom5"
                  defaultValue={profileData.provider_address1}
                  placeholder="100 Center Street, City, State Zip"
                  onChange={handleChange}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
                {/* <ErrorMessage error={errors.address1} /> */}
                {/* <input
                  name="provider_address2"
                  className="marginBottom5"
                  defaultValue={profileData.provider_address2}
                  placeholder="100 Center Street, City, State Zip"
                  onChange={handleChange}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                /> */}
                {/* <input
                  name="provider_address3"
                  className="marginBottom5"
                  defaultValue={profileData.provider_address3}
                  placeholder="100 Center Street, City, State Zip"
                  onChange={handleChange}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                /> */}
              </div>
              {/* Age Range Input */}
              <p className="bold marginBottom10">Ages Served</p>
              <p className="marginBottom0">Set Exact Range: </p>
              <div className="flexRow ageRangeWrapper">
                <TextField
                  name="AgeRangeMin"
                  className="ageTextField"
                  type="number"
                  style={{
                    width: "45%",
                    height: "10%",
                    borderRadius: "5px",
                    // more styles in App.css
                  }}
                  // variant="outlined"
                  defaultValue={
                    profileData.provider_ages_served.length > 0 &&
                    profileData.provider_ages_served[0] === 0
                      ? 0
                      : profileData.provider_ages_served[0]
                      ? profileData.provider_ages_served[0]
                      : null
                  }
                  InputProps={{
                    inputProps: { min: "0", max: "119" },
                  }}
                  onChange={handleAgeInput}
                  error={
                    profileData.provider_ages_served[0] >=
                      profileData.provider_ages_served[1] ||
                    profileData.provider_ages_served[0] < 0 ||
                    profileData.provider_ages_served > 119
                  }
                  helperText={
                    profileData.provider_ages_served[0] > 119
                      ? "Must be 119 or less"
                      : profileData.provider_ages_served[0] >
                          profileData.provider_ages_served[1] &&
                        profileData.provider_ages_served[0] !== 0
                      ? "Enter a lower number"
                      : profileData.provider_ages_served[0] ===
                        profileData.provider_ages_served[1]
                      ? "Ages cannot be equal"
                      : profileData.provider_ages_served[0] < 0
                      ? "Age must be 0+"
                      : null
                  }
                />
                <p className="marginTop5">to</p>
                <TextField
                  name="AgeRangeMax"
                  className="ageTextField"
                  type="number"
                  style={{
                    width: "45%",
                    height: "10%",
                    borderRadius: "5px",
                    // more styles in App.css
                  }}
                  // variant="outlined"
                  defaultValue={
                    profileData.provider_ages_served.length > 0 &&
                    profileData.provider_ages_served[1] === 0
                      ? 0
                      : profileData.provider_ages_served[1]
                      ? profileData.provider_ages_served[1]
                      : null
                  }
                  InputProps={{
                    inputProps: { min: "1", max: "120" },
                  }}
                  onChange={handleAgeInput}
                  error={
                    profileData.provider_ages_served[1] <=
                      profileData.provider_ages_served[0] ||
                    profileData.provider_ages_served[1] < 1 ||
                    profileData.provider_ages_served[1] > 120
                  }
                  helperText={
                    profileData.provider_ages_served[1] <=
                    profileData.provider_ages_served[0]
                      ? "Enter a higher number"
                      : profileData.provider_ages_served[1] > 120
                      ? "Must be 120 or less"
                      : profileData.provider_ages_served[1] < 1
                      ? "Age must be 1+"
                      : null
                  }
                />
              </div>
              {/* Age Slider
              <div className={classes.root} style={{ overflow: "hidden" }}>
                <div className="column left">
                  <Typography
                    className="slider-value"
                    id="range-slider"
                    gutterBottom
                  >
                    {profileData.provider_ages_served.length > 0
                      ? profileData.provider_ages_served[0]
                      : value[0]}{" "}
                  </Typography>
                </div>
                <div className="column middle">
                  <Slider
                    name="provider_ages_served"
                    // accepts array as value
                    defaultValue={
                      profileData.provider_ages_served.length > 0
                        ? profileData.provider_ages_served
                        : value
                    }
                    onChange={handleChangeAge}
                    aria-labelledby="range-slider"
                    max={120}
                    style={{ marginLeft: "15px" }}
                  />
                </div>
                <div className="column right">
                  <Typography
                    id="range-slider"
                    gutterBottom
                    className="slider-value"
                    style={{ marginLeft: "30px" }}
                  >
                    {profileData.provider_ages_served.length > 0
                      ? profileData.provider_ages_served[1]
                      : value[1]}{" "}
                  </Typography>
                </div>
              </div>{" "}
              */}
              <br></br>
              <p className="bold marginBottom10 ">
                Website &amp; Social Media{" "}
              </p>
              <input
                name="provider_website"
                className="marginBottom5 width300"
                placeholder="https://www.websiteName.com"
                // ref={register({
                //   pattern: {
                //     value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                //     message: "invalid email address",
                //   },
                // })}
                defaultValue={profileData.provider_website}
                onChange={handleChange}
              />
              {error.provider_website === "Https needed" ? (
                <p className="colorRed fontSize14">
                  Please enter a valid url:{" "}
                  <span className="bold">https://www.websiteName.com</span>
                </p>
              ) : null}
              {/* <p className="bold marginBottom10 ">Instagram Link </p> */}
              <input
                name="provider_instagram"
                className="marginBottom5 width300"
                defaultValue={profileData.provider_instagram}
                placeholder="https://www.instagramName.com"
                // ref={register({
                //   pattern: {
                //     value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                //     message: "invalid email address",
                //   },
                // })}
                onChange={handleChange}
              />
              {error.provider_instagram === "Https needed" ? (
                <p className="colorRed fontSize14">
                  Please enter a valid url:{" "}
                  <span className="bold">
                    https://www.instagram.com/instagramName
                  </span>
                </p>
              ) : null}
              {/* <p className="bold marginBottom10 ">Facebook Link </p> */}
              <input
                name="provider_facebook"
                className="marginBottom5 width300"
                placeholder="https://www.facebookName.com"
                defaultValue={profileData.provider_facebook}
                // ref={register({
                //   pattern: {
                //     value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                //     message: "invalid email address",
                //   },
                // })}
                onChange={handleChange}
              />
              {error.provider_facebook === "Https needed" ? (
                <p className="colorRed fontSize14">
                  Please enter a valid url:{" "}
                  <span className="bold">
                    https://www.facebook.com/facebookName
                  </span>
                </p>
              ) : null}
              {/* <ErrorMessage error={errors.url} /> */}
            </div>
            {/* ----------------------------- insert Location----------- */}

            <div className="marginLeft15">
              {/* <div>
                age checkbox : commented out  https://www.figma.com/file/KPrTqLBRowfLqpIt6KcRcl/ReferAll-Designs?node-id=6482%3A16540 */}

              {/* <p className="bold marginBottom10 ">Ages Served</p>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    className={
                      profileData.under18 ? "labelbold" : "labeldefault"
                    }
                    control={
                      <Checkbox
                        checked={profileData.provider_ages_served.includes(
                          "Under 18 yrs old"
                        )}
                        onChange={handleCheckboxChange}
                        name="Under 18 yrs old"
                        color="primary"
                      />
                    }
                    label="Under 18 yrs old"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={profileData.provider_ages_served.includes(
                          "16 - 24 years old"
                        )}
                        onChange={handleCheckboxChange}
                        name="16 - 24 years old"
                        color="primary"
                      />
                    }
                    label="16 - 24 years old"
                  /> */}
              {/* Example: If "18 years old and older" exist in the provider_ages_served array check true if not false */}
              {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={profileData.provider_ages_served.includes(
                          "18 years old and older"
                        )}
                        onChange={handleCheckboxChange}
                        // defaultValue={profileData.ages_served}
                        name="18 years old and older"
                        color="primary"
                      />
                    }
                    label="18 years old and older"
                  />
                </FormGroup> 
              </div>*/}
              <p className="bold marginBottom10">Types of Services offered</p>
              <FormGroup className="marginBottom20 basicsServices">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Housing and Shelter"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Housing and Shelter"
                      color="primary"
                    />
                  }
                  label="Housing and Shelter"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Workforce Development"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Workforce Development"
                      color="primary"
                    />
                  }
                  label="Workforce Development"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Legal Aid"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Legal Aid"
                      color="primary"
                    />
                  }
                  label="Legal Aid"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Financial Assistance"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Financial Assistance"
                      color="primary"
                    />
                  }
                  label="Financial Assistance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Mental Health"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Mental Health"
                      color="primary"
                    />
                  }
                  label="Mental Health"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Case Management"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Case Management"
                      color="primary"
                    />
                  }
                  label="Case Management"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Healthcare"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Healthcare"
                      color="primary"
                    />
                  }
                  label="Healthcare"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "24-Hour Hotlines"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="24-Hour Hotlines"
                      color="primary"
                    />
                  }
                  label="24-Hour Hotlines"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Drop-In Center"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Drop-In Center"
                      color="primary"
                    />
                  }
                  label="Drop-In Center"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Support Group Services"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Support Group Services"
                      color="primary"
                    />
                  }
                  label="Support Group Services"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Education"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Education"
                      color="primary"
                    />
                  }
                  label="Education"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Healing Arts"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Healing Arts"
                      color="primary"
                    />
                  }
                  label="Healing Arts"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Youth Development Services"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Youth Development Services"
                      color="primary"
                    />
                  }
                  label="Youth Development Services"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Substance Overuse Services"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Substance Overuse Services"
                      color="primary"
                    />
                  }
                  label="Substance Overuse Services"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Public Benefits"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Public Benefits"
                      color="primary"
                    />
                  }
                  label="Public Benefits"
                />{" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Peer Support"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Peer Support"
                      color="primary"
                    />
                  }
                  label="Peer Support"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Basic Needs Resource"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Basic Needs Resource"
                      color="primary"
                    />
                  }
                  label="Basic Needs Resource"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Crisis Intervention"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Crisis Intervention"
                      color="primary"
                    />
                  }
                  label="Crisis Intervention"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Entrepreneurship"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Entrepreneurship"
                      color="primary"
                    />
                  }
                  label="Entrepreneurship"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Leadership Development"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Leadership Development"
                      color="primary"
                    />
                  }
                  label="Leadership Development"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Food Access"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Food Access"
                      color="primary"
                    />
                  }
                  label="Food Access"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Child Welfare and Child Protection"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Child Welfare and Child Protection"
                      color="primary"
                    />
                  }
                  label="Child Welfare and Child Protection"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_services_offered.includes(
                        "Telephone Hotline"
                      )}
                      onChange={(e) =>
                        handleCheckboxServices(e, "provider_services_offered")
                      }
                      name="Telephone Hotline"
                      color="primary"
                    />
                  }
                  label="Telephone Hotline"
                />
              </FormGroup>

              <p className="bold marginBottom10 ">Genders Served</p>
              <FormGroup className="marginBottom20">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Woman"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Woman"
                      color="primary"
                    />
                  }
                  label="Woman"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Trans-Woman"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Trans-Woman"
                      color="primary"
                    />
                  }
                  label="Trans-Woman"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Man"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Man"
                      color="primary"
                    />
                  }
                  label="Man"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Trans-Man"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Trans-Man"
                      color="primary"
                    />
                  }
                  label="Trans-Man"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Gender Queer"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Gender Queer"
                      color="primary"
                    />
                  }
                  label="Gender Queer"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Gender Non-Conforming"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Gender Non-Conforming"
                      color="primary"
                    />
                  }
                  label="Gender Non-Conforming"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Gender Non-Binary"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Gender Non-Binary"
                      color="primary"
                    />
                  }
                  label="Gender Non-Binary"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "Agender"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="Agender"
                      color="primary"
                    />
                  }
                  label="Agender"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_genders_served.includes(
                        "None of these"
                      )}
                      onChange={(e) =>
                        handleCheckboxGender(e, "provider_genders_served")
                      }
                      name="None of these"
                      color="primary"
                    />
                  }
                  label="None of these"
                />
              </FormGroup>

              <p className="bold marginBottom10 ">Other Characteristics</p>
              <FormGroup className="marginBottom20">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_other_characteristics.includes(
                        "Virtual Services"
                      )}
                      onChange={(e) =>
                        handleCheckboxCharact(
                          e,
                          "provider_other_characteristics"
                        )
                      }
                      name="Virtual Services"
                      color="primary"
                    />
                  }
                  label="Virtual Services"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_other_characteristics.includes(
                        "Open Now"
                      )}
                      onChange={(e) =>
                        handleCheckboxCharact(
                          e,
                          "provider_other_characteristics"
                        )
                      }
                      name="Open Now"
                      color="primary"
                    />
                  }
                  label="Open Now"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_other_characteristics.includes(
                        "Drop-in Available"
                      )}
                      onChange={(e) =>
                        handleCheckboxCharact(
                          e,
                          "provider_other_characteristics"
                        )
                      }
                      name="Drop-in Available"
                      color="primary"
                    />
                  }
                  label="Drop-in Available"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_other_characteristics.includes(
                        "Faith-based"
                      )}
                      onChange={(e) =>
                        handleCheckboxCharact(
                          e,
                          "provider_other_characteristics"
                        )
                      }
                      name="Faith-based"
                      color="primary"
                    />
                  }
                  label="Faith-based"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_other_characteristics.includes(
                        "Children Allowed"
                      )}
                      onChange={(e) =>
                        handleCheckboxCharact(
                          e,
                          "provider_other_characteristics"
                        )
                      }
                      name="Children Allowed"
                      color="primary"
                    />
                  }
                  label="Children Allowed"
                />
              </FormGroup>
            </div>

            <div>
              <p className="bold marginBottom10 ">Locations Served</p>
              {/* <FormGroup className="marginBottom20">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Alameda County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Alameda County"
                      color="primary"
                    />
                  }
                  label="Alameda County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Contra Costa County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Contra Costa County"
                      color="primary"
                    />
                  }
                  label="Contra Costa County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Marin County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Marin County"
                      color="primary"
                    />
                  }
                  label="Marin County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Monterey County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Monterey County"
                      color="primary"
                    />
                  }
                  label="Monterey County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "San Diego County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="San Diego County"
                      color="primary"
                    />
                  }
                  label="San Diego County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "San Francisco County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="San Francisco County"
                      color="primary"
                    />
                  }
                  label="San Francisco County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "San Mateo County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="San Mateo County"
                      color="primary"
                    />
                  }
                  label="San Mateo County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Santa Cruz County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Santa Cruz County"
                      color="primary"
                    />
                  }
                  label="Santa Cruz County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Solano County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Solano County"
                      color="primary"
                    />
                  }
                  label="Solano County"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.provider_counties_served.includes(
                        "Stanislaus County"
                      )}
                      onChange={(e) =>
                        handleCheckboxLocation(e, "provider_counties_served")
                      }
                      name="Stanislaus County"
                      color="primary"
                    />
                  }
                  label="Stanislaus County"
                />
              </FormGroup> */}
              <div
                className={
                  profileData.is_national
                    ? "flexRow alignCenter marginBottom15"
                    : "flexRow alignCenter"
                }
              >
                <Checkbox
                  checked={profileData.is_national}
                  name="is_national"
                  // value={addProviderInfo.is_national}
                  onChange={(e) => handleSingleCheckbox(e, "is_national")}
                  className="marginBottom15"
                  color="primary"
                />
                <span>
                  This organization serves anyone located in the United States.
                </span>
              </div>
              {profileData.is_national ? null : (
                <AddressSearchBar
                  name="provider_cities_served"
                  index="provider_cities_served"
                  state="edit"
                  parentData={
                    profileData.provider_cities_served
                      ? profileData.provider_cities_served
                      : []
                  }
                  updateParent={saveProviderAddresses}
                />
              )}
            </div>
          </div>
          <div
            className="displayBlock flexRow justifySpaceBetween marginBottom30"
            style={{ width: "40vw" }}
          >
            <Link to="/ourprofile">
              <button className="cancel-button">Cancel</button>
            </Link>
            {profileData.provider_ages_served[0] >=
              profileData.provider_ages_served[1] ||
            profileData.provider_ages_served[0] < 0 ||
            profileData.provider_ages_served > 119 ||
            profileData.provider_ages_served[1] <=
              profileData.provider_ages_served[0] ||
            profileData.provider_ages_served[1] < 1 ||
            profileData.provider_ages_served[1] > 120 ||
            error.provider_website ||
            error.provider_facebook ||
            error.provider_instagram ? (
              <ColorButton variant="contained" color="primary" disabled>
                Save
              </ColorButton>
            ) : (
              <Link to="/ourprofile">
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </ColorButton>
              </Link>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Basics1;
