import React, { useState } from "react";
import "../ProviderSignUp/ProviderSignUp.css";

import { Eligibility } from "./Eligibility";
import { CreateAccount } from "./CreateAccount";
import { Profile } from "./Profile";
import { Programs } from "./Programs";
import { IntakeForm } from "./IntakeForm";

const ProviderSignUp = () => {
  const [providerData, setProviderData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const [isStepComplete, setIsStepComplete] = useState({
    eligibility: false,
    createAccount: false,
    profile: false,
    Programs: false,
    intakeForm: false,
  });

  const addProviderData = (inputName, newValue) => {
    let updateData = { ...providerData };
    updateData[inputName] = newValue;
    setProviderData(updateData);
  };

  const isStepCompleteFunc = (page, bool) => {
    let step = { ...isStepComplete };
    step[page] = bool;
    setIsStepComplete(step);
  };

  const goForward = () => {
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    <Eligibility
      addProviderData={addProviderData}
      providerData={providerData}
      goForward={goForward}
    />,
    <CreateAccount
      addProviderData={addProviderData}
      providerData={providerData}
      goForward={goForward}
    />,
    <Profile
      addProviderData={addProviderData}
      providerData={providerData}
      goForward={goForward}
    />,
    <Programs
      addProviderData={addProviderData}
      providerData={providerData}
      goForward={goForward}
    />,
    <IntakeForm
      addProviderData={addProviderData}
      providerData={providerData}
      goForward={goForward}
      goBack={goBack}
      isStepCompleteFunc={isStepCompleteFunc}
    />,
  ];

  return <div>{steps[currentStep]}</div>;
};

export default ProviderSignUp;
