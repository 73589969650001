import { useState } from "react";
import "./addressSearchBar.css";
import { createFilterOptions } from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "../Assets/greyCloseIcon.png";
import EditIcon from "../Assets/edit-edit.png";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export const AddressSearchBar = (props) => {
  // console.log(props.parentData, props.index);
  /* 
        Many different State Hooks to hold Strings and lists of varying degrees
        address: The string that holds any input change from the user
        addressBoxes: The list of objects that will hold certain addresses when 
        added from the search text field
        invalid: The boolean indicator for whether the autocomplete component returns an 
        error message, and what kind of error
        searchedAdd: The list of objects that will hold the addresses from the API call
        oldAddressBoxes: The list of previously searched and added addresses before the user
        saves any checked address boxes.
        */
  // const [parentAddresses, setParentAddresses] = useState(props.parentData);
  const [address, setAddress] = useState("");
  const [addressBoxes, setAddressBoxes] = useState(props.parentData);
  const [invalid, setInvalid] = useState({ bool: false, msg: "" });
  const [searchedAdd, setSearchedAdd] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [oldAddressBoxes, setOldAddressBoxes] = useState([]);

  /* 
        A call to a function for the autocomplete component to only return 
        addresses that start with the current user's input in the text field.
        */
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label,
  });

  /* 
        The function call when the user changes the text field input.
        Changes the address state to the current text value
        Calls the geocoding API to return suggested addresses based on the 
        current text input from the user
        */
  const onChangeType = (e) => {
    setAddress(e.target.value);
    let timeout;
    setLoading(true);
    if (e.target.value.length > 2) {
      timeout = setTimeout(() => {
        callAddresses(e.target.value);
      }, 3000);
    } else {
      setSearchedAdd([]);
    }

    return () => clearTimeout(timeout);
  };

  const callAddresses = async (val) => {
    const secretApi = "ac12bef6b23d474b9839cf1acafba9e3";
    let searchables = [];

    var requestOptions = {
      method: "GET",
    };

    await fetch(
      `https://api.geoapify.com/v1/geocode/search?text=${encodeURIComponent(
        val
      )}&filter=countrycode:us&lang=en&format=json&apiKey=${secretApi}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          for (let i = 0; i < result.results.length; i++) {
            if (
              searchables.some(
                (search) => search.value === result.results[i].formatted
              )
            ) {
              continue;
            } else {
              console.log(result.results[i]);
              let newStr = result.results[i].formatted.replace(
                ", United States of America",
                ""
              );
              let newCityStr = `${result.results[i].city}, ${result.results[i].state}`;
              // console.log(newStr);
              searchables[i] = {
                value:
                  props.name === "provider_addresses" ? newStr : newCityStr,
                label:
                  props.name === "provider_addresses" ? newStr : newCityStr,
                city: result.results[i].city,
                state_code: result.results[i].state_code,
                state: result.results[i].state,
                county: result.results[i].county
                  ? result.results[i].county
                  : `${result.results[i].city} County`,
                checked: true,
                category: "Searched",
              };
              if (props.name === "provider_addresses") {
                searchables[i] = {
                  ...searchables[i],
                  zip_code: result.results[i].postcode,
                };
              }
            }
          }
          searchables = searchables.filter((add) => add !== "");
          setSearchedAdd(searchables);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  /* 
        Old: The function call when clicking the add button to save an address.
        Calls on the geocoding API to make sure the address about to be saved
        is a valid and real address.
        Once validated, the address gets saved with a check box and a link connecting
        to Google Maps.
        */

  const selectAddress = async (newAddressObj) => {
    // console.log('newAddressObj')
    // if (address.length <= 5) {
    //     setInvalid({ bool: true, msg: "Please enter a longer address." });
    //     return null;
    // }
    // const secretApi = "ac12bef6b23d474b9839cf1acafba9e3";
    // var requestOptions = {
    //     method: "GET",
    // };
    // await fetch(
    //     `https://api.geoapify.com/v1/geocode/search?text=${encodeURIComponent(newAddressObj.value)}&filter=countrycode:us&lang=en&format=json&apiKey=${secretApi}`,
    //     requestOptions,
    // )
    //     .then((response) => response.json())
    //     .then((result) => {
    //         if (
    //             result.results[0].rank.match_type === "full_match" &&
    //             result.results[0].rank.confidence >= 0.2
    //         ) {
    if (
      addressBoxes.filter((a) => a.value === newAddressObj.value).length > 0
    ) {
      setInvalid({ bool: true, msg: "Address already entered." });
      return;
    } else {
      setInvalid({ bool: false, msg: "" });
      let concat = addressBoxes.concat(newAddressObj);
      let sorted = concat.sort((a, b) => (a.value > b.value ? 1 : -1));
      // console.log("sorted", sorted);
      setAddressBoxes(sorted);
      if (props.name === "location_filter") {
        props.updateParent(sorted);
      } else {
        props.updateParent(sorted, props.index, props.name);
      }
      setAddress("");
      setSearchedAdd([]);
      // if (
      //     parentAddresses.filter((a) => a.label === newAddressObj.label).length === 0
      // ) {
      //     setParentAddresses(
      //         parentAddresses.concat(newAddressObj),
      //     );
      //     setSearchedAdd([]);
      // }
    }
    // } else {
    //     setInvalid({ bool: true, msg: "Please enter a valid address." });
    // }
    // })
    // .catch((error) => console.log("error", error));
  };

  // When user clicks inside the Box element, set address ready to true so that once the next inputchange runs we can get a valid address. (We do this because the Autocomplete component does not provide an onClick function for the option items)
  const checkAddressClick = (addressObj) => {
    // console.log("addressObj: ", addressObj);
    if (addressObj?.value?.length > 2) {
      selectAddress(addressObj);
    }
  };

  // const handleClicks = async () => {
  //   const addButton = document.getElementById("addressAdd");
  //   const loadButton = document.getElementById("addressLoading");
  //   addButton.style.display = "none";
  //   loadButton.style.display = "";
  //   await selectAddress();
  //   addButton.style.display = "";
  //   loadButton.style.display = "none";
  // };
  /* 
        A function that is called to change the icon of an address box from being
        unchecked to checked or vice versa. Having an address box be checked allows it
        to be saved and kept once the user presses the save button.
        */
  const handleSwap = (address, i) => {
    let addressBoxesCopy = [...addressBoxes];
    let updateAddy = addressBoxesCopy.map((a, i) => {
      if (a.value === address.value) {
        return {
          ...a,
          checked: !a.checked,
          category: a.checked === true ? "Searched" : "Removed",
        };
      } else {
        return a;
      }
    });
    let updateAddyFiltered = updateAddy.filter((item) => item.checked === true);
    let updatedAddy =
      props.name === "provider_cities_served" ||
      props.name === "location_filter"
        ? updateAddyFiltered
        : updateAddy;
    // console.log("updateAddy: ", updateAddy);
    // console.log("updateAddyFiltered: ", updateAddyFiltered);
    // let sortedAddy = updatedAddy.sort((a, b) => (a.value > b.value ? 1 : -1)); //sorting only works when clicking X to eliminate a city
    // console.log("sorted", sortedAddy);
    // console.log("updatedAddy: ", updatedAddy);
    setAddressBoxes(updatedAddy);
    props.updateParent(updatedAddy, props.index, props.name);
  };

  const handleEditClick = (e, i) => {
    let addressBoxesCopy = [...addressBoxes];
    addressBoxesCopy[i].category = "Edit";
    setAddressBoxes(addressBoxesCopy);
  };

  const handleEditChange = (e, i) => {
    let value =
      e.target.value === ""
        ? `${addressBoxes[i].city}, ${addressBoxes[i].state_code} ${addressBoxes[i].zip_code}`
        : `${e.target.value}, ${addressBoxes[i].city}, ${addressBoxes[i].state_code} ${addressBoxes[i].zip_code}`;
    let addressBoxesCopy = [...addressBoxes];
    addressBoxesCopy[i].label = value;
    addressBoxesCopy[i].value = value;
    setAddressBoxes(addressBoxesCopy);
  };

  const handleEditSave = (e, i) => {
    let addressBoxesCopy = [...addressBoxes];
    addressBoxesCopy[i].category = "Searched";
    setAddressBoxes(addressBoxesCopy);
  };

  /* 
        A function that saves all the current address boxes that are checked.
        The function also removes any unchecked addresses from the address boxes
        group and the dropdown from the autocomplete component.
        It saves the previous instance of the address box group to another list,
        in case to undo the save.
        */
  // const handleSave = () => {
  //     const checkedAddBoxes = addressBoxes.filter((addy) => addy.checked);
  //     //const searchedAdd = checkedAddBoxes.filter((add) => add.category === "Searched",);
  //     setAddressBoxes(checkedAddBoxes);
  //     props.updateParent(checkedAddBoxes);
  // };

  return (
    <section
      id="addressSearchBar"
      className="marginBottom15"
      style={{
        width:
          props.name === "location_filter" && props.state === "edit"
            ? 256
            : props.name === "provider_addresses" &&
              props.state === "edit" &&
              500,
      }}
    >
      {props.redSubLabel && <label>{props.redSubLabel}</label>}

      {props.state !== "display" && (
        <div className="flexRow alignCenter">
          <Box
            // onClick={() => checkAddressClick()} // Working as an on address option click
            className="mainBox"
            width={
              props.name === "provider_addresses" && props.state === "edit"
                ? 450
                : props.name === "location_filter" && props.state === "edit"
                ? 250
                : 320
            }
          >
            <Autocomplete
              id="innerInput"
              freeSolo
              sx={{
                width:
                  props.name === "provider_addresses" && props.state === "edit"
                    ? 500
                    : props.name === "location_filter" && props.state === "edit"
                    ? 250
                    : 320,
              }}
              ListboxProps={{ style: { maxHeight: 205 } }}
              name={props.name}
              value={address}
              selectOnFocus
              // disableCloseOnSelect
              disablePortal
              options={
                searchedAdd?.length > 0 && address !== ""
                  ? searchedAdd
                      ?.sort((a, b) => -b.value.localeCompare(a.value))
                      ?.sort((a, b) => -b.category.localeCompare(a.category))
                  : []
                // : parentAddresses
                //     .sort((a, b) => -b.value.localeCompare(a.value))
                //     .sort((a, b) => -b.category.localeCompare(a.category))
              }
              // groupBy={
              //     searchedAdd.length > 0 && address !== ""
              //         ? null
              //         : (option) => option.category
              // }
              filterOptions={filterOptions}
              onInputChange={(e, newText) => setAddress(newText)}
              onChange={(e, newObj) => checkAddressClick(newObj)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="autoTextInput"
                  error={invalid.bool}
                  helperText={invalid.bool ? invalid.msg : ""}
                  variant="filled"
                  // label="Address"
                  InputProps={{
                    ...params.InputProps,
                    maxLength: "255",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e, ...params) => {
                    onChangeType(e);
                  }}
                  placeholder={props.placeholder}
                />
              )}
            />
            {/* {props.name === "provider_addresses" && props.state === "edit" && (
              <ControlPointIcon
                id="addressAdd"
                fontSize="large"
                onClick={() => handleClicks()}
              />
            )} */}
            {loading && <CircularProgress id="addressLoading" size={16} />}
          </Box>
        </div>
      )}

      {/* <label id="error">Please enter a valid address.</label> */}
      {/* <br /> <br /> */}
      {props.parentData.length > 0 && (
        <span className="colorRed">{props.checkedLabel}</span>
      )}

      <Box
        className="flexColumn"
        width={props.name === "provider_addresses" ? 500 : 350}
      >
        {addressBoxes?.map((address, i) => {
          if (address) {
            return (
              <Box
                className="selectedAddressWrap"
                key={
                  props.index
                    ? `${props.name}${props.index}${i}`
                    : `${props.name}${i}`
                }
              >
                <span className="flexRow alignCenter marginLeft5">
                  {props.state === "edit" &&
                  props.name === "provider_cities_served" ? (
                    <img
                      src={CloseIcon}
                      alt="close icon"
                      className="width14Height14"
                      onClick={(i) => handleSwap(address, i)}
                    />
                  ) : props.state === "edit" &&
                    props.name === "provider_addresses" ? (
                    <>
                      <CheckBoxIcon
                        className="CheckBoxIcon"
                        id="CheckBoxIcon"
                        onClick={(i) => handleSwap(address, i)}
                        style={{
                          display: address.checked ? "" : "none",
                        }}
                      />
                      <CheckBoxOutlineBlankIcon
                        className="CheckBoxOutlineIcon"
                        id="CheckBoxOutlineIcon"
                        onClick={(i) => handleSwap(address, i)}
                        style={{
                          display: !address.checked ? "" : "none",
                        }}
                      />
                      {address.category === "Searched" ? (
                        <img
                          src={EditIcon}
                          className="paddingLeft8"
                          onClick={(e) => handleEditClick(e, i)}
                          alt="edit icon"
                        />
                      ) : (
                        <>
                          <TextField
                            type="text"
                            defaultValue={
                              addressBoxes[i].value.split(",")[0] ===
                              addressBoxes[i].city
                                ? ""
                                : addressBoxes[i].value.split(",")[0]
                            }
                            placeholder={
                              addressBoxes[i].value.split(",")[0] ===
                              addressBoxes[i].city
                                ? ""
                                : addressBoxes[i].value.split(",")[0]
                            }
                            onChange={(e) => handleEditChange(e, i)}
                            className="width200 borderRightNone"
                          />
                          <ControlPointIcon
                            onClick={(e) => handleEditSave(e, i)}
                          />
                          <span className="paddingLeft8">
                            {addressBoxes[i].city}, {addressBoxes[i].state_code}{" "}
                            {addressBoxes[i].zip_code}
                          </span>
                          {/* <TextField
                            type="text"
                            value={`${addressBoxes[i].city}, ${addressBoxes[i].state_code} ${addressBoxes[i].zip_code}`}
                            disabled
                            className="width200 borderLeftNone"
                          /> */}
                        </>
                      )}
                    </>
                  ) : null}
                  {address.category === "Searched" && (
                    <span className="marginLR15"> {address.value} </span>
                  )}
                  {props.state === "edit" &&
                    props.name === "location_filter" && (
                      <img
                        src={CloseIcon}
                        alt="close icon"
                        className="width14Height14"
                        onClick={(i) => handleSwap(address, i)}
                      />
                    )}
                </span>
                {props.state === "edit" &&
                  props.name !== "location_filter" &&
                  address.category === "Searched" && (
                    <Link
                      className="checkBoxMapIcon"
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        address.value
                      )}`}
                      target="_blank"
                    >
                      <LocationOnIcon />
                    </Link>
                  )}
              </Box>
            );
          } else {
            return null;
          }
        })}
      </Box>

      {/* <button id="saveChanges" onClick={() => handleSave()}>
                {" "}
        Save{" "}
            </button> */}
    </section>
  );
};
