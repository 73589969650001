import "./App.css";
import Routes from "./Routes";
import React, { useState, useEffect, useRef } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AppContext } from "./useContext";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import config from "./config";
ReactGA.initialize("G-1NN0VSBHL2");
mixpanel.init(
  process.env.MIXPANEL_PROJECT_TOKEN
    ? process.env.MIXPANEL_PROJECT_TOKEN
    : config.mixpanel.PROJECT_TOKEN,
  {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  }
);

function App() {
  const history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userId, setUserId] = React.useState("");
  const [provider, setProvider] = React.useState("");
  const [providerId, setProviderId] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [isVisible, setIsVisible] = React.useState(false);

  const [colors, setColors] = React.useState("");

  //function to detect window dimensions only once with useRef to prevent multiple rerenderings upon mobile scroll
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const size = windowSize.current;
  // console.log(size); // [width, height];

  // following code kept rerendering when parent passed props to all its children on mobile scroll
  // const size = useWindowSize();
  // function useWindowSize() {
  //   // Initialize state with undefined width/height so server and client renders match
  //   // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  //   const [windowSize, setWindowSize] = useState({
  //     width: undefined,
  //     height: undefined,
  //   });
  //   useEffect(() => {
  //     // Handler to call on window resize
  //     function handleResize() {
  //       // Set window width/height to state
  //       setWindowSize({
  //         width: window.innerWidth,
  //         height: window.innerHeight,
  //       });
  //     }
  //     // Add event listener
  //     window.addEventListener("resize", handleResize);
  //     // Call handler right away so state gets updated with initial window size
  //     handleResize();
  //     // Remove event listener on cleanup
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []); // Empty array ensures that effect is only run on mount
  //   return windowSize;
  // }

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    // console.log("on load");
    try {
      let auth = await Auth.currentSession();
      console.log(auth);
      userHasAuthenticated(true);
      await Auth.currentUserInfo().then((response) => {
        setUserId(response.attributes.sub);
        setEmail(response.attributes.email);
        API.post("referall-provider", "getuserprovider", {
          body: {
            user_id: response.attributes.sub,
            email: response.attributes.sub,
          },
        })
          .then((response) => {
            // console.log(response);
            setProvider(response[0].provider);
            setProviderId(response[0].provider_id);
            setUsername(response[0].username);
            setColors(response[0].avatar_colors);
            setRole(response[0].role);
            setIsVisible(response[0].is_visible);
            history.push("/serviceproviders");
          })
          .catch(async (error) => {
            console.log(error);
            mixpanel.track("P ConsoleLog Error", {
              "Event Type": "API Call",
              "Error Message": error,
              "API Name": "getuserprovider",
              "File Name": "App.js",
              "File Line": 103,
            });
            await Auth.signOut(); //must sign out so user does not get stuck with blank page
            userHasAuthenticated(false);
            setProvider([]);
            setProviderId([]);
            history.push("/serviceproviders");
          });
      });
    } catch (e) {
      console.log("No current user.");
      mixpanel.track("P ConsoleLog Error", {
        "Event Type": "API Call",
        "Error Message": `No current user: ${e}`,
        "API Name": "Auth.currentSession",
        "File Name": "App.js",
        "File Line": 118,
      });
    }
  }

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          provider,
          setProvider,
          providerId,
          setProviderId,
          userId,
          setUserId,
          email,
          setEmail,
          username,
          setUsername,
          colors,
          setColors,
          role,
          setRole,
          isVisible,
          setIsVisible,
        }}
      >
        {/* <Route path="/login" component={Login} /> */}
        {/* <Route path="/" component={Routes} exact /> */}
        <Routes windowSize={size} />
      </AppContext.Provider>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
