import { Auth, API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import { Input, Modal, Box, Typography, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { ErrorOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import mixpanel from "mixpanel-browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export default function ForgotPassword(props) {
  // console.log(props);
  const history = useHistory();
  const size = props.windowSize;
  // const classes = useStyles();
  const [value, setValue] = useState({ password: "", confirmpassword: "" });
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [values1, setValues1] = useState({
    showPassword1: false,
  });
  // email and password help text
  const [error, setError] = useState("initial email input");
  const [passwordError, setPasswordError] = useState(["initial state"]);
  const defaultPwdHelpText =
    "Please make sure your password has 8 to 20 characters, a number, a lowercase letter, an uppercase letter, and a special character (e.g., !@#$%^&*()).";
  const [passwordHelpText, setPasswordHelpText] = useState(defaultPwdHelpText);

  const [codeSent, setCodeSent] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSendingCode, setSendingCode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [confirming, setConfirming] = useState(false);

  const [tempPasswordModal, setTempPasswordModal] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, message: "" });

  useEffect(() => {
    Auth.currentUserInfo()
      .then((response) => {
        if (response !== null) {
          setValue({ email: response.attributes.email });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const isEmailValid = (value) => {
    const validRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value.match(validRegex)) {
      setError("invalid email");
      // console.log(error);
      return false;
    } else {
      setError("");
      // console.log(error);
      return true;
    }
  };
  const isPasswordValid = (value) => {
    const validPasswordRegex =
      // /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()-+_;:'"<>,.?]{8,20}$/;
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()]).{8,20}$/;
    const hasSyms = /^(?=.*?[!@#$%^&*()]).{1,20}$/;
    const hasNums = /^(?=.*?[0-9]).{1,20}$/;
    const hasCaps = /^(?=.*?[A-Z]).{1,20}$/;
    const hasMins = /^(?=.*?[a-z]).{1,20}$/;
    // const needLength = /^[\s\S]{1,7}$/;
    let needs = [...passwordError];
    if (value.match(validPasswordRegex)) {
      setPasswordError(["valid password"]);
      setPasswordHelpText("");
      // console.log("true");
      return true;
    } else if (value === "") {
      setPasswordError(["invalid password"]);
      setPasswordHelpText(defaultPwdHelpText);
      // console.log("false");
      return false;
    } else {
      // console.log("else");
      if (needs.includes("valid password")) {
        let index = needs.findIndex((item) => item === "valid password");
        needs.splice(index, 1);
      }
      if (!needs.includes("invalid password")) {
        needs.push("invalid password");
      }
      if (value.match(hasSyms)) {
        if (!needs.includes("hasSyms")) {
          needs.push("hasSyms");
        }
      } else {
        needs = needs.filter((item) => item !== "hasSyms");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasNums)) {
        if (!needs.includes("hasNums")) {
          needs.push("hasNums");
        }
      } else {
        needs = needs.filter((item) => item !== "hasNums");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasCaps)) {
        if (!needs.includes("hasCaps")) {
          needs.push("hasCaps");
        }
      } else {
        needs = needs.filter((item) => item !== "hasCaps");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasMins)) {
        if (!needs.includes("hasMins")) {
          needs.push("hasMins");
        }
      } else {
        needs = needs.filter((item) => item !== "hasMins");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.length < 8 || value.length > 20) {
        // console.log("needLength");
        if (!needs.includes("needLength")) {
          needs.push("needLength");
        }
      } else {
        needs = needs.filter((item) => item !== "needLength");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      // console.log(needs);
      setPasswordError(needs);
      let helpText = [
        needs.includes("needLength") && "8 to 20 characters",
        !needs.includes("hasNums") && "a number",
        !needs.includes("hasMins") && "a lowercase letter",
        !needs.includes("hasCaps") && "an uppercase letter",
        !needs.includes("hasSyms") && "a special character (e.g. !@#$%^&*())",
      ].filter(Boolean);
      if (
        helpText.length === 0 &&
        needs.includes("invalid password") &&
        needs.length === 1
      ) {
        helpText = defaultPwdHelpText;
      } else if (helpText.length === 1) {
        helpText = "Please make sure your password has " + helpText[0] + ".";
      } else if (helpText.length === 2) {
        helpText =
          "Please make sure your password has " + helpText.join(" and ") + ".";
      } else if (helpText.length > 2) {
        let lastItem = " and " + helpText.pop() + ".";
        helpText =
          "Please make sure your password has " +
          helpText.join(", ") +
          lastItem;
      }
      // console.log(needs, helpText);
      setPasswordHelpText(helpText);
      return false;
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "email") {
      isEmailValid(event.target.value);
    } else if (event.target.name === "password") {
      isPasswordValid(event.target.value);
    }
    let newValue = event.target.value;
    if (event.target.name === "email") {
      newValue = newValue.toLowerCase();
    }
    // console.log(event.target.name, event.target.value);
    setValue({ ...value, [event.target.name]: newValue });
  };
  // console.log(value);
  const handleClose = () => {
    props.callBack();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPassword1 = () => {
    setValues1({ ...values1, showPassword1: !values1.showPassword1 });
  };
  const handleSendCodeClick = async (event) => {
    event.preventDefault();
    // console.log("send code clicked");
    setSendingCode(true);

    try {
      await Auth.forgotPassword(value.email);
      setCodeSent(true);
    } catch (e) {
      // console.log(e.code, e.message);
      // alert(e.message);
      if (e.message === "Network error") {
        // alert("Please try again.");
        setErrorModal({
          open: true,
          message: "Please try again.",
        });
      } else if (
        e.message === "User password cannot be reset in the current state."
      ) {
        setValue({ ...value, resendTempPassword: true });
        try {
          API.post("referall-provider", "resettemppassword", {
            body: {
              email: value.email.toLowerCase(),
              // resendTempPassword: userData.resendTempPassword,
            },
          }).then((response) => {
            // console.log(response);
            if (response.resendTempPassword === false) {
              // console.log(response);
              // if (response) {
              setValue({ ...value, resendTempPassword: false });
            } else if (response.resendTempPassword === true) {
              Auth.forgotPassword(value.email)
                .then((response) => {
                  // console.log(response);
                  setValue({ ...value, forgotPassword: true });
                  // console.log(value);
                })
                .catch((err) => console.log(err));
            }
          });
        } catch (e) {
          console.log(e); // "User password cannot be reset in the current state."
          if (
            e.message === "User password cannot be reset in the current state."
          ) {
            value.resendTempPassword = true;
          } else {
            // alert(e.message);
            setErrorModal({ open: true, message: e.message });
            mixpanel.track("P ConsoleLog Error", {
              "Event Type": "API Call",
              "Error Message": e,
              "API Name": "resettemppassword",
              "File Name": "ForgotPassword.js",
              "File Line": 283,
            });
          }
        }
        setValue({ ...value, resendTempPassword: false });
      }
      setTempPasswordModal(true);
      setSendingCode(false);
    }
  };

  const handleConfirmClick = async (event) => {
    event.preventDefault();
    if (value.password === value.confirmpassword) {
      setConfirming(true);
      // console.log(value);
      try {
        await Auth.forgotPasswordSubmit(
          value.email,
          value.code,
          value.password
        );
        setConfirmed(true);
        mixpanel.track("P ForgotPW Click", {
          "Event Type": "User Action",
        });
      } catch (e) {
        // alert(e.message);
        // console.log(e.code);
        setErrorModal({ open: true, message: e.message });
        setConfirming(false);
      }
    } else {
      setPasswordMismatch(true);
    }
  };

  const handlePasswordMismatchClick = () => {
    setPasswordMismatch(false);
    setCodeSent(true);
  };

  const renderRequestCodeForm = () => {
    return (
      <div className="recovery-parent-div">
        <h3 className="recoveryHeader">Forgot Password</h3>
        <form className="password-recover-form" onSubmit={handleSendCodeClick}>
          <p className="recovery-email-label">
            Enter your account’s email address, and we’ll send you a one-time
            passcode to reset your password. The code will expire in 24 hours.
          </p>
          <TextField
            name="email"
            label="Email"
            multiline
            fullWidth
            required
            value={value.email}
            onChange={handleChange}
          />
          {error === "invalid email" ? (
            <p className="marginTop0 colorRed fontSize14">
              Please use this format:{" "}
              <span className="bold">yourname@company.com</span>
            </p>
          ) : null}
          <div>
            <button
              className={
                error === "invalid email" || error === "initial email input"
                  ? "button-forgot opacity3"
                  : "button-forgot"
              }
              disabled={
                error === "invalid email" || error === "initial email input"
                  ? true
                  : false
              }
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <div className="recovery-parent-div">
        <h3 className="recovery-header">Forgot Password</h3>
        <form className="recovery-form" onSubmit={handleConfirmClick}>
          <p>
            Please check your email for a verification code to enter below. It
            may take a few minutes for your code to arrive.
          </p>
          <TextField
            name="code"
            type="text"
            label="Code"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            defaultValue={value.code}
            onChange={handleChange}
          />
          <p>Password</p>
          <Input
            name="password"
            type={values.showPassword ? "text" : "password"}
            label="Password"
            fullWidth
            required
            endAdornment={
              <InputAdornment position="end">
                {passwordError.includes("invalid password") && <ErrorOutline />}
                <IconButton
                  className="icon-eye-login"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            defaultValue={value.password}
            onChange={handleChange}
          />
          {passwordError.includes("initial state") &&
          passwordError.length === 1 ? (
            <p className="fontSize14 marginTop0">{passwordHelpText}</p>
          ) : (
            passwordError.includes("invalid password") && (
              <p className="colorRed fontSize14 marginTop0">
                {passwordHelpText}
              </p>
            )
          )}
          <p className="marginTop0"> Confirm Password</p>
          <Input
            name="confirmpassword"
            type={values1.showPassword1 ? "text" : "password"}
            label="Confirm Password"
            fullWidth
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  className="icon-eye-login"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                >
                  {values1.showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            defaultValue={value.confirmpassword}
            onChange={handleChange}
          />
          <div className="recovery-button-div" style={{ marginTop: "10px" }}>
            <button
              className={
                value.password &&
                value.confirmpassword &&
                value.confirmpassword.toString().length >= 8
                  ? "button-forgot"
                  : "button-forgot opacity3"
              }
              type="submit"
              // disabled={
              //   value.password &&
              //   value.confirmpassword &&
              //   value.confirmpassword.toString().length >= 8
              //     ? false
              //     : true
              // }
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderPasswordMismatchMessage = () => {
    return (
      <div className="mismatch">
        <p>Passwords do not match. Please check and try again.</p>
        <button
          className={"button-forgot"}
          onClick={handlePasswordMismatchClick}
        >
          Try Again
        </button>
      </div>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <div className="success">
        <p>
          Your password has been reset. You can login with your new credentials.
        </p>
        <button
          className={"button-forgot"}
          onClick={() => {
            handleClose();
            setValue({
              password: "",
              confirmPassword: "",
              showPassword: false,
              showConfirmPassword: false,
            });
            setError("initial email input");
            setPasswordError([""]);
            setCodeSent(false);
            setConfirmed(false);
          }}
        >
          Close
        </button>
      </div>
    );
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={
          // size.width < 768
          size[0] < 768 ? "xl" : "xs"
        }
      >
        <div style={{ padding: "30px" }}>
          {!codeSent
            ? renderRequestCodeForm()
            : passwordMismatch
            ? renderPasswordMismatchMessage()
            : !confirmed
            ? renderConfirmationForm()
            : renderSuccessMessage()}
        </div>
      </Dialog>
      {/* Error Modal */}
      {errorModal.open && (
        <Modal
          open={errorModal.open}
          onClose={() => {
            // console.log(errorModal);
            setErrorModal({ ...errorModal, open: false });
          }}
          aria-labelledby="temp-password-modal"
          aria-describedby="temp-password-modal"
        >
          <Box
            sx={style}
            className={props.windowSize[0] < 480 ? "width75vw" : "width400"}
          >
            <Typography className="modal-text">{errorModal.message}</Typography>
            <Typography
              className="modal-button-stacked modal-button-last textDecorationUnderline"
              onClick={() => {
                setErrorModal({ ...errorModal, open: false });
              }}
            >
              OK
            </Typography>
          </Box>
        </Modal>
      )}
      {/* modal shows when confirmed user clicks resend temporary password link */}
      <Modal
        open={tempPasswordModal}
        onClose={() => {
          setTempPasswordModal(false);
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box
          sx={style}
          className={props.windowSize[0] < 480 ? "width75vw" : "width400"}
        >
          <Typography className="modal-heading" variant="h6" component="h2">
            Resend Temporary Password
          </Typography>
          <Typography className="modal-text">
            We need to email you a new temporary password to set up your
            account. The password will expire in 7 days.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-first textDecorationUnderline"
            onClick={() => {
              setTempPasswordModal(false);
              handleSendCodeClick();
              history.push("/newuser");
            }}
          >
            Send Password
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last textDecorationUnderline"
            onClick={() => {
              setTempPasswordModal(false);
            }}
          >
            Cancel
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
