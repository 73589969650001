import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import API from "@aws-amplify/api";
import { useAppContext } from "../../useContext";
import MaterialTable, { MTableToolbar } from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import Participantsnavbar from "../People/ParticipantsNavbar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "./people.css";
import FlagIcon from "@material-ui/icons/Flag";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import Badge from "@material-ui/core/Badge";
import mixpanel from "mixpanel-browser";

const tableIcons = {
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const Clients = (props) => {
  const { providerId } = useAppContext();
  const [formSubmitted, setFormSubmitted] = useState(true);
  const [selectedProfileData, setSelectedProfileData] = useState({});
  // const [invisible, setInvisible] = React.useState(false);
  let tableRef = React.createRef();
  const [dataClient, setDataClient] = useState([]);

  /* https://annie-cannons.atlassian.net/browse/REF-258 */
  const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
  const mobileSize = () => {
    setDesktop(window.innerWidth > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", mobileSize);
    return () => window.removeEventListener("resize", mobileSize);
  });

  useEffect(() => {
    setDataClient(props.dataClient);
  }, [props.dataClient]);

  const peopleCallback = () => {
    props.peopleCallback();
  };

  const handleDropdown = (event) => {
    const name = event.target.name;
    var currentProfileData = { ...selectedProfileData };
    currentProfileData[name] = event.target.value;
    setSelectedProfileData(currentProfileData);
    API.post("referall-provider", "addproviderpeoplenotes", {
      body: {
        participant_status: event.target.value,
        participant_id: selectedProfileData.participant_id,
        provider_id: providerId,
        profileData: selectedProfileData,
      },
    }).then((response) => {
      // console.log(response);
      mixpanel.track("P ChangeSurStat Change", {
        "Status Change": event.target.value,
        "Event Type": "User Action",
      });
      props.peopleCallback();
    });
  };

  // const handleBadgeClick = () => {
  //   setInvisible(true);
  // };

  var handleFalse = () => {
    setFormSubmitted(formSubmitted === false);
    // console.log(formSubmitted);
    props.handleNavbarVisible();
  };

  var handleClick = (rowData) => {
    setSelectedProfileData(rowData);
    // console.log(rowData);

    setFormSubmitted(formSubmitted === false);
    props.handleNavbarVisible();
  };
  const columns = [
    // {
    //   title: "",
    //   field: "avatar",
    //   sorting: false,
    //   render: (rowData) => (
    //     <div className="flexRow justifySpaceBetween alignCenter">
    //       {rowData.participant_status.includes(
    //         "Connected: Awaiting Response"
    //       ) ? (
    //         <p
    //           onClick={() => {
    //             handleBadgeClick();
    //           }}
    //           className="marginRight11 marginBottom0"
    //         >
    //           <Badge color="secondary" variant="dot" invisible={invisible}>
    //             <AccountCircleIcon className="avatar-user" />
    //           </Badge>
    //         </p>
    //       ) : (
    //         <p
    //           onClick={() => {
    //             handleBadgeClick();
    //           }}
    //           className="marginRight11 marginBottom0"
    //         >
    //           <AccountCircleIcon className="avatar-user" />
    //         </p>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Name",
      field: "preferred_name",
      sorting: false,
      render: (rowData) => (
        // console.log('rowData', rowData)
        <div className="flexRow alignCenter">
          <span className="marginRight11">
            <AccountCircleIcon className="avatar-user" />
          </span>
          <p className="marginRight11 marginBottom0">
            {rowData.preferred_name}
          </p>
        </div>
      ),
    },
    {
      title: "DOB",
      field: "date_of_birth",
      sorting: false,
      render: (rowData) => {
        let dob = rowData.date_of_birth;
        let dobOnly = dob ? dob.split("T")[0] : "No date";
        let monthYearOnly = "";
        if (dobOnly.includes("-")) {
          let date = new Date(dobOnly);
          let dateAdj = date.setDate(date.getDate() + 1);
          let dateUi = new Date(dateAdj);
          monthYearOnly = dateUi.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
        } else {
          monthYearOnly = dobOnly;
        }
        // console.log(monthYearOnly);
        return (
          <div className="flexRow justifySpaceBetween alignCenter">
            <p className="marginRight11 marginBottom0">
              {rowData.date_of_birth && monthYearOnly}
              {/* : console.log(rowData.date_of_birth) */}
            </p>
          </div>
        );
      },
    },
    // { title: "Current Location", field: "county_location", sorting: false },
    // { title: "Phone", field: "phone" },
    {
      title: "Current Location",
      field: "address_line1", //stores city, state, county
      sorting: false,
      render: (rowData) => (
        <div className="flexRow justifySpaceBetween alignCenter">
          <p className="marginRight11 marginBottom0">
            {rowData.address_line1 ? rowData.address_line1 : "Not specified"}
          </p>
        </div>
      ),
    },
    // {
    // 	title: "Form Date",
    // 	field: "createdat",
    //  sorting: true,
    // 	render: (rowData) => (
    // 		<div className="flexRow justifySpaceBetween alignCenter">
    // 			<p className="marginRight11 marginBottom0">
    // 				{rowData.created_at}
    // 			</p>
    // 			<img src={rightArrowColorD5D8DD} alt="arrow" className="height17" />
    // 		</div>
    // 	),
    // },
  ];

  /* https://annie-cannons.atlassian.net/browse/REF-258 */

  const mobileColumns = [
    // {
    //   title: "",
    //   field: "avatar",
    //   sorting: false,
    //   render: (rowData) => (
    //     <div className="flexRow justifySpaceBetween alignCenter">
    //       {rowData.participant_status.includes(
    //         "Connected: Awaiting Response"
    //       ) ? (
    //         <p
    //           onClick={() => {
    //             handleBadgeClick();
    //           }}
    //           className="marginRight11 marginBottom0"
    //         >
    //           <Badge color="secondary" variant="dot" invisible={invisible}>
    //             <AccountCircleIcon className="avatar-user" />
    //           </Badge>
    //         </p>
    //       ) : (
    //         <p
    //           onClick={() => {
    //             handleBadgeClick();
    //           }}
    //           className="marginRight11 marginBottom0"
    //         >
    //           <AccountCircleIcon className="avatar-user" />
    //         </p>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Name",
      field: "preferred_name",
      sorting: false,
      render: (rowData) => (
        // console.log('rowData', rowData)
        <div className="flexRow alignCenter">
          <span className="marginRight11">
            <AccountCircleIcon className="avatar-user" />
          </span>
          <p className="marginRight11 marginBottom0">
            {rowData.preferred_name}
          </p>
        </div>
      ),
    },

    {
      title: "Current Location",
      field: "address_line1", //stores city, state, county
      sorting: false,
      render: (rowData) => (
        <div className="flexRow justifySpaceBetween alignCenter">
          <p className="marginRight11 marginBottom0">
            {rowData.address_line1 ? rowData.address_line1 : "Not specified"}
          </p>
        </div>
      ),
    },
    // {
    // 	title: "Form Date",
    // 	field: "createdat",
    //  sorting: true,
    // 	render: (rowData) => (
    // 		<div className="flexRow justifySpaceBetween alignCenter">
    // 			<p className="marginRight11 marginBottom0">
    // 				{rowData.created_at}
    // 			</p>
    // 			<img src={rightArrowColorD5D8DD} alt="arrow" className="height17" />
    // 		</div>
    // 	),
    // },
  ];

  return (
    <div className="maxWidth95Percent paddingBottom60 tables peopleList">
      {isDesktop ? (
        <div>
          {formSubmitted === true ? (
            <div>
              <MaterialTable
                tableRef={tableRef}
                title=""
                icons={tableIcons}
                data={dataClient}
                columns={columns}
                options={{
                  search: true,
                  searchFieldAlignment: "right",
                }}
                onRowClick={(e, rowData) => handleClick(rowData)}
                components={{
                  // Custom "Toolbar" needed to show number of people in the table
                  Toolbar: React.memo((props) => {
                    return (
                      <div className="flexRowReverse justifySpaceBetween alignCenter marginTop25 marginBottom15">
                        <MTableToolbar {...props} />

                        <p className="fontSize14 lineHeight16 colorBlack2 marginBottom0 ">
                          {dataClient ? dataClient.length : 0} People
                        </p>
                      </div>
                    );
                  }),
                }}
              />
            </div>
          ) : (
            // if formSubmitted === false, update the parent component of Intake - (PeopleNavbar) and hide the PeopleNavbar <nav>

            //************************************PARTICIPANT INFORMATION**************************************
            <div>
              <div className="participant-bar">
                <span onClick={handleFalse} className="colorGrey3 button ">
                  <ArrowBackIosIcon className="arrow-icon " />
                  Back
                </span>
                <div
                  className={
                    isDesktop
                      ? "participant-infobar marginLeft15"
                      : "participant-infobar"
                  }
                >
                  <div
                    className={
                      isDesktop
                        ? "flexRow alignCenter"
                        : "flexRow alignCenter marginLeft5"
                    }
                  >
                    <AccountCircleIcon className="avatar-icon" />
                    <div className="participant-avatar-div">
                      <p className="participant-username">
                        {selectedProfileData.intake_form_completed ===
                        selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name_general
                          : selectedProfileData.intake_form_completed >
                            selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name
                          : selectedProfileData.preferred_name_general}
                      </p>
                      {/* <p>
                        {selectedProfileData.intake_form_completed ===
                        selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name_general
                          : selectedProfileData.intake_form_completed >
                            selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name
                          : selectedProfileData.preferred_name_general}{" "}
                        {selectedProfileData.intake_form_completed ===
                        selectedProfileData.general_form_completed
                          ? selectedProfileData.last_name_general
                          : selectedProfileData.intake_form_completed >
                            selectedProfileData.general_form_completed
                          ? selectedProfileData.last_name
                          : selectedProfileData.last_name_general}
                      </p> */}
                    </div>
                  </div>
                  <div className="participant-contact">
                    <strong className=""> Phone</strong>
                    {(selectedProfileData.intake_form_completed <=
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.phone_general !== null &&
                      selectedProfileData.phone_general !== "") ||
                    (selectedProfileData.intake_form_completed >
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.phone !== null) ? (
                      <a
                        href={`tel:${
                          selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.phone_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.phone
                            : selectedProfileData.phone_general
                        }`}
                      >
                        <span className="participant-phone">
                          {selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.phone_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.phone
                            : selectedProfileData.phone_general}
                        </span>
                      </a>
                    ) : (
                      <span className="null-value-phone">Not Available</span>
                    )}
                    <br></br>
                    <strong className="">Email</strong>
                    {(selectedProfileData.intake_form_completed <=
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.email_general !== null &&
                      selectedProfileData.email_general !== "") ||
                    (selectedProfileData.intake_form_completed >
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.email !== null) ? (
                      <a
                        href={`mailto:${
                          selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.email_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.email
                            : selectedProfileData.email_general
                        }`}
                      >
                        <span className="participant-email">
                          {selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.email_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.email
                            : selectedProfileData.email_general}
                        </span>
                      </a>
                    ) : (
                      <span className="null-value-email">Not Available</span>
                    )}
                    <br></br>
                    <div className="notify-status-div">
                      <strong className="notify-status-label">
                        Notify/ status
                      </strong>
                      {/* <Select
                    options={options}
                    value={selectedProfileData.participant_status}
                    className="status-dropdown"
                    onChange={handleChangeD}
                  /> */}
                      <select
                        name="participant_status"
                        value={selectedProfileData.participant_status}
                        onChange={handleDropdown}
                        className="status-dropdown"
                      >
                        <option value={selectedProfileData.participant_status}>
                          {selectedProfileData.participant_status}
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Accepted: Call Us for Appointment"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Accepted: Call Us for Appointment"
                        >
                          Accepted: Call Us for Appointment
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Not Eligible: Call Us for Referral"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Not Eligible: Call Us for Referral"
                        >
                          Not Eligible: Call Us for Referral
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Unavailable: Unable to Reach"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Unavailable: Unable to Reach"
                        >
                          Unavailable: Unable to Reach
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Active: Receiving Services Now"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Active: Receiving Services Now"
                        >
                          Active: Receiving Services Now
                        </option>
                      </select>
                      <span
                        className={
                          selectedProfileData.participant_status ===
                          "Connected: Awaiting Response"
                            ? "flag-color adjust-flag "
                            : "flag-none adjust-flag"
                        }
                      >
                        <FlagIcon />
                      </span>
                    </div>
                  </div>
                </div>

                <Participantsnavbar
                  selectedProfileData={selectedProfileData}
                  data={dataClient}
                  peopleCallback={peopleCallback}
                />
              </div>
            </div>
          )}{" "}
        </div>
      ) : (
        <div>
          {formSubmitted === true ? (
            <div>
              <MaterialTable
                tableRef={tableRef}
                title=""
                icons={tableIcons}
                data={dataClient}
                columns={mobileColumns}
                options={{
                  search: true,
                  searchFieldAlignment: "right",
                }}
                onRowClick={(e, rowData) => handleClick(rowData)}
                components={{
                  // Custom "Toolbar" needed to show number of people in the table
                  Toolbar: React.memo((props) => {
                    return (
                      <div className="flexRowReverse justifySpaceBetween alignCenter marginTop25 marginBottom15">
                        <MTableToolbar {...props} />

                        <p className="fontSize14 lineHeight16 colorBlack2 marginBottom0 ">
                          {dataClient ? dataClient.length : 0} People
                        </p>
                      </div>
                    );
                  }),
                }}
              />
            </div>
          ) : (
            // if formSubmitted === false, update the parent component of Intake - (PeopleNavbar) and hide the PeopleNavbar <nav>

            //************************************PARTICIPANT INFORMATION**************************************
            <div>
              <div className="participant-bar">
                <span onClick={handleFalse} className="colorGrey3 button ">
                  <ArrowBackIosIcon className="arrow-icon " />
                  Back
                </span>
                <div
                  className={
                    isDesktop
                      ? "participant-infobar marginLeft15"
                      : "participant-infobar"
                  }
                >
                  <div
                    className={
                      isDesktop
                        ? "flexRow alignCenter"
                        : "flexRow alignCenter marginLeft5"
                    }
                  >
                    <AccountCircleIcon className="avatar-icon" />
                    <div className="participant-avatar-div">
                      <p className="participant-username">
                        {selectedProfileData.intake_form_completed ===
                        selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name_general
                          : selectedProfileData.intake_form_completed >
                            selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name
                          : selectedProfileData.preferred_name_general}
                      </p>
                      {/* <p>
                        {selectedProfileData.intake_form_completed ===
                        selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name_general
                          : selectedProfileData.intake_form_completed >
                            selectedProfileData.general_form_completed
                          ? selectedProfileData.preferred_name
                          : selectedProfileData.preferred_name_general}{" "}
                        {selectedProfileData.intake_form_completed ===
                        selectedProfileData.general_form_completed
                          ? selectedProfileData.last_name
                          : selectedProfileData.intake_form_completed >
                            selectedProfileData.general_form_completed
                          ? selectedProfileData.last_name
                          : selectedProfileData.last_name_general}
                      </p> */}
                    </div>
                  </div>
                  <div className="participant-contact">
                    <strong className=""> Phone</strong>
                    {(selectedProfileData.intake_form_completed <=
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.phone_general !== null &&
                      selectedProfileData.phone_general !== "") ||
                    (selectedProfileData.intake_form_completed >
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.phone !== null) ? (
                      <a
                        href={`tel:${
                          selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.phone_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.phone
                            : selectedProfileData.phone_general
                        }`}
                      >
                        <span className="participant-phone">
                          {selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData._general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.phone
                            : selectedProfileData.phone_general}
                        </span>
                      </a>
                    ) : (
                      <span className="null-value-phone">Not Available</span>
                    )}
                    <br></br>
                    <strong className="">Email</strong>
                    {(selectedProfileData.intake_form_completed <=
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.email_general !== null &&
                      selectedProfileData.email_general !== "") ||
                    (selectedProfileData.intake_form_completed >
                      selectedProfileData.general_form_completed &&
                      selectedProfileData.email !== null) ? (
                      <a
                        href={`mailto:${
                          selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.email_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.email
                            : selectedProfileData.email_general
                        }`}
                      >
                        <span className="participant-email">
                          {selectedProfileData.intake_form_completed ===
                          selectedProfileData.general_form_completed
                            ? selectedProfileData.email_general
                            : selectedProfileData.intake_form_completed >
                              selectedProfileData.general_form_completed
                            ? selectedProfileData.email
                            : selectedProfileData.email_general}
                        </span>
                      </a>
                    ) : (
                      <span className="null-value-email">Not Available</span>
                    )}
                    <br></br>
                    <div className="notify-status-div">
                      <strong className="notify-status-label">
                        Notify/ status
                      </strong>
                      {/* <Select
                    options={options}
                    value={selectedProfileData.participant_status}
                    className="status-dropdown"
                    onChange={handleChangeD}
                  /> */}
                      <select
                        name="participant_status"
                        value={selectedProfileData.participant_status}
                        onChange={handleDropdown}
                        className="status-dropdown"
                      >
                        <option value={selectedProfileData.participant_status}>
                          {selectedProfileData.participant_status}
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Accepted: Call Us for Appointment"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Accepted: Call Us for Appointment"
                        >
                          Accepted: Call Us for Appointment
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Not Eligible: Call Us for Referral"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Not Eligible: Call Us for Referral"
                        >
                          Not Eligible: Call Us for Referral
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Unavailable: Unable to Reach"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Unavailable: Unable to Reach"
                        >
                          Unavailable: Unable to Reach
                        </option>
                        <option
                          className={
                            selectedProfileData.participant_status.includes(
                              "Active: Receiving Services Now"
                            )
                              ? "display-none"
                              : ""
                          }
                          value="Active: Receiving Services Now"
                        >
                          Active: Receiving Services Now
                        </option>
                      </select>
                      <span
                        className={
                          selectedProfileData.participant_status ===
                          "Connected: Awaiting Response"
                            ? "flag-color adjust-flag "
                            : "flag-none adjust-flag"
                        }
                      >
                        <FlagIcon />
                      </span>
                    </div>
                  </div>
                </div>

                <Participantsnavbar
                  selectedProfileData={selectedProfileData}
                  data={dataClient}
                  peopleCallback={peopleCallback}
                />
              </div>
            </div>
          )}{" "}
        </div>
      )}
    </div>
  );
};
export default Clients;
