import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  // Input,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import {
  AddBox,
  ErrorOutline,
  Visibility,
  VisibilityOff,
  // ToggleOff,
  // ToggleOn,
} from "@material-ui/icons";
import { InputAdornment } from "@mui/material";
import React, { useState } from "react";
import deleteIcon from "../../Assets/delete-icon.png";
// import editbutton from "../Assets/editbutton.png";
//import editbuttonedit from "../Assets/edit-edit.png";
import { Divider } from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import "../../App.css";
import { useAppContext } from "../../useContext";
import mixpanel from "mixpanel-browser";

const ColorButton = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "40px",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "smaller",
    fontWeight: "bolder",
    letterSpacing: "normal",
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#A10070",
    "&:hover": {
      backgroundColor: "#A10070",
      opacity: "0.8",
    },
  },
}))(Button);

const GrayButton = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "40px",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "smaller",
    fontWeight: "bolder",
    letterSpacing: "normal",
    color: "black",
    backgroundColor: "lightgray",
    "&:hover": {
      backgroundColor: "gray",
      opacity: "0.8",
      color: "white",
    },
  },
}))(Button);

// for cancel button in deprecated iteration
// const BlackButton = withStyles((theme) => ({
//   root: {
//     width: "100px",
//     height: "40px",
//     textTransform: "none",
//     fontFamily: "Arial",
//     fontSize: "smaller",
//     fontWeight: "bolder",
//     letterSpacing: "normal",
//     color: "#ffffff",
//     backgroundColor: "#000000",
//   },
// }))(Button);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const Settings = (props) => {
  // console.log(props); //windowSize
  const CONTEXT = {
    SETTINGS: 0,
    VERIFICATION_CODE: 1,
    REMOVE_PROFILE: 2,
    REMOVED: 3,
    REINSTATE_PROFILE: 4,
    REINSTATED: 5,
  };
  const size = props.windowSize;
  // console.log(size);
  const { email, providerId, isVisible, setIsVisible } = useAppContext();
  // console.log(isVisible);
  const [pageToShow, setPageToShow] = useState(CONTEXT.SETTINGS);
  // const [editPassword, setEditPassword] = useState(false);
  // const [email, setEmail] = useState("");
  const [resetValues, setResetValues] = useState({
    verificationCode: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  // const [values, setValues] = React.useState({
  //   newPassword: "",
  //   oldPassword: "",
  // });

  //password help text states
  const [passwordError, setPasswordError] = useState(["initial state"]);
  const defaultPwdHelpText =
    "Please make sure your password has 8 to 20 characters, a number, a lowercase letter, an uppercase letter, and a special character (e.g., !@#$%^&*()).";
  const [passwordHelpText, setPasswordHelpText] = useState(defaultPwdHelpText);

  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [resendCodeModal, setResendCodeModal] = useState(false);
  const [passwordMismatchModal, setPasswordMismatchModal] = useState(false);
  const [passwordUpdatedModal, setPasswordUpdatedModal] = useState(false);

  //delete account states
  const [deleteData, setDeleteData] = useState({
    request: "",
    deleted_reason: [],
    deleted_other: "",
    password: "",
    showPassword: false,
    // toggleClick: isVisible,
  });
  mixpanel.track("P Settings PgVw", {
    "Event Type": "Page View",
  });

  // const size = useWindowSize();

  // function useWindowSize() {
  //   // Initialize state with undefined width/height so server and client renders match
  //   // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  //   const [windowSize, setWindowSize] = useState({
  //     width: undefined,
  //     height: undefined,
  //   });
  //   useEffect(() => {
  //     // Handler to call on window resize
  //     function handleResize() {
  //       // Set window width/height to state
  //       setWindowSize({
  //         width: window.innerWidth,
  //         height: window.innerHeight,
  //       });
  //     }
  //     // Add event listener
  //     window.addEventListener("resize", handleResize);
  //     // Call handler right away so state gets updated with initial window size
  //     handleResize();
  //     // Remove event listener on cleanup
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []); // Empty array ensures that effect is only run on mount
  //   return windowSize;
  // }

  // var handleEditButtonClick = () => {
  //   setEditPassword(true);
  // };

  const isPasswordValid = (value) => {
    const validPasswordRegex =
      // /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()-+_;:'"<>,.?]{8,20}$/;
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()]).{8,20}$/;
    const hasSyms = /^(?=.*?[!@#$%^&*()]).{1,20}$/;
    const hasNums = /^(?=.*?[0-9]).{1,20}$/;
    const hasCaps = /^(?=.*?[A-Z]).{1,20}$/;
    const hasMins = /^(?=.*?[a-z]).{1,20}$/;
    // const needLength = /^[\s\S]{1,7}$/;
    let needs = [...passwordError];
    if (value.match(validPasswordRegex)) {
      setPasswordError(["valid password"]);
      setPasswordHelpText("");
      return true;
    } else if (value === "initial state") {
      setPasswordError(["invalid password"]);
      setPasswordHelpText(defaultPwdHelpText);
      return false;
    } else {
      if (needs.includes("valid password")) {
        let index = needs.findIndex((item) => item === "valid password");
        needs.splice(index, 1);
      }
      if (!needs.includes("invalid password")) {
        needs.push("invalid password");
      }
      if (value.match(hasSyms)) {
        if (!needs.includes("hasSyms")) {
          needs.push("hasSyms");
        }
      } else {
        needs = needs.filter((item) => item !== "hasSyms");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasNums)) {
        if (!needs.includes("hasNums")) {
          needs.push("hasNums");
        }
      } else {
        needs = needs.filter((item) => item !== "hasNums");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasCaps)) {
        if (!needs.includes("hasCaps")) {
          needs.push("hasCaps");
        }
      } else {
        needs = needs.filter((item) => item !== "hasCaps");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasMins)) {
        if (!needs.includes("hasMins")) {
          needs.push("hasMins");
        }
      } else {
        needs = needs.filter((item) => item !== "hasMins");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.length < 8 || value.length > 20) {
        // console.log("needLength");
        if (!needs.includes("needLength")) {
          needs.push("needLength");
        }
      } else {
        needs = needs.filter((item) => item !== "needLength");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      // console.log(needs);
      setPasswordError(needs);
      let helpText = [
        needs.includes("needLength") && "8 to 20 characters",
        !needs.includes("hasNums") && "a number",
        !needs.includes("hasMins") && "a lowercase letter",
        !needs.includes("hasCaps") && "an uppercase letter",
        !needs.includes("hasSyms") && "a special character (e.g. !@#$%^&*())",
      ].filter(Boolean);
      if (
        helpText.length === 0 &&
        needs.includes("invalid password") &&
        needs.length === 1
      ) {
        helpText = defaultPwdHelpText;
      } else if (helpText.length === 1) {
        helpText = "Please make sure your password has " + helpText[0] + ".";
      } else if (helpText.length === 2) {
        helpText =
          "Please make sure your password has " + helpText.join(" and ") + ".";
      } else if (helpText.length > 2) {
        let lastItem = " and " + helpText.pop() + ".";
        helpText =
          "Please make sure your password has " +
          helpText.join(", ") +
          lastItem;
      }
      // console.log(needs, helpText);
      setPasswordHelpText(helpText);
      return false;
    }
  };

  const handleSave = () => {
    if (resetValues.password === resetValues.confirmPassword) {
      Auth.forgotPasswordSubmit(
        email,
        resetValues.verificationCode,
        resetValues.password
      )
        .then((data) => {
          // console.log(data); //undefined - why?
          if (data === "SUCCESS" || data === undefined) {
            setPageToShow(CONTEXT.SETTINGS);
            setPasswordUpdatedModal(true);
            mixpanel.track("P ResetPW Click", { "Event Type": "User Action" });
          }
        })
        .catch((e) => {
          alert(e.message);
        });
    } else {
      setPasswordMismatchModal(true);
    }
    //deprecated change old password to new password flow
    // Auth.currentAuthenticatedUser()
    //   .then((username) => {
    //     Auth.changePassword(username, values.oldPassword, values.newPassword)
    //       .then((data) => {
    //         setEditPassword(false);
    //         setValues({ ...values, oldPassword: "" });
    //         setValues({ ...values, newPassword: "" });
    //         alert("Successfully updated password!");
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         if (err.code === "NotAuthorizedException") {
    //           alert(
    //             "You have entered an incorrect old password. If you can't remember your password, please logout and click on 'Forgot Password'."
    //           );
    //         } else {
    //           alert(err.message);
    //         }
    //       });
    //   })
    //   .catch((err) => console.log(err));
  };

  // const handleNewPasswordChange = (event) => {
  //   setValues({ ...values, newPassword: event.target.value });
  // };

  // const handleOldPasswordChange = (event) => {
  //   setValues({ ...values, oldPassword: event.target.value });
  // };

  // const handleCancel = () => {
  //   setEditPassword(false);
  //   setValues({ ...values, oldPassword: "" });
  //   setValues({ ...values, newPassword: "" });
  // };

  const onChangeText = (e) => {
    // console.log(e.target.name, e.target.value);
    if (e.target.name === "password") {
      isPasswordValid(e.target.value);
    }
    let resetValuesCopy = { ...resetValues, [e.target.name]: e.target.value };
    setResetValues(resetValuesCopy);
    let deleteDataCopy = {
      ...deleteData,
      [e.target.name]: e.target.value,
      request: "delete",
    };
    setDeleteData(deleteDataCopy);
  };

  const handleSendCodeClick = async () => {
    try {
      await Auth.forgotPassword(email.toLowerCase());
      setPageToShow(CONTEXT.VERIFICATION_CODE);
    } catch (e) {
      alert(e.message);
    }
  };

  // resend verification code if another code is needed
  const resendVerificationCode = async () => {
    // console.log(newData.email); //email address is capitalized, must lowercase to void UserNotFoundException error
    setResetValues({
      ...resetValues,
      verificationCode: "",
    });
    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    try {
      await Auth.forgotPassword(email);
    } catch (e) {
      alert(e.message);
    }
  };

  const handleRemovalChange = (e) => {
    // console.log(e.target.name, e.target.checked, e.target.value);
    let deleteDataReasons = [...deleteData.deleted_reason];
    if (e.target.checked) {
      deleteDataReasons.push(e.target.value);
      setDeleteData({
        ...deleteData,
        deleted_reason: deleteDataReasons,
      });
    } else if (e.target.name === "deleted_other") {
      setDeleteData({
        ...deleteData,
        deleted_other: e.target.value,
      });
    } else if (e.target.name === "password") {
      setDeleteData({
        ...deleteData,
        password: e.target.value,
      });
    } else {
      deleteDataReasons.splice(deleteDataReasons.indexOf(e.target.value), 1);
      setDeleteData({ ...deleteData, deleted_reason: deleteDataReasons });
    }
    // console.log(deleteData);
  };

  const handleClickShowPassword = () => {
    setResetValues({ ...resetValues, showPassword: !resetValues.showPassword });
    setDeleteData({ ...deleteData, showPassword: !deleteData.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setResetValues({
      ...resetValues,
      showConfirmPassword: !resetValues.showConfirmPassword,
    });
    setDeleteData({
      ...deleteData,
      showConfirmPassword: !deleteData.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUpdateProfile = (event) => {
    // console.log(deleteData);
    // must run lambda to change the is_visible and/or is_deleted values in respective tables first before setPageToShow
    API.post("referall-provider", "updateproviderprofile", {
      body: {
        request: deleteData.request,
        deleted_reason: deleteData.deleted_reason,
        deleted_other: deleteData.deleted_other,
        provider_id: providerId,
      },
    })
      .then((response) => {
        // value of is_visible returned
        // console.log(response);
        // reset values of deleteData
        setDeleteData({
          request: "",
          deleted_reason: [],
          deleted_other: "",
          password: "",
          showPassword: false,
        });
        // console.log(isVisible);
        if (response) {
          setIsVisible(true);
          setPageToShow(CONTEXT.REINSTATED);
        } else {
          setIsVisible(false);
          setPageToShow(CONTEXT.REMOVED);
        }
        // console.log(isVisible);
      })
      .catch((e) => {
        console.log(e);
        mixpanel.track("P ConsoleLog Error", {
          "Event Type": "API Call",
          "Error Message": e,
          "API Name": "updateproviderprofile",
          "File Name": "Settings.js",
          "File Line": 490,
        });
      });
  };

  // useEffect(() => {
  //   Auth.currentAuthenticatedUser().then((user) => {
  //     console.log(user);
  //     setEmail(user.attributes.email);
  //   });
  // }, []);

  return (
    <div className="settings-wrapper">
      <div
        className="header-wrapper"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 className="settings-header">
          {pageToShow === CONTEXT.REMOVE_PROFILE
            ? "We're sad to see you go"
            : pageToShow === CONTEXT.REMOVED
            ? "Your Organization Has Been Removed"
            : pageToShow === CONTEXT.REINSTATE_PROFILE
            ? "Reinstate Profile"
            : pageToShow === CONTEXT.REINSTATED
            ? "Profile Reinstated"
            : "Settings"}
        </h1>

        {/* {editPassword === false && size.width < 768 ? (
          <div
            className="mobileEditButton-wrapper"
            style={{
              marginRight: "10%",
            }}
          >
            <Button
              className="mobileEditButton mobile-width"
              onClick={handleEditButtonClick}
            >
              <figure>
                <img src={editbutton} alt="Icon" />
                <figcaption>Edit</figcaption>
              </figure>
            </Button>
          </div>
        ) : editPassword === false && size.height < 768 ? (
          <div>
            <Button
              className="mobileEditButton mobile-height"
              onClick={handleEditButtonClick}
            >
              <figure>
                <img src={editbutton} alt="Icon" />
                <figcaption>Edit</figcaption>
              </figure>
            </Button>
          </div>
        ) : null} */}
      </div>

      {pageToShow === CONTEXT.SETTINGS && (
        <div className="border-setting">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width:
                // size.width < 768 ? "95vw" : "500px",
                size[0] < 768 ? "95vw" : "500px",
            }}
          >
            <span
              // className="settings-style-email"
              style={{
                // marginRight: width < "768px" ? "36px" : "80px",
                width:
                  // size.width < "768px" ? "35%" : "25%", //stuck at 25%
                  size[0] < "768px" ? "35%" : "25%", //stuck at 25%
              }}
            >
              <strong>Email</strong>&emsp;
            </span>
            <span className="email">{email}</span>
          </div>
          <br></br>
          <Divider
            style={{
              width:
                // size.width < 768 ? "95vw" : "500px"
                size[0] < 768 ? "95vw" : "500px",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width:
                // size.width < 768 ? "95vw" : "500px",
                size[0] < 768 ? "95vw" : "500px",
            }}
          >
            <p
              style={{
                margin: "0",
                width:
                  // size.width < "768px" ? "35%" : "25%", //stuck at 25%
                  size[0] < 768 ? "35%" : "25%", //stuck at 25%
              }}
            >
              <strong>Password</strong>
            </p>
            {/* {editPassword === true ? (
            <div style={{ marginLeft: "50px" }}>
              <Input
                placeholder="Old Password"
                onChange={handleOldPasswordChange}
                value={values.oldPassword}
                variant="outlined"
              />
              <Input
                placeholder="New Password"
                onChange={handleNewPasswordChange}
                value={values.newPassword}
                variant="outlined"
              />
            </div>
          ) : ( */}
            <p
              // className="password-field-mobile"
              style={{
                margin: "0% 10% 0% 0%",
                padding: "15px 0",
              }}
            >
              ********
            </p>
            {/* )}
          {editPassword === false && width > 768 && size.height > 768 ? (
            <div>
              <Button
                className="desktopEditButton"
                onClick={handleEditButtonClick}
              >
                <figure>
                  <img src={editbutton} alt="Icon" />
                  <figcaption>Edit</figcaption>
                </figure>
              </Button>
            </div>
          ) : null} */}
          </div>
          <Divider
            style={{
              width:
                // size.width < 768 ? "95vw" : "500px"
                size[0] < 768 ? "95vw" : "500px",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width:
                // size.width < 768 ? "95vw" : "500px",
                size[0] < 768 ? "95vw" : "500px",
            }}
          >
            <p
              style={{
                margin: "0",
                width:
                  // size.width < "768px" ? "35%" : "25%", //stuck at 25%
                  size[0] < "768px" ? "35%" : "25%", //stuck at 25%
              }}
            ></p>
            <p
              style={{ margin: "5% 0%" }}
              onClick={() => setResetPasswordModal(true)}
            >
              <strong className="textDecorationUnderline colorBlue0">
                Reset Password
              </strong>
            </p>
          </div>
          {isVisible ? (
            <div
              className="padding20-0 flexRow"
              onClick={() => setPageToShow(CONTEXT.REMOVE_PROFILE)}
            >
              {
                // needs to run lambda to get org info for value of is_deleted to show "Reactivate Account" when true OR when false show:
                // if lambda is checking for value of is_visible, then show "Reactivate Account" when false OR when true show:
                <>
                  <p className="bold marginTop0">Remove Profile</p>
                  <img
                    className="margin2-10-20"
                    src={deleteIcon}
                    alt="delete"
                  />
                </>
              }
            </div>
          ) : (
            <div
              className="padding20-0 flexRow"
              onClick={() => {
                // setDeleteData({
                //   ...deleteData,
                //   toggleClick: !deleteData.toggleClick,
                // });
                setPageToShow(CONTEXT.REINSTATE_PROFILE);
              }}
            >
              {
                // needs to run lambda to get org info for value of is_visible to show "Reactivate Account" when true OR when false show:
                // if lambda is checking for value of is_visible, then show "Reactivate Account" when false OR when true show:
                <>
                  <p className="bold marginTop0">Reinstate Profile</p>
                  <span className="marginLeft5">
                    <AddBox fontSize="medium" />
                    {/* {deleteData.toggleClick ? (
                      <ToggleOn fontSize="large" />
                    ) : (
                      <ToggleOff fontSize="large" />
                    )} */}
                  </span>
                </>
              }
            </div>
          )}
        </div>
      )}
      {pageToShow === CONTEXT.VERIFICATION_CODE && (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            width:
              // size.width < 768 ? "95vw" : "500px",
              size[0] < 768 ? "95vw" : "500px",
          }}
        >
          <p>
            Please check your email for a verification code to enter below. It
            may take a few minutes for your code to arrive.
          </p>
          <form
            className="password-recover-form"
            onSubmit={handleSendCodeClick}
          >
            <p className="recovery-email-label bold marginBottom0">
              Verification Code
            </p>
            <TextField
              name="verificationCode"
              fullWidth
              required
              variant="outlined"
              value={resetValues.verificationCode}
              onChange={onChangeText}
            />
            <p
              className="textDecorationUnderline colorBlue0 marginTop0"
              onClick={() => setResendCodeModal(true)}
            >
              Resend Code
            </p>
            <p className="recovery-email-label bold marginTop25 marginBottom0">
              Password
            </p>
            <TextField
              name="password"
              type={resetValues.showPassword ? "text" : "password"}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordError.includes("invalid password") && (
                      <ErrorOutline />
                    )}
                    <IconButton
                      className="icon-eye-login paddingRight0"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {resetValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={resetValues.password}
              onChange={onChangeText}
            />
            {passwordError.includes("initial state") &&
            passwordError.length === 1 ? (
              <p className="fontSize14 marginTop0">{passwordHelpText}</p>
            ) : (
              passwordError.includes("invalid password") && (
                <p className="colorRed fontSize14">{passwordHelpText}</p>
              )
            )}
            <p className="recovery-email-label bold marginTop25 marginBottom0">
              Confirm Password
            </p>
            <TextField
              name="confirmPassword"
              type={resetValues.showConfirmPassword ? "text" : "password"}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon-eye-login paddingRight0"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {resetValues.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={resetValues.confirmPassword}
              onChange={onChangeText}
            />
            <div className="marginTop25">
              <ColorButton
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={
                  resetValues.verificationCode.length === 6 &&
                  resetValues.password.length >= 8 &&
                  // resetValues.password === resetValues.confirmPassword
                  resetValues.confirmPassword.length >= 8
                    ? false
                    : true
                }
              >
                Save
              </ColorButton>
            </div>
          </form>
        </div>
      )}
      {pageToShow === CONTEXT.REMOVE_PROFILE && (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            width:
              // size.width < 768 ? "95vw" : "500px",
              size[0] < 768 ? "95vw" : "500px",
          }}
        >
          <p>
            Removing your profile will remove your organizations’s profile from
            ResourceFull Service Provider List immediately.
            {/* We know you have information you may need to access so you will continue to have
            access to your account for 6 months. */}
          </p>
          <form
            className="password-recover-form"
            onSubmit={handleSendCodeClick}
          >
            <p className="bold marginBottom0">
              Tell us why you are leaving (Check All That Apply)
            </p>
            <FormGroup className="marginBottom20">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      deleteData.deleted_reason.includes(
                        "Our organization is closing"
                      )
                        ? true
                        : false
                    }
                    name="our_organization_is_closing"
                    onChange={handleRemovalChange}
                    value="Our organization is closing"
                    color="primary"
                  />
                }
                label="Our organization is closing"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      deleteData.deleted_reason.includes(
                        "We are not receiving referrals"
                      )
                        ? true
                        : false
                    }
                    name="we_are_not_receiving_referrals"
                    onChange={handleRemovalChange}
                    value="We are not receiving referrals"
                    color="primary"
                  />
                }
                label="We are not receiving referrals"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      deleteData.deleted_reason.includes(
                        "It’s too hard to update our profile"
                      )
                        ? true
                        : false
                    }
                    name="its_too_hard_to_update_our_profile"
                    onChange={handleRemovalChange}
                    value="It’s too hard to update our profile"
                    color="primary"
                  />
                }
                label="It’s too hard to update our profile"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      deleteData.deleted_reason.includes(
                        "ResourceFull is not working for our needs"
                      )
                        ? true
                        : false
                    }
                    name="resourcefull_is_not_working_for_our_needs"
                    onChange={handleRemovalChange}
                    value="ResourceFull is not working for our needs"
                    color="primary"
                  />
                }
                label="ResourceFull is not working for our needs"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      deleteData.deleted_reason.includes("Other") ? true : false
                    }
                    name="other"
                    onChange={handleRemovalChange}
                    value="Other"
                    color="primary"
                  />
                }
                label="Other"
              />
              {deleteData.deleted_reason.includes("Other") && (
                <textarea
                  placeholder="Tell us more (optional)"
                  name="deleted_other"
                  value={deleteData.deleted_other}
                  onChange={handleRemovalChange}
                  rows={size[0] >= 768 ? 5 : 8}
                ></textarea>
              )}
            </FormGroup>
            <p className="bold">
              If you are sure you want to proceed with removing your profile,
              please re-enter your password below.
            </p>
            <p className="recovery-email-label bold marginTop25 marginBottom0">
              Password
            </p>
            <TextField
              name="password"
              type={deleteData.showPassword ? "text" : "password"}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon-eye-login"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {deleteData.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={deleteData.password}
              onChange={onChangeText}
            />
            <div className="marginTop25 flexRow justifySpaceBetween">
              <GrayButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  setPageToShow(CONTEXT.SETTINGS);
                  setDeleteData({
                    request: "",
                    deleted_reason: [],
                    deleted_other: "",
                    password: "",
                    showPassword: false,
                  });
                }}
              >
                Cancel
              </GrayButton>
              <ColorButton
                variant="contained"
                color="primary"
                onClick={handleUpdateProfile}
                disabled={
                  deleteData.deleted_reason.length > 0 &&
                  deleteData.password.length >= 8
                    ? false
                    : true
                }
              >
                Submit
              </ColorButton>
            </div>
          </form>
        </div>
      )}
      {pageToShow === CONTEXT.REMOVED && (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            width:
              // size.width < 768 ? "95vw" : "500px",
              size[0] < 768 ? "95vw" : "500px",
          }}
        >
          <p>
            Your organization’s profile has been successfully removed from the
            ResourceFull Service Provider List.
          </p>
          <p>
            You will continue to have access to your account in case you need to
            access information available on the platform.
          </p>
          <p className="marginBottom40">
            If you change your mind, you can reinstate your profile at any time
            in your Profile Settings.
          </p>
          {/* <p className="marginBottom40">
            We will send you reminders as the 6 month mark draws near.
          </p> */}
          {/* <p className="bold">Important</p>
          <p className="marginBottom40">
            If you don’t reinstate your account by this time, your information
            and access to information about the people you served will be
            permanently deleted.
          </p>
          <p className="bold">Delete Your Account Immediately</p>
          <p className="marginBottom40">
            If you and your team are certain that you want to delete your
            account immediately, you have been sent an email with instructions
            for how to do so. Please remember, this will permanently delete all
            information your team had access to through your account.
          </p> */}
          <div className="flexRow">
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => setPageToShow(CONTEXT.SETTINGS)}
              // disabled={
              //   deleteData.deleted_reason.length > 0 &&
              //   deleteData.password.length >= 8
              //     ? false
              //     : true
              // }
            >
              Continue
            </ColorButton>
          </div>
        </div>
      )}
      {pageToShow === CONTEXT.REINSTATE_PROFILE && (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            width:
              // size.width < 768 ? "95vw" : "500px",
              size[0] < 768 ? "95vw" : "500px",
          }}
        >
          <p className="marginBottom40">
            Reinstating your profile will add your organizations’s profile to
            the ResourceFull Service Provider List immediately.
            {/* We know you have information you may need to access so you will continue to have
            access to your account for 6 months. */}
          </p>
          <div className="marginTop25 flexRow justifySpaceBetween">
            <GrayButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setPageToShow(CONTEXT.SETTINGS);
                // setDeleteData({
                //   ...deleteData,
                //   toggleClick: !deleteData.toggleClick,
                // });
              }}
            >
              Cancel
            </GrayButton>
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => {
                setDeleteData({
                  ...deleteData,
                  request: "reinstate",
                });
                handleUpdateProfile();
              }}
            >
              Reinstate
            </ColorButton>
          </div>
        </div>
      )}
      {pageToShow === CONTEXT.REINSTATED && (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            width:
              // size.width < 768 ? "95vw" : "500px",
              size[0] < 768 ? "95vw" : "500px",
          }}
        >
          <p>
            Your organization’s profile has been successfully added tp the
            ResourceFull Service Provider List.
          </p>
          <p className="marginBottom40">
            If you change your mind, you can remove your profile at any time in
            your Profile Settings.
          </p>
          {/* <p className="marginBottom40">
            We will send you reminders as the 6 month mark draws near.
          </p> */}
          {/* <p className="bold">Important</p>
          <p className="marginBottom40">
            If you don’t reinstate your account by this time, your information
            and access to information about the people you served will be
            permanently deleted.
          </p>
          <p className="bold">Delete Your Account Immediately</p>
          <p className="marginBottom40">
            If you and your team are certain that you want to delete your
            account immediately, you have been sent an email with instructions
            for how to do so. Please remember, this will permanently delete all
            information your team had access to through your account.
          </p> */}
          <div className="flexRow">
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => setPageToShow(CONTEXT.SETTINGS)}
              // disabled={
              //   deleteData.deleted_reason.length > 0 &&
              //   deleteData.password.length >= 8
              //     ? false
              //     : true
              // }
            >
              Continue
            </ColorButton>
          </div>
        </div>
      )}
      {/* {editPassword === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: size.width < 768 ? "95vw" : "500px",
            marginTop: "30px",
          }}
        >
          <BlackButton
            variant="contained"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </BlackButton>
          <ColorButton variant="contained" color="primary" onClick={handleSave}>
            Save
          </ColorButton>
        </div>
      ) : null} */}
      {/* modal shows when user clicks reset password link */}
      <Modal
        open={resetPasswordModal}
        onClose={() => setResetPasswordModal(false)}
        aria-labelledby="reset-password-modal"
        aria-describedby="reset-password-modal"
      >
        <Box sx={size[0] < 768 ? styleMobile : style}>
          <Typography className="modal-heading" variant="h6" component="h2">
            One-Time Passcode
          </Typography>
          <Typography className="modal-text">
            We can email you a one-time passcode to enter here. The code will
            expire in 24 hours.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-first textDecorationUnderline"
            onClick={() => {
              setResetPasswordModal(false);
              handleSendCodeClick();
            }}
          >
            Send Code
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last textDecorationUnderline"
            onClick={() => setResetPasswordModal(false)}
          >
            Cancel
          </Typography>
        </Box>
      </Modal>
      {/* modal shows when user clicks resend code link */}
      <Modal
        open={resendCodeModal}
        onClose={() => setResendCodeModal(false)}
        aria-labelledby="resend-code-modal"
        aria-describedby="resend-code-modal"
      >
        <Box sx={size[0] < 768 ? styleMobile : style}>
          <Typography className="modal-heading" variant="h6" component="h2">
            One-Time Passcode
          </Typography>
          <Typography className="modal-text">
            We can email you a one-time passcode to enter here. The code will
            expire in 24 hours.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-first textDecorationUnderline"
            onClick={() => {
              setResendCodeModal(false);
              resendVerificationCode();
            }}
          >
            Send Code
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last textDecorationUnderline"
            onClick={() => setResendCodeModal(false)}
          >
            Cancel
          </Typography>
        </Box>
      </Modal>
      {/* modal shows when password and confirmPassword are mismatched */}
      <Modal
        open={passwordMismatchModal}
        onClose={() => setPasswordMismatchModal(false)}
        aria-labelledby="password-updated-modal"
        aria-describedby="password-updated-modal"
      >
        <Box sx={size[0] < 768 ? styleMobile : style}>
          {/* <Typography className="modal-heading" variant="h6" component="h2">
            Password Mismatch
          </Typography> */}
          <Typography className="modal-text">
            Passwords do not match. Please check and try again.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last textDecorationUnderline"
            onClick={() => {
              setPasswordMismatchModal(false);
            }}
          >
            Try Again
          </Typography>
        </Box>
      </Modal>
      {/* modal shows when password has been successfully updated */}
      <Modal
        open={passwordUpdatedModal}
        onClose={() => setPasswordUpdatedModal(false)}
        aria-labelledby="password-updated-modal"
        aria-describedby="password-updated-modal"
      >
        <Box sx={size[0] < 768 ? styleMobile : style}>
          <Typography className="modal-heading" variant="h6" component="h2">
            Confirmed
          </Typography>
          <Typography className="modal-text">
            Your password has been updated.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last textDecorationUnderline"
            onClick={() => {
              setPasswordUpdatedModal(false);
            }}
          >
            OK
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Settings;
