import React, { useState, useEffect } from "react";
import { Route, NavLink, withRouter } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemText,
  Badge,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonAdd from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../Assets/ResourceFull-Horizontal-Final.png";
// import LogoMobile from "../Assets/ResourceFull-Horizontal-Mobile.png";
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HelpIcon from "@material-ui/icons/Help";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { faHandsHelping } from "@fortawesome/free-solid-svg-icons";
import ServiceProviders from "../Pages/ServiceProviders/ServiceProviders";
// import { ServiceProviders } from "../Pages/ServiceProviders/ServiceProvidersV2";
import Help from "../Pages/Help/Help";
import HelpContact from "../Pages/HelpContact/HelpContact";
import Settings from "../Pages/Settings/Settings";
import AdminAddProvider from "../Pages/AdminAddProvider";
import Ourprofilenav from "../Pages/OurProfile/ourprofilenav";
import PeopleNavbar from "../Pages/People/PeopleNavbar";
import { useAppContext } from "../useContext";
import config from "../config";

// add https://resourcefullapp.org/#partner to Become a Partner tab

// add https://resourcefullapp.org/#partner to Become a Partner tab

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    "@media (min-width: 768px)": {
      // height: "25px",
      height: "auto",
    },
    "@media (max-width: 767px)": {
      // height: "25px",
      height: "auto",
    },
    background: "#007DB3",
    boxShadow: "none",
    "@media (max-height: 600px)": {
      // height: "32px",
      height: "auto",
    },
  },
  appBarInteriorWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100vw",
  },
  menuIcon: {
    "@media (max-height: 600px)": {
      marginLeft: "10px",
      borderRadius: "5px",
    },
    "@media (max-width: 767px)": {
      marginLeft: "10px",
      borderRadius: "5px",
    },
  },
  logo: {
    width: "150px",
  },
  appBarText: {
    color: "#FFFFFF",
    padding: "0px 6px 3px 3px",
  },
  text: {
    color: "#007db3",
  },
  drawerPaper: {
    top: "20px",
  },
  drawerPaperToggle: {
    top: "20px",
  },
  drawerTablet: {
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  // drawerDesktop: {
  //   "@media (min-width: 768px)": {
  //     height: "90vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "space-between",
  //   },
  // },
  // drawerDestopAuth: {
  //   "@media (min-width: 768px)": {
  //     height: "90vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-end",
  //   },
  // },
}));

const ClippedDrawer = (props) => {
  const classes = useStyles();
  const [invisible, setInvisible] = useState(false);
  const size = props.windowSize;
  // const size = useWindowSize();
  // const isMobile = window.matchMedia("(max-width: 767px)").matches;
  const [drawer, drawerShow] = useState(false);
  const {
    provider,
    setProvider,
    providerId,
    setProviderId,
    // setEmail,
    role,
    isAuthenticated,
    userHasAuthenticated,
    colors,
    user_id,
  } = useAppContext();
  const { history } = props;
  const color2 = colors[1];
  const color = colors[0];

  //this function helps left drawer rerender for portrait/landscape views; however, it refreshes tables/forms upon mobile scroll
  // function useWindowSize() {
  //   // Initialize state with undefined width/height so server and client renders match
  //   // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  //   const [windowSize, setWindowSize] =
  //     // useState({
  //     //   width: undefined,
  //     //   height: undefined,
  //     // });
  //     useState([undefined, undefined]);
  //   useEffect(() => {
  //     // Handler to call on window resize
  //     function handleResize() {
  //       // Set window width/height to state
  //       // setWindowSize({
  //       //   width: window.innerWidth,
  //       //   height: window.innerHeight,
  //       // });
  //       setWindowSize([window.innerWidth, window.innerHeight]);
  //     }
  //     // Add event listener
  //     window.addEventListener("resize", handleResize);
  //     // Call handler right away so state gets updated with initial window size
  //     handleResize();
  //     // Remove event listener on cleanup
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []); // Empty array ensures that effect is only run on mount
  //   return windowSize;
  // }

  const showMenu = () => {
    drawerShow(true);
    // console.log(typeof window, window.document);
    // console.log(props);
    // Disables Background Scrolling whilst the SideDrawer/Modal is open
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };
  const hideMenu = () => {
    drawerShow(false);
    // Unsets Background Scrolling to use when SideDrawer/Modal is closed
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    API.post("referall-provider", "getpeopledrawernotification", {
      body: {
        // provider_name: provider,
        // provider: provider,
        provider_id: providerId
      },
    }).then((response) => {
      setInvisible(
        response.notification.includes("Connected: Awaiting Response")
      );
    });
  }, [providerId, colors]);

  const handleBadgeClick = () => {
    setInvisible(false);
  };

  async function handleLogin() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/");
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setProvider([]);
    setProviderId([]);
    // setEmail("");
    history.push("/serviceproviders");
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        {
          // size.width < 768
          size[0] < 768 ? (
            <div className={classes.appBarInteriorWrap}>
              {/* the above div needs a className, fiddle with flexbox FIRST then position then box */}
              <MenuIcon className={classes.menuIcon} onClick={showMenu} />
              <img className={classes.logo} src={Logo} alt="ResourceFull" />
              {/* <div onClick={handleLogout} button selected>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span className={classes.appBarText}>Sign Out</span>
            </div> */}
            </div>
          ) : // size.height < 600
          size[1] < 600 ? (
            <div className={classes.appBarInteriorWrap}>
              <MenuIcon className={classes.menuIcon} onClick={showMenu} />
              <img className={classes.logo} src={Logo} alt="ResourceFull" />
            </div>
          ) : (
            <img className={classes.logo} src={Logo} alt="ResourceFull" />
          )
        }
      </AppBar>

      {
        // size.width < 768
        size[0] < 768 &&
        drawer &&
        // size.height <= 600
        size[1] <= 600 &&
        drawer ? (
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={
              isAuthenticated
                ? {
                    paper: classes.drawerPaper,
                  }
                : // size.height <= 600
                size[1] <= 600
                ? {
                    paper: classes.drawerPaperToggle,
                  }
                : null
            }
          >
            <Toolbar />
            <div className="orgname-grand-wrapper-mobile">
              <div className="orgname-wrapper-mobile">
                {isAuthenticated ? (
                  <div className="orgname">
                    <p className="provider-name-drawer">{provider}</p>
                    <div
                      className="triangle-topleft"
                      style={{
                        borderTop: `solid 25px ${color}`,
                        borderLeft: `solid 25px ${color}`,
                      }}
                    ></div>
                    <div
                      className="triangle-bottomright"
                      style={{
                        borderRight: `solid 25px ${color2}`,
                        borderBottom: `solid 25px ${color2}`,
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>
              <div
                className="drawers2and3-wrapper-mobile-toggle"
                style={
                  // size.height <= 450
                  size[1] <= 450
                    ? {
                        // landscape settings
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "-1em",
                      }
                    : {
                        // portrait settings
                        display: "flex",
                        height: "90vh",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginTop: "-1em",
                        overflowY: "auto",
                      }
                }
              >
                <div className="drawer2-wrapper-mobile">
                  <div className={isAuthenticated ? "drawer2" : ""}>
                    <Divider
                      className={
                        isAuthenticated ? "mobile-divider-rule" : "display-none"
                      }
                    />

                    <List>
                      <ListItem
                        component={NavLink}
                        to={`/serviceproviders`}
                        button
                        selected
                        onClick={hideMenu}
                      >
                        <span className="icondrawer">
                          <FontAwesomeIcon icon={faHandsHelping} />
                        </span>
                        <ListItemText className={classes.text} color="primary">
                          Service Providers
                        </ListItemText>
                      </ListItem>
                      {isAuthenticated ? (
                        <ListItem
                          button
                          selected
                          component={NavLink}
                          to={`/people`}
                          onClick={() => {
                            handleBadgeClick();
                          }}
                        >
                          <span className="icondrawer">
                            <Badge
                              color="secondary"
                              variant="dot"
                              invisible={!invisible}
                            >
                              <PeopleIcon />
                            </Badge>
                          </span>
                          <ListItemText
                            className={classes.text}
                            color="primary"
                            onClick={hideMenu}
                          >
                            People
                          </ListItemText>
                        </ListItem>
                      ) : null}
                      {isAuthenticated ? (
                        <ListItem
                          component={NavLink}
                          to={`/ourprofile`}
                          button
                          selected
                          onClick={hideMenu}
                        >
                          <span className="icondrawer">
                            <SmartphoneIcon />
                          </span>
                          <ListItemText
                            className={classes.text}
                            color="primary"
                            onClick={hideMenu}
                          >
                            Our Profile
                          </ListItemText>
                        </ListItem>
                      ) : null}
                    </List>
                  </div>
                </div>
                <div
                  className={
                    providerId
                      ? "drawer3-wrapper-mobile-auth"
                      : "drawer3-wrapper-mobile"
                  }
                >
                  <div className="drawer3">
                    <Divider className="mobile-divider-rule mobile-divider-rule-drawer3-toggle" />
                    <List>
                      <ListItem
                        component={NavLink}
                        to="/Help"
                        button
                        selected
                        onClick={hideMenu}
                      >
                        <span className="icondrawer">
                          <HelpIcon />
                        </span>
                        <ListItemText className={classes.text} color="primary">
                          Help &amp; Tutorials
                        </ListItemText>
                      </ListItem>
                      <ListItem
                        component={NavLink}
                        to="/HelpContact"
                        button
                        selected
                        onClick={hideMenu}
                      >
                        <span className="icondrawer">
                          <MailOutlinedIcon />
                        </span>
                        <ListItemText className={classes.text} color="primary">
                          Contact Us
                        </ListItemText>
                      </ListItem>
                      {isAuthenticated ? (
                        <ListItem
                          component={NavLink}
                          to="/settings"
                          button
                          selected
                          onClick={() => {
                            handleBadgeClick();
                          }}
                        >
                          <span className="icondrawer">
                            <SettingsIcon />
                          </span>
                          <ListItemText
                            className={classes.text}
                            color="primary"
                            onClick={hideMenu}
                          >
                            Settings
                          </ListItemText>
                        </ListItem>
                      ) : null}
                      {(provider === process.env.REACT_APP_PROVIDER &&
                        role === "Admin" &&
                        isAuthenticated === true) ||
                      (provider === config.provider &&
                        role === "Admin" &&
                        isAuthenticated === true) ? (
                        <ListItem
                          component={NavLink}
                          to="/addprovider"
                          button
                          selected
                          onClick={() => {
                            handleBadgeClick();
                          }}
                        >
                          <span className="icondrawer">
                            <PersonAdd />
                          </span>
                          <ListItemText
                            className={classes.text}
                            color="primary"
                            onClick={hideMenu}
                          >
                            Add Provider
                          </ListItemText>
                        </ListItem>
                      ) : null}
                      {isAuthenticated ? (
                        <ListItem onClick={handleLogout} button selected>
                          <span className="icondrawer">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                          </span>
                          <ListItemText
                            className={classes.text}
                            color="primary"
                            onClick={hideMenu}
                          >
                            Sign Out
                          </ListItemText>
                        </ListItem>
                      ) : (
                        <ListItem onClick={handleLogin} button selected>
                          <span className="icondrawer">
                            <FontAwesomeIcon icon={faSignInAlt} />
                          </span>
                          <ListItemText
                            className={classes.text}
                            color="primary"
                            onClick={hideMenu}
                          >
                            Provider Sign In
                          </ListItemText>
                        </ListItem>
                      )}
                    </List>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </Drawer>
        ) : // size.width >= 768 && size.height >= 768
        size[0] >= 768 || size[1] >= 768 ? (
          <Drawer
            className={classes.drawerTablet}
            variant="permanent"
            classes={
              isAuthenticated
                ? {
                    paper: classes.drawerPaper,
                  }
                : null
            }
          >
            <Toolbar />
            {isAuthenticated ? (
              <div className="orgname">
                <p className="provider-name-drawer">{provider}</p>

                <div
                  className="triangle-topleft"
                  style={{
                    borderTop: `solid 25px ${color}`,
                    borderLeft: `solid 25px ${color}`,
                  }}
                ></div>
                <div
                  className="triangle-bottomright"
                  style={{
                    borderRight: `solid 25px ${color2}`,
                    borderBottom: `solid 25px ${color2}`,
                  }}
                ></div>
              </div>
            ) : null}
            <div
              className={
                isAuthenticated ? "drawer-desktop-auth" : "drawer-desktop"
              }
            >
              <div className={isAuthenticated ? "drawer2" : ""}>
                {isAuthenticated ? <Divider /> : null}

                <List>
                  <ListItem
                    component={NavLink}
                    to={`/serviceproviders`}
                    button
                    selected
                  >
                    <span className="icondrawer">
                      <FontAwesomeIcon icon={faHandsHelping} />
                    </span>
                    <ListItemText className={classes.text} color="primary">
                      Service Providers
                    </ListItemText>
                  </ListItem>
                  {isAuthenticated ? (
                    <ListItem
                      button
                      selected
                      component={NavLink}
                      to={`/people`}
                      onClick={() => {
                        handleBadgeClick();
                      }}
                    >
                      <span className="icondrawer">
                        <Badge
                          color="secondary"
                          variant="dot"
                          invisible={!invisible}
                        >
                          <PeopleIcon />
                        </Badge>
                      </span>
                      <ListItemText className={classes.text} color="primary">
                        People
                      </ListItemText>
                    </ListItem>
                  ) : null}
                  {isAuthenticated ? (
                    <ListItem
                      component={NavLink}
                      to={`/ourprofile`}
                      button
                      selected
                    >
                      <span className="icondrawer">
                        <SmartphoneIcon />
                      </span>
                      <ListItemText className={classes.text} color="primary">
                        Our Profile
                      </ListItemText>
                    </ListItem>
                  ) : null}
                </List>
              </div>

              <div className="drawer3">
                <Divider />
                <List>
                  <ListItem component={NavLink} to="/Help" button selected>
                    <span className="icondrawer">
                      <HelpIcon />
                    </span>
                    <ListItemText className={classes.text} color="primary">
                      Help &amp; Tutorials
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/HelpContact"
                    button
                    selected
                  >
                    <span className="icondrawer">
                      <MailOutlinedIcon />
                    </span>
                    <ListItemText className={classes.text} color="primary">
                      Contact Us
                    </ListItemText>
                  </ListItem>
                  {isAuthenticated ? (
                    <ListItem
                      component={NavLink}
                      to="/settings"
                      button
                      selected
                      onClick={() => {
                        handleBadgeClick();
                      }}
                    >
                      <span className="icondrawer">
                        <SettingsIcon />
                      </span>
                      <ListItemText className={classes.text} color="primary">
                        Settings
                      </ListItemText>
                    </ListItem>
                  ) : null}
                  {(provider === process.env.REACT_APP_PROVIDER &&
                    role === "Admin" &&
                    isAuthenticated === true) ||
                  (provider === config.provider &&
                    role === "Admin" &&
                    isAuthenticated === true) ? (
                    <ListItem
                      component={NavLink}
                      to="/addprovider"
                      button
                      selected
                      onClick={() => {
                        handleBadgeClick();
                      }}
                    >
                      <span className="icondrawer">
                        <PersonAdd />
                      </span>
                      <ListItemText className={classes.text} color="primary">
                        Add Provider
                      </ListItemText>
                    </ListItem>
                  ) : null}
                  {isAuthenticated ? (
                    <ListItem onClick={handleLogout} button selected>
                      <span className="icondrawer">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                      </span>
                      <ListItemText
                        className={classes.text}
                        color="primary"
                        onClick={hideMenu}
                      >
                        Sign Out
                      </ListItemText>
                    </ListItem>
                  ) : (
                    <ListItem onClick={handleLogin} button selected>
                      <span className="icondrawer">
                        <FontAwesomeIcon icon={faSignInAlt} />
                      </span>
                      <ListItemText
                        className={classes.text}
                        color="primary"
                        onClick={hideMenu}
                      >
                        Provider Sign In
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </div>
            </div>
          </Drawer>
        ) : null
      }
      <main className="width100Percent">
        <Route
          path="/serviceproviders"
          component={(props) => (
            <ServiceProviders
              {...props}
              user_id={user_id}
              provider={provider}
              provider_id={providerId}
              windowSize={size}
            />
          )}
        />
        <Route path="/people" component={() => <PeopleNavbar />} />
        <Route path="/ourprofile" component={() => <Ourprofilenav />} />
        <Route
          path="/settings"
          component={() => <Settings windowSize={size} />}
        />
        <Route
          path="/addprovider"
          component={() => <AdminAddProvider windowSize={size} />}
        />
        <Route path="/help" component={() => <Help />} />
        <Route
          path="/helpcontact"
          component={() => <HelpContact windowSize={size} />}
        />
      </main>
    </div>
  );
};

export default withRouter(ClippedDrawer);
