import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import mixpanel from "mixpanel-browser";
import { useAppContext } from "../../src/useContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  // Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { createFilterOptions } from "@mui/material/Autocomplete";
// import { InputAdornment, Autocomplete, Box, Link } from "@mui/material";
// import {
//   Search,
//   CheckBox,
//   CheckBoxOutlineBlank,
//   LocationOn,
//   ControlPoint,
// } from "@mui/icons-material";
import { AddressSearchBar } from "../Components/AddressSearchBar";

const ClearButton = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "40px",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "smaller",
    fontWeight: "bolder",
    letterSpacing: "normal",
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#1081C8",
    "&:hover": {
      backgroundColor: "#A10070",
    },
  },
}))(Button);

const AddProviderButton = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "40px",
    textAlign: "left",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "smaller",
    fontWeight: "bolder",
    letterSpacing: "normal",
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#A10070",
    "&:hover": {
      backgroundColor: "#A10070",
    },
    "&:disabled": {
      backgroundColor: "#A10070",
      opacity: 0.3,
      color: "white",
    },
  },
}))(Button);

//accordion styles
const useStyles = makeStyles((theme) => ({
  parentWrapper: {
    width: "100%",
    "&> :nth-child(even)": {
      "&> div:first-child": {
        // backgroundColor: "#66B2D1",
        color: "#1081C8",
      },
      "&> div.expandedStyle": {
        backgroundColor: "#1081C8",
        color: "white",
      },
    },
    "&> :nth-child(odd)": {
      "&> div:first-child": {
        backgroundColor: "#6DC1E4",
        color: "#1081C8",
      },
      "&> div.expandedStyle": {
        backgroundColor: "#1081C8",
        color: "white",
      },
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  ageslider: {
    width: "100vw",
    "@media (min-width:768px)": {
      width: "50vw",
    },
    "@media (min-width:1200px)": {
      width: "40vw",
    },
  },
}));

const AdminAddProvider = (props) => {
  const [dataProvider, setDataProvider] = useState([]);
  const classes = useStyles();
  const { providerId } = useAppContext();
  const size = props.windowSize;
  const [expanded, setExpanded] = useState(false);
  const [addProviderInfo, setAddProviderInfo] = useState({
    provider_name: "",
    email: "",
    role: "",
    activity_level: "",
    provider_start_hours: "",
    provider_close_hours: "",
    provider_phone: "",
    provider_phone_ext: "",
    provider_hotline: "",
    provider_hotline_ext: "",
    provider_website: "",
    provider_instagram: "",
    provider_facebook: "",
    org_for_who: "",
    org_our_services: "",
    org_we_are: "",
    required_variables_intake: [],
    required_variables_general: [
      "preferred_name",
      "first_name",
      "last_name",
      "phone",
      "email",
    ],
    provider_ages_served: [],
    provider_services_offered: [],
    provider_genders_served: [],
    provider_other_characteristics: [],
    // provider_counties_served: [],
    provider_address1: "",
    provider_addresses: [
      // {
      // // need to refactor to array of strings?
      // label: "47 W 13th St, New York, NY 10011, USA",
      // value: "47 W 13th St, New York, NY 10011, USA",
      // checked: true,
      // category: "Saved",
      // },
    ],
    provider_cities_served: [],
    is_confidential: null,
    // provider_address2: "",
    // provider_address3: "",
    // addressBoxes: [],
    is_national: null,
    email_help: "",
    email_intake_notify: "",
    avatar_colors: ["gray", "lightgray"],
  });

  // error handling for address input field
  // const [addressError, setAddressError] = useState({ bool: false, msg: "" });

  const [openForm, setOpenForm] = useState(false);
  const { reset } = useForm();

  // const [hasFocus, setFocus] = useState(false);

  // helper text for email, email_help, email_intake_notify, phone, hotline, hyperlinks (website, facebook, instagram)
  const [error, setError] = useState({
    email: "initial email input",
    email_help: "initial email input",
    email_intake_notify: "initial email input",
    provider_phone: "",
    provider_phone_ext: "",
    provider_hotline: "",
    provider_hotline_ext: "",
    provider_website: "",
    provider_facebook: "",
    provider_instagram: "",
  });

  const saveProviderAddresses = (addresses, index, stateName) => {
    console.log(index);
    let addressesCopy = [...addresses];
    let addressesFiltered = addressesCopy.filter(
      (item) => item.checked === true
    );
    if (typeof index === "number") {
      var newObject = [...dataProvider];
      newObject[index][stateName] =
        stateName === "provider_cities_served" ? addressesFiltered : addresses;
      setDataProvider(newObject);
    } else {
      setAddProviderInfo({
        ...addProviderInfo,
        [stateName]:
          stateName === "provider_cities_served"
            ? addressesFiltered
            : addresses,
      });
    }
  };

  const isEmailValid = async (stateName, value) => {
    const validRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value.match(validRegex)) {
      setError({ ...error, [stateName]: "invalid email" });
      // if (stateName === "email") {
      //   setError("invalid email");
      //   console.log(error);
      // } else if (stateName === "email_help") {
      //   setErrorHelp("invalid email");
      //   console.log(errorHelp);
      // } else if (stateName === "email_intake_notify") {
      //   setErrorIntakeNotify("invalid email");
      //   console.log(errorIntakeNotify);
      // }
      return false;
    } else {
      if (stateName === "email") {
        // console.log(value);
        doesUserExist(value.trim());
        // let userExists = await doesUserExist(value.trim());
        // console.log(userExists);
      } else {
        setError({ ...error, [stateName]: "" });
        // setErrorHelp("");
        // setErrorIntakeNotify("");
        console.log(error);
        return true;
      }
    }
  };

  const doesUserExist = async (username) => {
    // cannot use Auth.signIn because user is already signed in, so errors out.
    // must use lambda to check Cognito for existing users.
    API.post("referall-provider", "checkuserexists", {
      body: { email: username },
    }).then((response) => {
      // console.log(response);
      if (response.userExists) {
        setError({ ...error, email: "User already exists." });
      } else {
        setError({ ...error, email: "" });
      }
      return response;
    });
    // .catch((error) => {
    //   mixpanel.track("P ConsoleLog Error", {
    //     "Event Type": "API Call",
    //     "Error Message": error,
    //     "API Name": "checkuserexists",
    //     "File Name": "AdminAddProvider.js",
    //     "File Line": 221,
    //   });
    // });
  };

  const isPhoneValid = (stateName, value) => {
    // need const to store the regex for symbols user enters https links, provider_phone and provider_hotline fields
    const hasSyms = /^(?=.*?[() -.+]).{1,2}$/;
    const hasLetters = /^(?=.*?[a-zA-Z]).{1,20}$/;
    if (
      (stateName === "provider_phone" && value.match(hasSyms)) ||
      (stateName === "provider_phone" && value.match(hasLetters)) ||
      (stateName === "provider_hotline" && value.match(hasSyms)) ||
      (stateName === "provider_hotline" && value.match(hasLetters)) ||
      value.length !== 14
    ) {
      setError({ ...error, [stateName]: "Nonnumbers used" });
      return false;
      // console.log("has symbols");
    } else {
      setError({ ...error, [stateName]: "" });
      // console.log(error[stateName]);
      return true;
    }
  };

  const formatPhone = (value) => {
    const cleanInput = value.replace(/\D/g, "");
    let formattingNum = "";
    if (cleanInput.length >= 1) {
      formattingNum += "(" + cleanInput.substring(0, 3); //exclusive end, add 012 indicies
    }
    if (cleanInput.length >= 4) {
      formattingNum += ") " + cleanInput.substring(3, 6);
    }
    if (cleanInput.length >= 7) {
      formattingNum += "-" + cleanInput.substring(6, 10); //10 digits, 0-9 index
    }
    return formattingNum;
  };

  const isExtValid = (stateName, value) => {
    console.log(stateName, value);
    const numsOnly = /^[0-9].{1,10}$/;
    if (value.match(numsOnly)) {
      setError({ ...error, [stateName]: "" });
      return true;
    } else {
      setError({ ...error, [stateName]: "Nonnumbers used" });
      return false;
    }
  };

  const isUrlValid = (stateName, value) => {
    const validRegex =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/;
    if (
      (stateName === "provider_website" && !value.match(validRegex)) ||
      // value.substring(0, 8) !== "https://") ||
      (stateName === "provider_instagram" && !value.match(validRegex)) ||
      // value.substring(0, 8) !== "https://") ||
      (stateName === "provider_facebook" && !value.match(validRegex))
      // value.substring(0, 8) !== "https://")
    ) {
      setError({ ...error, [stateName]: "Https needed" });
      // console.log(error);
      return false;
    } else {
      setError({ ...error, [stateName]: "" });
      // console.log(error);
      return true;
    }
  };
  // Age Slider
  // const [value, setValue] = useState([16, 65]);
  // const [editIndex, setEditIndex] = useState(null);

  // const handleAgeInput = (event, newValue) => {
  //   setValue(newValue);
  //   setAddProviderInfo({
  //     ...addProviderInfo,
  //     provider_ages_served: newValue,
  //   });
  //   // }
  // };

  // const handleEditAge = (event, newValue) => {
  //   // console.log(editIndex, newValue);
  //   const newObject = [...dataProvider];
  //   newObject[editIndex].provider_ages_served = newValue;
  //   console.log(newObject);
  //   setDataProvider(newObject);
  // };

  // const handleEditAgeIndex = (event, index) => {
  //   setEditIndex(index);
  // };

  // useEffect(() => {
  //   API.get("referall-provider", "getallproviders")
  //     .then((response) => {
  //       //console.log('Daily messages & statuses: ', response);
  //       let alphabeticalOrder = response.services.sort(function (a, b) {
  //         let textA = a.provider_name.toUpperCase();
  //         let textB = b.provider_name.toUpperCase();
  //         return textA < textB ? -1 : textA > textB ? 1 : 0;
  //       });
  //       setAllProviders(alphabeticalOrder);
  //     })
  //     .catch((error) => {
  //       alert(error.message);
  //     });
  // }, []);

  useEffect(() => {
    // console.log(`the info changed!${addProviderInfo}`);
    Auth.currentAuthenticatedUser().then(async (response) => {
      // console.log(response);
      await API.post("referall-provider", "getproviderorg", {
        body: { user_id: response.username, provider_id: providerId },
      })
        .then((data) => {
          data.sort((a, b) =>
            a.provider_name > b.provider_name
              ? 1
              : b.provider_name > a.provider_name
              ? -1
              : 0
          );
          console.log(data);
          setDataProvider(data);
        })
        .catch((error) => {
          console.log(error);
          mixpanel.track("P ConsoleLog Error", {
            "Event Type": "API Call",
            "Error Message": error,
            "API Name": "getproviderorg",
            "File Name": "AdminAddProvider.js",
            "File Line": 373,
          });
        });
    }); // eslint-disable-next-line
  }, [providerId]);

  const showForm = () => {
    setOpenForm(!openForm);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    // console.log(panel, isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  function handleChange(e, index) {
    // console.log(e.target.name.endsWith("hours"), e.target.value);
    const value = e.target.value;
    console.log("check value", e.target.name);
    // // need const to store the regex for symbols user enters https links, provider_phone and provider_hotline fields
    // const hasSyms = /^(?=.*?[() -.+]).{1,2}$/;
    // const hasLetters = /^(?=.*?[a-zA-Z]).{1,20}$/;

    if (typeof index === "number") {
      var newObject = [...dataProvider];
      // if (e.target.name.endsWith("hours") === true) {
      //   let time = e.target.value;
      //   let amPm = time >= 12 ? "PM" : "AM";
      //   let timeArray = time.split(":");
      //   timeArray[0] = timeArray[0] % 12 || 12;
      //   let newTime = timeArray.join(":") + ` ${amPm}`;
      //   // console.log(newTime);
      //   newObject[index][e.target.name] = newTime;
      // } else {
      if (
        e.target.name === "email" ||
        e.target.name === "email_help" ||
        e.target.name === "email_intake_notify"
      ) {
        // console.log(e.target.name, e.target.value);
        isEmailValid(e.target.name, e.target.value);
      } else if (
        e.target.name === "provider_website" ||
        e.target.name === "provider_instagram" ||
        e.target.name === "provider_facebook"
      ) {
        isUrlValid(e.target.name, e.target.value);
      }
      if (
        e.target.name === "provider_phone" ||
        e.target.name === "provider_hotline"
      ) {
        isPhoneValid(e.target.name, value);
        const formattedPhone = formatPhone(value);
        newObject[index][e.target.name] = formattedPhone;
      } else if (
        (e.target.name === "provider_phone_ext" && value) ||
        (e.target.name === "provider_hotline_ext" && value)
      ) {
        isExtValid(e.target.name, value);
        newObject[index][e.target.name] = value ? value : "";
      } else {
        newObject[index][e.target.name] = value;
      }
      // }
      console.log(error);
      console.log(newObject);
      setDataProvider(newObject);
    } else {
      console.log(e.target.value, index); //index undefined
      if (
        index === "email" ||
        index === "email_help" ||
        index === "email_intake_notify"
      ) {
        isEmailValid(index, e.target.value);
        // let emailResult = isEmailValid(index, e.target.value);
        // console.log("isEmailValid", emailResult);
        setAddProviderInfo({
          ...addProviderInfo,
          [e.target.name]: value,
          email_intake_notify: value,
        });
      } else if (
        index === "provider_website" ||
        index === "provider_instagram" ||
        index === "provider_facebook"
      ) {
        isUrlValid(index, value);
        setAddProviderInfo({ ...addProviderInfo, [e.target.name]: value });
      } else if (index === "provider_phone" || index === "provider_hotline") {
        isPhoneValid(index, value);
        const formattedPhone = formatPhone(value);
        setAddProviderInfo({
          ...addProviderInfo,
          [e.target.name]: formattedPhone,
        });
      } else if (
        index === "provider_phone_ext" ||
        index === "provider_hotline_ext"
      ) {
        isExtValid(e.target.name, value);
        setAddProviderInfo({
          ...addProviderInfo,
          [e.target.name]: value ? value : "",
        });
      } else {
        setAddProviderInfo({ ...addProviderInfo, [e.target.name]: value });
      }
    }
  }

  var handleChangeRole = (e, index) => {
    // console.log(index);
    if (typeof index === "number") {
      var newObject = [...dataProvider];
      newObject[index].role = e.target.value;
      setDataProvider(newObject);
      // console.log(dataProvider);
    } else {
      var newData = { ...addProviderInfo };
      newData.role = e.target.value;
      setAddProviderInfo(newData);
    }
  };

  var handleCheckboxActLevel = (e, index) => {
    // console.log(index, e.target.value);
    if (typeof index === "number") {
      var newObject = [...dataProvider];
      newObject[index].activity_level = e.target.value;
      setDataProvider(newObject);
    } else {
      var newData = { ...addProviderInfo };
      newData.activity_level = e.target.value;
      setAddProviderInfo(newData);
    }
  };

  var handleCheckboxChange = (e, index) => {
    if (typeof index === "number") {
      var newObject = [...dataProvider];
      if (e.target.checked) {
        newObject[index][e.target.name].push(e.target.value);
      } else {
        newObject[index][e.target.name].splice(
          newObject[index][e.target.name].indexOf(e.target.value),
          1
        );
      }
      setDataProvider(newObject);
    } else {
      var newData = { ...addProviderInfo };
      if (e.target.checked) {
        newData[e.target.name].push(e.target.value);
      } else {
        newData[e.target.name].splice(
          newData[e.target.name].indexOf(e.target.value),
          1
        );
      }
      setAddProviderInfo(newData);
    }
  };

  const handleSingleCheckbox = (e, index) => {
    // console.log("newValue ", e.target.checked);
    if (typeof index === "number") {
      var newObject = [...dataProvider];
      newObject[index][e.target.name] = e.target.checked;
      setDataProvider(newObject);
    } else {
      setAddProviderInfo({
        ...addProviderInfo,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleAgeInput = (e, index) => {
    if (typeof index === "number") {
      var newObject = [...dataProvider];
      // if (e.target.checked) {
      //   newObject[index][e.target.name].push(e.target.value);
      // } else {
      //   newObject[index][e.target.name].splice(
      //     newObject[index][e.target.name].indexOf(e.target.value),
      //     1
      //   );
      // }
      if (e.target.value) {
        if (e.target.name === "AgeRangeMin") {
          newObject[index].provider_ages_served[0] = +e.target.value;
        }
        if (e.target.name === "AgeRangeMax") {
          newObject[index].provider_ages_served[1] = +e.target.value;
        }
      }
      // console.log(newObject);
      setDataProvider(newObject);
    } else {
      var newData = { ...addProviderInfo };
      // if (e.target.checked) {
      //   newData[e.target.name].push(e.target.value);
      // } else {
      //   newData[e.target.name].splice(
      //     newData[e.target.name].indexOf(e.target.value),
      //     1
      //   );
      // }
      if (e.target.name === "AgeRangeMin") {
        newData.provider_ages_served[0] = +e.target.value;
      }
      if (e.target.name === "AgeRangeMax") {
        newData.provider_ages_served[1] = +e.target.value;
      }
      setAddProviderInfo(newData);
    }
  };

  const handleUpdate = (e, index) => {
    e.preventDefault();
    Auth.currentAuthenticatedUser().then((response) => {
      // console.log(response); //provider is a string
      API.post("referall-provider", "addproviderorg", {
        body: {
          user_id: response.username,
          data: dataProvider[index],
          provider_id: providerId,
        },
      })
        .then((data) => {
          // console.log(data);
          data.sort((a, b) =>
            a.provider_name > b.provider_name
              ? 1
              : b.provider_name > a.provider_name
              ? -1
              : 0
          );
          setDataProvider(data);
          setExpanded(false);
        })
        .catch((error) => {
          console.log(error);
          mixpanel.track("P ConsoleLog Error", {
            "Event Type": "API Call",
            "Error Message": error,
            "API Name": "addproviderorg",
            "File Name": "AdminAddProvider.js",
            "File Line": 621,
          });
        });
    }); // eslint-disable-next-line
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let providerData = { ...addProviderInfo };
    providerData.provider_addresses = providerData.provider_addresses.filter(
      (addy) => addy.checked
    );
    setAddProviderInfo(providerData);
    console.log("providerData: ", providerData);

    Auth.currentAuthenticatedUser().then(async (response) => {
      // console.log(response);
      await API.post("referall-provider", "addproviderorg", {
        body: { data: providerData, user_id: response.username },
      })
        .then(async (data) => {
          await API.post("referall-provider", "getproviderorg", {
            body: { user_id: response.username, provider_id: providerId },
          })
            .then((data) => {
              // console.log(data);
              data.sort((a, b) =>
                a.provider_name > b.provider_name
                  ? 1
                  : b.provider_name > a.provider_name
                  ? -1
                  : 0
              );
              setDataProvider(data);
              setOpenForm(false);
              mixpanel.track("P AddProv Submit", {
                "Event Type": "User Action",
              });
            })
            .catch((error) => {
              console.log(error);
              mixpanel.track("P ConsoleLog Error", {
                "Event Type": "API Call",
                "Error Message": error,
                "API Name": "getproviderorg",
                "File Name": "AdminAddProvider.js",
                "File Line": 671,
              });
            });
        })
        .catch((error) => {
          console.log(error);
          mixpanel.track("P ConsoleLog Error", {
            "Event Type": "API Call",
            "Error Message": error,
            "API Name": "addproviderorg",
            "File Name": "AdminAddProvider.js",
            "File Line": 682,
          });
        });
    }); // eslint-disable-next-line

    setAddProviderInfo({
      // to clear after saving
      provider_name: "",
      email: "",
      role: "",
      activity_level: "",
      provider_start_hours: "",
      provider_close_hours: "",
      provider_phone: "",
      provider_phone_ext: "",
      provider_hotline: "",
      provider_hotline_ext: "",
      provider_website: "",
      provider_instagram: "",
      provider_facebook: "",
      org_for_who: "",
      org_our_services: "",
      org_we_are: "",
      required_variables_intake: [],
      required_variables_general: [
        "preferred_name",
        "first_name",
        "last_name",
        "phone",
        "email",
      ],
      provider_ages_served: [],
      provider_services_offered: [],
      provider_genders_served: [],
      provider_other_characteristics: [],
      provider_counties_served: [],
      provider_address1: "",
      provider_addresses: [],
      is_confidential: null,
      // provider_address2: "",
      // provider_address3: "",
      provider_cities_served: [],
      is_national: null,
      email_help: "",
      email_intake_notify: "",
      avatar_colors: ["gray", "lightgray"],
    });
    setError({
      ...error,
      email: "initial email input",
      email_help: "initial email input",
    });
    // setErrorHelp("initial email input");
  };

  //phone validation
  // function phonenumber(inputtxt) {
  //   var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   if (inputtxt.value.match(phoneno)) {
  //     return true;
  //   } else {
  //     alert("message");
  //     return false;
  //   }
  // }

  return (
    <div className="addProviderForm">
      <div>
        <h1 className="add-provider-header paddingBottom60">Add a Provider</h1>
        <p className="paddingBottom20">
          *List of Providers Added in the Database
        </p>
      </div>
      <div className="maxWidth95Percent paddingBottom60">
        <div className={classes.parentWrapper}>
          <>
            {dataProvider.map((data, index) => {
              return (
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleChangeAccordion(`panel${index}`)}
                  key={index}
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{ expanded: "expandedStyle" }}
                  >
                    <Typography
                    // key={index}
                    >
                      {data.provider_name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div>
                        <form
                          className="marginBottom40, marginTop25"
                          //onSubmit={handleSubmit}
                        >
                          <label>Provider Name:</label>
                          <input
                            type="text"
                            name="provider_name"
                            value={data.provider_name}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15 width300"
                            disabled={true}
                          />
                          {/* <label>
                              Email (This will add a new user to the platform):
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={data.email}
                              onChange={(e) => handleChange(e, index)}
                              className="marginBottom15"
                              placeholder="name@email.com"
                              disabled={true}
                            />

                            <label>Role:</label>
                            <FormGroup>
                              <RadioGroup
                                row
                                className="marginBottom20"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  checked={
                                    data.activity_level === "Admin"
                                      ? true
                                      : false
                                    // data.role
                                    //   ? data.role.includes("Admin")
                                    //   : false
                                  }
                                  value="Admin"
                                  control={<Radio />}
                                  label="Admin"
                                  onChange={(e) => handleChangeRole(e, index)}
                                />
                                <FormControlLabel
                                  checked={
                                    data.activity_level === "Standard"
                                      ? true
                                      : false
                                    // data.role
                                    //   ? data.role.includes("Standard")
                                    //   : false
                                  }
                                  value="Standard"
                                  control={<Radio />}
                                  label="Standard"
                                  onChange={(e) => handleChangeRole(e, index)}
                                />
                              </RadioGroup>
                            </FormGroup> */}
                          <label>Activity Level:</label>
                          <FormGroup>
                            <RadioGroup
                              row
                              className="marginBottom20"
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                checked={
                                  data.activity_level === "Intake"
                                    ? true
                                    : false
                                }
                                value="Intake"
                                control={<Radio />}
                                label="Intake"
                                onChange={(e) =>
                                  handleCheckboxActLevel(e, index)
                                }
                              />
                              <FormControlLabel
                                checked={
                                  data.activity_level === "No Intake"
                                    ? true
                                    : false
                                }
                                value="No Intake"
                                control={<Radio />}
                                label="No Intake"
                                onChange={(e) =>
                                  handleCheckboxActLevel(e, index)
                                }
                              />
                            </RadioGroup>
                          </FormGroup>
                          <label>Start Hours:</label>
                          <input
                            type="time"
                            name="provider_start_hours"
                            value={data.provider_start_hours}
                            onChange={(e) => handleChange(e, index)}
                            className="hours marginBottom15"
                          />
                          <label>Close Hours:</label>
                          <input
                            type="time"
                            name="provider_close_hours"
                            value={data.provider_close_hours}
                            onChange={(e) => handleChange(e, index)}
                            className="hours marginBottom15"
                          />
                          <label>Phone Number:</label>
                          <div className="flexRow">
                            <input
                              // type={phonenumber}
                              type="tel"
                              // maxLength="10"
                              // data={phonenumber}
                              name="provider_phone"
                              value={data.provider_phone}
                              placeholder="(XXX) XXX-XXXX"
                              onChange={(e) => handleChange(e, index)}
                              className="marginBottom15"
                            />
                            <span className="paddingTop10 paddingLeft8">
                              Ext.
                            </span>
                            <input
                              type="tel"
                              name="provider_phone_ext"
                              value={data.provider_phone_ext}
                              placeholder="XXXXX (if applicable)"
                              onChange={(e) => handleChange(e, index)}
                              className="marginBottom15"
                            />
                          </div>
                          {error.provider_phone === "Nonnumbers used" ? (
                            <p className="colorRed fontSize14 marginTop0">
                              Please enter a valid phone number (numbers only):{" "}
                              <span className="bold">9876543210</span>
                            </p>
                          ) : error.provider_phone_ext === "Nonnumbers used" ? (
                            <p className="colorRed fontSize14 marginTop0">
                              Please enter a valid extension (numbers only):{" "}
                              <span className="bold">321</span>
                            </p>
                          ) : null}
                          <label>Hotline:</label>
                          <div className="flexRow">
                            <input
                              type="tel"
                              // maxLength="14"
                              name="provider_hotline"
                              value={data.provider_hotline}
                              placeholder="(XXX) XXX-XXXX"
                              onChange={(e) => handleChange(e, index)}
                              className="marginBottom15"
                            />
                            <span className="paddingTop10 paddingLeft8">
                              Ext.
                            </span>
                            <input
                              type="tel"
                              name="provider_hotline_ext"
                              value={data.provider_hotline_ext}
                              placeholder="XXXXX (if applicable)"
                              onChange={(e) => handleChange(e, index)}
                              className="marginBottom15"
                            />
                          </div>
                          {error.provider_hotline === "Nonnumbers used" ? (
                            <p className="colorRed fontSize14 marginTop0">
                              Please enter a valid phone number (numbers only):{" "}
                              <span className="bold">9876543210</span>
                            </p>
                          ) : error.provider_hotline_ext ===
                            "Nonnumbers used" ? (
                            <p className="colorRed fontSize14 marginTop0">
                              Please enter a valid extension (numbers only):{" "}
                              <span className="bold">321</span>
                            </p>
                          ) : null}
                          <label>Website:</label>
                          <input
                            type="url"
                            name="provider_website"
                            value={data.provider_website}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15 width300"
                            placeholder="https://www.websiteName.com"
                          />
                          {error.provider_website === "Https needed" ? (
                            <p className="colorRed fontSize14">
                              Please enter a valid url:{" "}
                              <span className="bold">
                                https://www.websiteName.com
                              </span>
                            </p>
                          ) : null}
                          <label>Instagram:</label>
                          <input
                            type="text"
                            name="provider_instagram"
                            value={data.provider_instagram}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15 width300"
                            placeholder="https://www.instagramName.com"
                          />
                          {error.provider_instagram === "Https needed" ? (
                            <p className="colorRed fontSize14">
                              Please enter a valid url:{" "}
                              <span className="bold">
                                https://www.instagram.com/instagramName
                              </span>
                            </p>
                          ) : null}
                          <label>Facebook:</label>
                          <input
                            type="text"
                            name="provider_facebook"
                            value={data.provider_facebook}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15 width300"
                            placeholder="https://www.facebookName.com"
                          />
                          {error.provider_facebook === "Https needed" ? (
                            <p className="colorRed fontSize14">
                              Please enter a valid url:{" "}
                              <span className="bold">
                                https://www.facebook.com/facebookName
                              </span>
                            </p>
                          ) : null}
                          <label>Clients:</label>
                          <input
                            type="text"
                            name="org_for_who"
                            value={data.org_for_who}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15"
                          />
                          <label>Services:</label>
                          <input
                            type="text"
                            name="org_our_services"
                            value={data.org_our_services}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15"
                          />
                          <label>Who We Are:</label>
                          <input
                            type="text"
                            name="org_we_are"
                            value={data.org_we_are}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15"
                          />
                          <label>Variables Intake:</label>
                          <FormGroup className="marginBottom20">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "address_line1"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="address_line1"
                                  color="primary"
                                />
                              }
                              label="city, state, county"
                            />
                            {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "address_line2"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="address_line2"
                                  color="primary"
                                />
                              }
                              label="address_line2"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "address_city"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="address_city"
                                  color="primary"
                                />
                              }
                              label="address_city"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "address_state"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="address_state"
                                  color="primary"
                                />
                              }
                              label="address_state"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "address_zipcode"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="address_zipcode"
                                  color="primary"
                                />
                              }
                              label="address_zipcode"
                            /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "date_of_birth"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="date_of_birth"
                                  color="primary"
                                />
                              }
                              label="date_of_birth"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "gender"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="gender"
                                  color="primary"
                                />
                              }
                              label="gender"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "sexual_orientation"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="sexual_orientation"
                                  color="primary"
                                />
                              }
                              label="sexual_orientation"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "ethnicity"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="ethnicity"
                                  color="primary"
                                />
                              }
                              label="ethnicity"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "preferred_language"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="preferred_language"
                                  color="primary"
                                />
                              }
                              label="preferred_language"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "survivorship"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="survivorship"
                                  color="primary"
                                />
                              }
                              label="survivorship"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "foster_care"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="foster_care"
                                  color="primary"
                                />
                              }
                              label="foster_care"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "incarceration"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="incarceration"
                                  color="primary"
                                />
                              }
                              label="incarceration"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "immigration"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="immigration"
                                  color="primary"
                                />
                              }
                              label="immigration"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "children"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="children"
                                  color="primary"
                                />
                              }
                              label="children"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "num_of_children"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="num_of_children"
                                  color="primary"
                                />
                              }
                              label="num_of_children"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_intake.includes(
                                          "accommodations"
                                        )
                                      : false
                                  }
                                  name="required_variables_intake"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="accommodations"
                                  color="primary"
                                />
                              }
                              label="accommodations"
                            />
                          </FormGroup>
                          {/* <label>Variables General:</label>
                          <FormGroup className="marginBottom20">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "preferred_name"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="preferred_name"
                                  color="primary"
                                />
                              }
                              label="preferred_name"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "first_name"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="first_name"
                                  color="primary"
                                />
                              }
                              label="first_name"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "last_name"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="last_name"
                                  color="primary"
                                />
                              }
                              label="last_name"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "phone"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="phone"
                                  color="primary"
                                />
                              }
                              label="phone"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "email"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="email"
                                  color="primary"
                                />
                              }
                              label="email"
                            />
                           <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "county_location"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="county_location"
                                  color="primary"
                                />
                              }
                              label="county_location"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.required_variables_intake
                                      ? data.required_variables_general.includes(
                                          "county_services"
                                        )
                                      : false
                                  }
                                  name="required_variables_general"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="county_services"
                                  color="primary"
                                />
                              }
                              label="county_services"
                            /> 
                          </FormGroup> */}

                          {/* Age Range Input Edit State */}
                          <label className="bold marginBottom10">
                            Ages Served
                          </label>
                          <p className="marginBottom0 marginTop0">
                            Set Exact Range:
                          </p>
                          <div className="flexRow ageRangeWrapper marginBottom15">
                            <TextField
                              name="AgeRangeMin"
                              className="ageTextField"
                              type="number"
                              style={{
                                width: "45%",
                                height: "10%",
                                borderRadius: "5px",
                                // more styles in App.css
                              }}
                              // variant="outlined"
                              defaultValue={
                                data.provider_ages_served.length > 0 &&
                                data.provider_ages_served[0] === 0
                                  ? 0
                                  : data.provider_ages_served[0]
                                  ? data.provider_ages_served[0]
                                  : null
                              }
                              InputProps={{
                                inputProps: { min: "0", max: "119" },
                              }}
                              onChange={(e) => handleAgeInput(e, index)}
                              error={
                                data.provider_ages_served[0] >=
                                  data.provider_ages_served[1] ||
                                data.provider_ages_served[0] < 0 ||
                                data.provider_ages_served[0] > 119
                              }
                              helperText={
                                data.provider_ages_served[0] > 119
                                  ? "Must be 119 or less"
                                  : data.provider_ages_served[0] >
                                      data.provider_ages_served[1] &&
                                    data.provider_ages_served[0] !== 0
                                  ? "Enter a lower number"
                                  : data.provider_ages_served[0] ===
                                    data.provider_ages_served[1]
                                  ? "Ages cannot be equal"
                                  : data.provider_ages_served[0] < 0
                                  ? "Age must be 0+"
                                  : null
                              }
                            />
                            <p className="marginTop5">to</p>
                            <TextField
                              name="AgeRangeMax"
                              className="ageTextField"
                              type="number"
                              style={{
                                width: "45%",
                                height: "10%",
                                borderRadius: "5px",
                                // more styles in App.css
                              }}
                              // variant="outlined"
                              defaultValue={
                                data.provider_ages_served.length > 0 &&
                                data.provider_ages_served[1] === 0
                                  ? 0
                                  : data.provider_ages_served[1]
                                  ? data.provider_ages_served[1]
                                  : null
                              }
                              InputProps={{
                                inputProps: { min: "1", max: "120" },
                              }}
                              onChange={(e) => handleAgeInput(e, index)}
                              error={
                                data.provider_ages_served[1] <=
                                  data.provider_ages_served[0] ||
                                data.provider_ages_served[1] < 1 ||
                                data.provider_ages_served[1] > 120
                              }
                              helperText={
                                data.provider_ages_served[1] <=
                                data.provider_ages_served[0]
                                  ? "Enter a higher number"
                                  : data.provider_ages_served[1] > 120
                                  ? "Must be 120 or less"
                                  : data.provider_ages_served[1] < 1
                                  ? "Age must be 1+"
                                  : null
                              }
                            />
                          </div>
                          {/* Age Slider Edit State */}
                          {/* <div
                              className={classes.ageslider}
                              style={{ overflow: "hidden" }}
                            >
                              <div className="column left">
                                <Typography
                                  className="slider-value"
                                  id={`range-slider${index}`}
                                  gutterBottom
                                >
                                  {data.provider_ages_served.length > 0 &&
                                  typeof +data.provider_ages_served[0] ===
                                    "number"
                                    ? data.provider_ages_served[0]
                                    : value[0]}{" "}
                                </Typography>
                              </div>
                              <div className="column middle">
                                <Slider
                                  name={`provider_ages_served${index}`}
                                  defaultValue={
                                    data.provider_ages_served.length > 0 &&
                                    typeof +data.provider_ages_served[0] ===
                                      "number"
                                      ? data.provider_ages_served
                                      : value
                                  }
                                  onChangeCommitted={handleEditAge}
                                  onChange={(e) => handleEditAgeIndex(e, index)}
                                  aria-labelledby="range-slider"
                                  max={120}
                                  style={{ marginLeft: "15px" }}
                                />
                              </div>
                              <div className="column right">
                                <Typography
                                  id="range-slider"
                                  gutterBottom
                                  className="slider-value"
                                  style={{ marginLeft: "30px" }}
                                >
                                  {data.provider_ages_served.length > 0 &&
                                  typeof +data.provider_ages_served[1] ===
                                    "number"
                                    ? data.provider_ages_served[1]
                                    : value[1]}{" "}
                                </Typography>
                              </div>
                            </div>{" "}
                            */}
                          {/* Age Checkbox Edit State */}
                          {/* <label>Ages Served:</label>
                            <FormGroup className="marginBottom20">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      data.provider_ages_served
                                        ? data.provider_ages_served.includes(
                                            "Under 18 years old"
                                          )
                                        : false
                                    }
                                    name="provider_ages_served"
                                    onChange={(e) =>
                                      handleCheckboxChange(e, index)
                                    }
                                    value="Under 18 years old"
                                    color="primary"
                                  />
                                }
                                label="Under 18 years old"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      data.provider_ages_served
                                        ? data.provider_ages_served.includes(
                                            "16 - 24 years old"
                                          )
                                        : false
                                    }
                                    name="provider_ages_served"
                                    onChange={(e) =>
                                      handleCheckboxChange(e, index)
                                    }
                                    value="16 - 24 years old"
                                    color="primary"
                                  />
                                }
                                label="16 - 24 years old"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      data.provider_ages_served
                                        ? data.provider_ages_served.includes(
                                            "18 years old and older"
                                          )
                                        : false
                                    }
                                    name="provider_ages_served"
                                    onChange={(e) =>
                                      handleCheckboxChange(e, index)
                                    }
                                    value="18 years old and older"
                                    color="primary"
                                  />
                                }
                                label="18 years old and older"
                              />
                            </FormGroup> */}
                          <label>Services Offered</label>
                          <FormGroup className="marginBottom20">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Housing and Shelter"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Housing and Shelter"
                                  color="primary"
                                />
                              }
                              label="Housing and Shelter"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Workforce Development"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Workforce Development"
                                  color="primary"
                                />
                              }
                              label="Workforce Development"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Legal Aid"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Legal Aid"
                                  color="primary"
                                />
                              }
                              label="Legal Aid"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Financial Assistance"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Financial Assistance"
                                  color="primary"
                                />
                              }
                              label="Financial Assistance"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Mental Health"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Mental Health"
                                  color="primary"
                                />
                              }
                              label="Mental Health"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Case Management"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Case Management"
                                  color="primary"
                                />
                              }
                              label="Case Management"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Healthcare"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Healthcare"
                                  color="primary"
                                />
                              }
                              label="Healthcare"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "24-Hour Hotlines"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="24-Hour Hotlines"
                                  color="primary"
                                />
                              }
                              label="24-Hour Hotlines"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Drop-In Center"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Drop-In Center"
                                  color="primary"
                                />
                              }
                              label="Drop-In Center"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Support Group Services"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Support Group Services"
                                  color="primary"
                                />
                              }
                              label="Support Group Services"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Education"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Education"
                                  color="primary"
                                />
                              }
                              label="Education"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Healing Arts"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Healing Arts"
                                  color="primary"
                                />
                              }
                              label="Healing Arts"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Youth Development Services"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Youth Development Services"
                                  color="primary"
                                />
                              }
                              label="Youth Development Services"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Substance Overuse Services"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Substance Overuse Services"
                                  color="primary"
                                />
                              }
                              label="Substance Overuse Services"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Public Benefits"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Public Benefits"
                                  color="primary"
                                />
                              }
                              label="Public Benefits"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Peer Support"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Peer Support"
                                  color="primary"
                                />
                              }
                              label="Peer Support"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Basic Needs Resource"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Basic Needs Resource"
                                  color="primary"
                                />
                              }
                              label="Basic Needs Resource"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Crisis Intervention"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Crisis Intervention"
                                  color="primary"
                                />
                              }
                              label="Crisis Intervention"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Entrepreneurship"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Entrepreneurship"
                                  color="primary"
                                />
                              }
                              label="Entrepreneurship"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Leadership Development"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Leadership Development"
                                  color="primary"
                                />
                              }
                              label="Leadership Development"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Food Access"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Food Access"
                                  color="primary"
                                />
                              }
                              label="Food Access"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Child Welfare and Child Protection"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Child Welfare and Child Protection"
                                  color="primary"
                                />
                              }
                              label="Child Welfare and Child Protection"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_services_offered
                                      ? data.provider_services_offered.includes(
                                          "Telephone Hotline"
                                        )
                                      : false
                                  }
                                  name="provider_services_offered"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Telephone Hotline"
                                  color="primary"
                                />
                              }
                              label="Telephone Hotline"
                            />
                          </FormGroup>
                          <label>Genders Served:</label>
                          <FormGroup className="marginBottom20">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Woman"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Woman"
                                  color="primary"
                                />
                              }
                              label="Woman"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Trans Woman"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Trans Woman"
                                  color="primary"
                                />
                              }
                              label="Trans Woman"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Man"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Man"
                                  color="primary"
                                />
                              }
                              label="Man"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Trans Man"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Trans Man"
                                  color="primary"
                                />
                              }
                              label="Trans Man"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Gender Queer"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Gender Queer"
                                  color="primary"
                                />
                              }
                              label="Gender Queer"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Gender Non-Conforming"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Gender Non-Conforming"
                                  color="primary"
                                />
                              }
                              label="Gender Non-Conforming"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Gender Non-Binary"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Gender Non-Binary"
                                  color="primary"
                                />
                              }
                              label="Gender Non-Binary"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "Agender"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Agender"
                                  color="primary"
                                />
                              }
                              label="Agender"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_genders_served
                                      ? data.provider_genders_served.includes(
                                          "None of these"
                                        )
                                      : false
                                  }
                                  name="provider_genders_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="None of these"
                                  color="primary"
                                />
                              }
                              label="None of these"
                            />
                          </FormGroup>
                          <label>Other Characteristics:</label>
                          <FormGroup className="marginBottom20">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_other_characteristics
                                      ? data.provider_other_characteristics.includes(
                                          "Drop-In Available"
                                        )
                                      : false
                                  }
                                  name="provider_other_characteristics"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Drop-In Available"
                                  color="primary"
                                />
                              }
                              label="Drop-in Available"
                            />
                            {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      data.provider_other_characteristics
                                        ? data.provider_other_characteristics.includes(
                                            "Open Now"
                                          )
                                        : false
                                    }
                                    name="provider_other_characteristics"
                                    onChange={(e) =>
                                      handleCheckboxChange(e, index)
                                    }
                                    value="Open Now"
                                    color="primary"
                                  />
                                }
                                label="Open Now"
                              /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_other_characteristics
                                      ? data.provider_other_characteristics.includes(
                                          "Virtual Services"
                                        )
                                      : false
                                  }
                                  name="provider_other_characteristics"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Virtual Services"
                                  color="primary"
                                />
                              }
                              label="Virtual Services"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_other_characteristics
                                      ? data.provider_other_characteristics.includes(
                                          "Faith-Based"
                                        )
                                      : false
                                  }
                                  name="provider_other_characteristics"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Faith-Based"
                                  color="primary"
                                />
                              }
                              label="Faith-based"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_other_characteristics
                                      ? data.provider_other_characteristics.includes(
                                          "Children Allowed"
                                        )
                                      : false
                                  }
                                  name="provider_other_characteristics"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Children Allowed"
                                  color="primary"
                                />
                              }
                              label="Children Allowed"
                            />
                          </FormGroup>
                          {/* <label>County:</label>
                          <FormGroup className="marginBottom20">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Alameda County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Alameda County"
                                  color="primary"
                                />
                              }
                              label="Alameda County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Contra Costa County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Contra Costa County"
                                  color="primary"
                                />
                              }
                              label="Contra Costa County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Marin County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Marin County"
                                  color="primary"
                                />
                              }
                              label="Marin County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Monterey County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Monterey County"
                                  color="primary"
                                />
                              }
                              label="Monterey County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "San Diego County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="San Diego County"
                                  color="primary"
                                />
                              }
                              label="San Diego County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "San Francisco County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="San Francisco County"
                                  color="primary"
                                />
                              }
                              label="San Francisco County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "San Mateo County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="San Mateo County"
                                  color="primary"
                                />
                              }
                              label="San Mateo County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Santa Cruz County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Santa Cruz County"
                                  color="primary"
                                />
                              }
                              label="Santa Cruz County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Solano County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Solano County"
                                  color="primary"
                                />
                              }
                              label="Solano County"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.provider_counties_served
                                      ? data.provider_counties_served.includes(
                                          "Stanislaus County"
                                        )
                                      : false
                                  }
                                  name="provider_counties_served"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                  value="Stanislaus County"
                                  color="primary"
                                />
                              }
                              label="Stanislaus County"
                            />
                          </FormGroup> */}

                          <label>Address:</label>
                          <div
                            className={
                              data.is_confidential
                                ? "flexRow alignCenter marginBottom15 width500"
                                : "flexRow alignCenter width500"
                            }
                          >
                            <Checkbox
                              checked={data.is_confidential}
                              name="is_confidential"
                              onChange={(e) => handleSingleCheckbox(e, index)}
                              className="marginBottom15"
                              color="primary"
                            />
                            <span>
                              Our street address is confidential. (People can
                              search for us based on City, State only).
                            </span>
                          </div>
                          {!data.is_confidential && (
                            <div
                              className={
                                !data.is_confidential
                                  ? "marginBottom30"
                                  : "marginBottom15"
                              }
                            >
                              <AddressSearchBar
                                name="provider_addresses"
                                state="edit"
                                index={index}
                                parentData={
                                  data.provider_addresses
                                    ? data.provider_addresses
                                    : []
                                }
                                updateParent={saveProviderAddresses}
                                redSubLabel="Do not disclose confidential locations."
                              />
                            </div>
                          )}
                          {/* {hasFocus ? (
                            <div className="addresswarning">
                              <p>This information is public to users.</p>
                              <p>Do not disclose confidential information.</p>
                            </div>
                          ) : null}
                          <input
                            type="text"
                            name="provider_address1"
                            value={data.provider_address1}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15"
                            placeholder="Address"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                          />
                          <label>Address 2:</label>
                          <input
                            type="text"
                            name="provider_address2"
                            value={data.provider_address2}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15"
                            placeholder="Address 2"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                          />
                          <label>Address 3:</label>
                          <input
                            type="text"
                            name="provider_address3"
                            value={data.provider_address3}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15"
                            placeholder="Address 3"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                          /> */}

                          <label>Locations served:</label>
                          <div
                            className={
                              data.is_national
                                ? "flexRow alignCenter marginBottom15 width500"
                                : "flexRow alignCenter width500"
                            }
                          >
                            <Checkbox
                              checked={data.is_national}
                              name="is_national"
                              // value={addProviderInfo.is_national}
                              onChange={(e) => handleSingleCheckbox(e, index)}
                              className="marginBottom15"
                              color="primary"
                            />
                            <span>
                              This organization serves anyone located in the
                              United States.
                            </span>
                          </div>
                          {!data.is_national && (
                            <div
                              className={
                                !data.is_national
                                  ? "marginBottom30"
                                  : "marginBottom15"
                              }
                            >
                              <AddressSearchBar
                                name="provider_cities_served"
                                state="edit"
                                index={index}
                                parentData={
                                  data.provider_cities_served
                                    ? data.provider_cities_served
                                    : []
                                }
                                updateParent={saveProviderAddresses}
                                placeholder="City, State"
                              />
                            </div>
                          )}

                          <label>Email Help:</label>
                          <input
                            type="email"
                            name="email_help"
                            value={data.email_help}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15 width300"
                            placeholder="name@email.com"
                          />
                          {error.email_help === "invalid email" ? (
                            <p className="colorRed fontSize14">
                              Please use this format:{" "}
                              <span className="bold">yourname@company.com</span>
                            </p>
                          ) : null}
                          <label>Intake Notification Email:</label>
                          <input
                            type="email"
                            name="email_intake_notify"
                            value={data.email_intake_notify}
                            onChange={(e) => handleChange(e, index)}
                            className="marginBottom15 width300"
                            placeholder="name@email.com"
                          />
                          {error.email_intake_notify === "invalid email" ? (
                            <p className="colorRed fontSize14">
                              Please use this format:{" "}
                              <span className="bold">yourname@company.com</span>
                            </p>
                          ) : null}
                          {/* <label>Created by:</label>
                              <input
                                type="text"
                                name="provider_creator"
                                value={data.provider_creator}
                                onChange={handleChange}
                                className="marginBottom40"
                              /> */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width:
                                // size.width >= 768
                                size[0] >= 768 ? "500px" : "95vw",
                              marginTop: "30px",
                              marginBottom: "50px",
                            }}
                          >
                            {/* <ClearButton
                                  className="cancel-button"
                                  // type="submit"
                                  onClick={() => reset()}
                                >
                                  Cancel
                                </ClearButton> */}
                            {data.provider_ages_served[0] >=
                              data.provider_ages_served[1] ||
                            data.provider_ages_served[0] < 0 ||
                            data.provider_ages_served[0] > 119 ||
                            data.provider_ages_served[1] <=
                              data.provider_ages_served[0] ||
                            data.provider_ages_served[1] < 1 ||
                            data.provider_ages_served[1] > 120 ||
                            data.provider_phone.length !== 14 ||
                            error.provider_phone ||
                            error.provider_hotline ||
                            (error.email_help &&
                              data.email_help.length === 0) ||
                            (error.email_intake_notify &&
                              data.email_intake_notify === 0) ? (
                              <AddProviderButton disabled type="submit">
                                Save
                              </AddProviderButton>
                            ) : (
                              <AddProviderButton
                                onClick={(e) => handleUpdate(e, index)}
                                type="submit"
                              >
                                Save
                              </AddProviderButton>
                            )}
                          </div>
                        </form>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
          {/* ) : null} */}
        </div>
      </div>

      {/* ********************************* Provider Button for FORM************************* */}
      <div>
        <AddProviderButton
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: size[0] < 768 ? "65vw" : "150px",
            marginTop: "30px",
          }}
          onClick={showForm}
        >
          Add a New Provider
        </AddProviderButton>

        {openForm && (
          <>
            <div className={size[0] < 480 ? "width95vw" : "width500"}>
              <form
                className="marginBottom40 marginTop25"
                onSubmit={handleSubmit}
              >
                <label>Provider Name:</label>

                <input
                  type="text"
                  name="provider_name"
                  value={addProviderInfo.provider_name}
                  onChange={handleChange}
                  className="marginBottom15 width300"
                />

                <label className={size[0] < 768 ? "65vw flexRow flexWrap" : ""}>
                  Email (This will add a new user to the platform):
                </label>
                <input
                  type="email"
                  name="email"
                  value={addProviderInfo.email}
                  onChange={(e) => handleChange(e, "email")}
                  className="marginBottom15 width300"
                  placeholder="name@email.com"
                />
                {error.email === "invalid email" ? (
                  <p className="colorRed fontSize14">
                    Please use this format:{" "}
                    <span className="bold">yourname@company.com</span>
                  </p>
                ) : error.email === "User already exists." ? (
                  <p className="colorRed fontSize14">User already exists.</p>
                ) : null}
                <label>Role:</label>
                <FormGroup>
                  <RadioGroup
                    row
                    className="marginBottom20"
                    // aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      checked={addProviderInfo.role === "Admin" ? true : false}
                      value="Admin"
                      control={<Radio />}
                      label="Admin"
                      onChange={(e) => handleChangeRole(e, "role")}
                    />
                    <FormControlLabel
                      checked={
                        addProviderInfo.role === "Standard" ? true : false
                      }
                      value="Standard"
                      control={<Radio />}
                      label="Standard"
                      onChange={(e) => handleChangeRole(e, "role")}
                    />
                  </RadioGroup>
                </FormGroup>

                <label>Activity Level:</label>
                <FormGroup>
                  <RadioGroup
                    row
                    className="marginBottom20"
                    // aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      checked={
                        addProviderInfo.activity_level === "Intake"
                          ? true
                          : false
                      }
                      value="Intake"
                      control={<Radio />}
                      label="Intake"
                      onChange={(e) =>
                        handleCheckboxActLevel(e, "activity_level")
                      }
                    />
                    <FormControlLabel
                      checked={
                        addProviderInfo.activity_level === "No Intake"
                          ? true
                          : false
                      }
                      value="No Intake"
                      control={<Radio />}
                      label="No Intake"
                      onChange={(e) =>
                        handleCheckboxActLevel(e, "activity_level")
                      }
                    />
                  </RadioGroup>
                </FormGroup>
                <div className="startEndHoursWrapper">
                  <label>Start Hours:</label>
                  <input
                    type="time"
                    name="provider_start_hours"
                    defaultValue={addProviderInfo.provider_start_hours}
                    onChange={(e) => handleChange(e, "initial time input")}
                    className="hours marginBottom15"
                  />

                  <label>Close Hours:</label>
                  <input
                    type="time"
                    name="provider_close_hours"
                    defaultValue={addProviderInfo.provider_close_hours}
                    onChange={(e) => handleChange(e, "initial time input")}
                    className="hours marginBottom15"
                  />
                </div>
                <label>Phone Number:</label>
                <div className="flexRow">
                  <input
                    // type={phonenumber}
                    type="tel"
                    name="provider_phone"
                    placeholder="(XXX) XXX-XXXX"
                    value={addProviderInfo.provider_phone}
                    onChange={(e) => handleChange(e, "provider_phone")}
                    className="marginBottom15"
                  />
                  <span className="paddingTop10 paddingLeft8">Ext.</span>
                  <input
                    type="tel"
                    name="provider_phone_ext"
                    value={addProviderInfo.provider_phone_ext}
                    placeholder="XXXXX (if applicable)"
                    onChange={(e) => handleChange(e, "provider_phone_ext")}
                    className="marginBottom15"
                  />
                </div>
                {error.provider_phone === "Nonnumbers used" ? (
                  <p className="colorRed fontSize14 marginTop0">
                    Please enter a valid phone number (numbers only):{" "}
                    <span className="bold">9876543210</span>
                  </p>
                ) : error.provider_phone_ext === "Nonnumbers used" ? (
                  <p className="colorRed fontSize14 marginTop0">
                    Please enter a valid extension (numbers only):{" "}
                    <span className="bold">321</span>
                  </p>
                ) : null}
                <label>Hotline:</label>
                <div className="flexRow">
                  <input
                    type="tel"
                    name="provider_hotline"
                    placeholder="(XXX) XXX-XXXX"
                    value={addProviderInfo.provider_hotline}
                    onChange={(e) => handleChange(e, "provider_hotline")}
                    className="marginBottom15"
                  />
                  <span className="paddingTop10 paddingLeft8">Ext.</span>
                  <input
                    type="tel"
                    name="provider_hotline_ext"
                    value={addProviderInfo.provider_hotline_ext}
                    placeholder="XXXXX (if applicable)"
                    onChange={(e) => handleChange(e, "provider_hotline_ext")}
                    className="marginBottom15"
                  />
                </div>
                {error.provider_hotline === "Nonnumbers used" ? (
                  <p className="colorRed fontSize14 marginTop0">
                    Please enter a valid phone number (numbers only):{" "}
                    <span className="bold">9876543210</span>
                  </p>
                ) : error.provider_hotline_ext === "Nonnumbers used" ? (
                  <p className="colorRed fontSize14 marginTop0">
                    Please enter a valid extension (numbers only):{" "}
                    <span className="bold">321</span>
                  </p>
                ) : null}
                <label>Website:</label>
                <input
                  type="url"
                  name="provider_website"
                  value={addProviderInfo.provider_website}
                  onChange={(e) => handleChange(e, "provider_website")}
                  className="marginBottom15 width300"
                  placeholder="https://www.websiteName.com"
                />
                {error.provider_website === "Https needed" ? (
                  <p className="colorRed fontSize14">
                    Please enter a valid url:{" "}
                    <span className="bold">https://www.websiteName.com</span>
                  </p>
                ) : null}
                <label>Instagram:</label>
                <input
                  type="text"
                  name="provider_instagram"
                  value={addProviderInfo.provider_instagram}
                  onChange={(e) => handleChange(e, "provider_instagram")}
                  className="marginBottom15 width300"
                  placeholder="https://www.instagramName.com"
                />
                {error.provider_instagram === "Https needed" ? (
                  <p className="colorRed fontSize14">
                    Please enter a valid url:{" "}
                    <span className="bold">
                      https://www.instagram.com/instagramName
                    </span>
                  </p>
                ) : null}
                <label>Facebook:</label>
                <input
                  type="text"
                  name="provider_facebook"
                  value={addProviderInfo.provider_facebook}
                  onChange={(e) => handleChange(e, "provider_facebook")}
                  className="marginBottom15 width300"
                  placeholder="https://www.facebookName.com"
                />
                {error.provider_facebook === "Https needed" ? (
                  <p className="colorRed fontSize14">
                    Please enter a valid url:{" "}
                    <span className="bold">
                      https://www.facebook.com/facebookName
                    </span>
                  </p>
                ) : null}
                <label>Clients:</label>
                <input
                  type="text"
                  name="org_for_who"
                  value={addProviderInfo.org_for_who}
                  onChange={handleChange}
                  className="marginBottom15"
                />
                <label>Services:</label>
                <input
                  type="text"
                  name="org_our_services"
                  value={addProviderInfo.org_our_services}
                  onChange={handleChange}
                  className="marginBottom15"
                />
                <label>Who We Are:</label>
                <input
                  type="text"
                  name="org_we_are"
                  value={addProviderInfo.org_we_are}
                  onChange={handleChange}
                  className="marginBottom15"
                />
                <label>Variables Intake:</label>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "address_line1"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="address_line1"
                        color="primary"
                      />
                    }
                    label="city, state, county"
                  />
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "address_line2"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="address_line2"
                        color="primary"
                      />
                    }
                    label="address_line2"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "address_city"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="address_city"
                        color="primary"
                      />
                    }
                    label="address_city"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "address_state"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="address_state"
                        color="primary"
                      />
                    }
                    label="address_state"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "address_zipcode"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="address_zipcode"
                        color="primary"
                      />
                    }
                    label="address_zipcode"
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "date_of_birth"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="date_of_birth"
                        color="primary"
                      />
                    }
                    label="date_of_birth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "gender"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="gender"
                        color="primary"
                      />
                    }
                    label="gender"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "sexual_orientation"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="sexual_orientation"
                        color="primary"
                      />
                    }
                    label="sexual_orientation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "ethnicity"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="ethnicity"
                        color="primary"
                      />
                    }
                    label="ethnicity"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "preferred_language"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="preferred_language"
                        color="primary"
                      />
                    }
                    label="preferred_language"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "survivorship"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="survivorship"
                        color="primary"
                      />
                    }
                    label="survivorship"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "foster_care"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="foster_care"
                        color="primary"
                      />
                    }
                    label="foster_care"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "incarceration"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="incarceration"
                        color="primary"
                      />
                    }
                    label="incarceration"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "immigration"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="immigration"
                        color="primary"
                      />
                    }
                    label="immigration"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "children"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="children"
                        color="primary"
                      />
                    }
                    label="children"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "num_of_children"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="num_of_children"
                        color="primary"
                      />
                    }
                    label="num_of_children"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_intake.includes(
                          "accommodations"
                        )}
                        name="required_variables_intake"
                        onChange={handleCheckboxChange}
                        value="accommodations"
                        color="primary"
                      />
                    }
                    label="accommodations"
                  />
                </FormGroup>

                {/* <label>Variables General:</label>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "preferred_name"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="preferred_name"
                        color="primary"
                      />
                    }
                    label="preferred_name"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "first_name"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="first_name"
                        color="primary"
                      />
                    }
                    label="first_name"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "last_name"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="last_name"
                        color="primary"
                      />
                    }
                    label="last_name"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "phone"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="phone"
                        color="primary"
                      />
                    }
                    label="phone"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "email"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="email"
                        color="primary"
                      />
                    }
                    label="email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "county_location"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="county_location"
                        color="primary"
                      />
                    }
                    label="county_location"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.required_variables_general.includes(
                          "county_services"
                        )}
                        name="required_variables_general"
                        onChange={handleCheckboxChange}
                        value="county_services"
                        color="primary"
                      />
                    }
                    label="county_services"
                  />
                </FormGroup> */}
                <FormGroup className="marginBottom10">
                  {/* Age Range Input Initial Add Provider State */}
                  <p className="bold marginBottom10">Ages Served</p>
                  <p className="marginBottom0 marginTop0">Set Exact Range: </p>
                  <div className="flexRow ageRangeWrapper marginBottom15">
                    <TextField
                      name="AgeRangeMin"
                      className="ageTextField"
                      type="number"
                      style={{
                        width: "45%",
                        height: "10%",
                        borderRadius: "5px",
                        // more styles in App.css
                      }}
                      // variant="outlined"
                      defaultValue={
                        addProviderInfo.provider_ages_served.length > 0 &&
                        addProviderInfo.provider_ages_served[0] === 0
                          ? 0
                          : addProviderInfo.provider_ages_served[0]
                          ? addProviderInfo.provider_ages_served[0]
                          : null
                      }
                      InputProps={{
                        inputProps: { min: "0", max: "119" },
                      }}
                      onChange={(e) => handleAgeInput(e, "ageRangeMin")}
                      error={
                        addProviderInfo.provider_ages_served[0] >=
                          addProviderInfo.provider_ages_served[1] ||
                        addProviderInfo.provider_ages_served[0] < 0 ||
                        addProviderInfo.provider_ages_served[0] > 119
                      }
                      helperText={
                        addProviderInfo.provider_ages_served[0] > 119
                          ? "Must be 119 or less"
                          : addProviderInfo.provider_ages_served[0] >
                              addProviderInfo.provider_ages_served[1] &&
                            addProviderInfo.provider_ages_served[0] !== 0
                          ? "Enter a lower number"
                          : addProviderInfo.provider_ages_served[0] ===
                            addProviderInfo.provider_ages_served[1]
                          ? "Ages cannot be equal"
                          : addProviderInfo.provider_ages_served[0] < 0
                          ? "Age must be 0+"
                          : null
                      }
                    />
                    <p>to</p>
                    <TextField
                      name="AgeRangeMax"
                      className="ageTextField"
                      type="number"
                      style={{
                        width: "45%",
                        height: "10%",
                        borderRadius: "5px",
                        // more styles in App.css
                      }}
                      // variant="outlined"
                      defaultValue={
                        addProviderInfo.provider_ages_served.length > 0 &&
                        addProviderInfo.provider_ages_served[1] === 0
                          ? 0
                          : addProviderInfo.provider_ages_served[1]
                          ? addProviderInfo.provider_ages_served[1]
                          : null
                      }
                      InputProps={{
                        inputProps: { min: "1", max: "120" },
                      }}
                      onChange={(e) => handleAgeInput(e, "ageRangeMax")}
                      error={
                        addProviderInfo.provider_ages_served[1] <=
                          addProviderInfo.provider_ages_served[0] ||
                        addProviderInfo.provider_ages_served[1] < 1 ||
                        addProviderInfo.provider_ages_served[1] > 120
                      }
                      helperText={
                        addProviderInfo.provider_ages_served[1] <=
                        addProviderInfo.provider_ages_served[0]
                          ? "Enter a higher number"
                          : addProviderInfo.provider_ages_served[1] > 120
                          ? "Must be 120 or less"
                          : addProviderInfo.provider_ages_served[1] < 1
                          ? "Age must be 1+"
                          : null
                      }
                    />
                  </div>
                  {/* Age Slider Initial Add Provider State */}
                  {/* <div
                    className={classes.ageslider}
                    style={{ overflow: "hidden" }}
                  >
                    <div className="column left">
                      <Typography
                        className="slider-value"
                        id="range-slider"
                        gutterBottom
                      >
                        {value[0]}{" "}
                      </Typography>
                    </div>
                    <div className="column middle">
                      <Slider
                        name="provider_ages_served"
                        value={value}
                        // value={addProviderInfo.provider_ages_served}
                        onChange={handleAgeInput}
                        aria-labelledby="range-slider"
                        max={120}
                        style={{ marginLeft: "15px" }}
                      />
                    </div>
                    <div className="column right">
                      <Typography
                        id="range-slider"
                        gutterBottom
                        className="slider-value"
                        style={{ marginLeft: "30px" }}
                      >
                        {value[1]}{" "}
                      </Typography>
                    </div>
                  </div> */}
                </FormGroup>
                {/* <label>Ages Served:</label>
								<FormGroup className="marginBottom20">
									<FormControlLabel
										control={
											<Checkbox
												checked={addProviderInfo.provider_ages_served.includes(
													"Under 18 years old"
												)}
												name="provider_ages_served"
												onChange={handleCheckboxChange}
												value="Under 18 years old"
												color="primary"
											/>
										}
										label="Under 18 years old"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={addProviderInfo.provider_ages_served.includes(
													"16 - 24 years old"
												)}
												name="provider_ages_served"
												onChange={handleCheckboxChange}
												value="16 - 24 years old"
												color="primary"
											/>
										}
										label="16 - 24 years old"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={addProviderInfo.provider_ages_served.includes(
													"18 years old and older"
												)}
												name="provider_ages_served"
												onChange={handleCheckboxChange}
												value="18 years old and older"
												color="primary"
											/>
										}
										label="18 years old and older"
									/>
								</FormGroup> */}

                <label>Services Offered</label>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Housing and Shelter"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Housing and Shelter"
                        color="primary"
                      />
                    }
                    label="Housing and Shelter"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Workforce Development"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Workforce Development"
                        color="primary"
                      />
                    }
                    label="Workforce Development"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Legal Aid"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Legal Aid"
                        color="primary"
                      />
                    }
                    label="Legal Aid"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Financial Assistance"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Financial Assistance"
                        color="primary"
                      />
                    }
                    label="Financial Assistance"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Mental Health"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Mental Health"
                        color="primary"
                      />
                    }
                    label="Mental Health"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Case Management"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Case Management"
                        color="primary"
                      />
                    }
                    label="Case Management"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Healthcare"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Healthcare"
                        color="primary"
                      />
                    }
                    label="Healthcare"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "24-Hour Hotlines"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="24-Hour Hotlines"
                        color="primary"
                      />
                    }
                    label="24-Hour Hotlines"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Drop-In Center"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Drop-In Center"
                        color="primary"
                      />
                    }
                    label="Drop-In Center"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Support Group Services"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Support Group Services"
                        color="primary"
                      />
                    }
                    label="Support Group Services"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Education"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Education"
                        color="primary"
                      />
                    }
                    label="Education"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Healing Arts"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Healing Arts"
                        color="primary"
                      />
                    }
                    label="Healing Arts"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Youth Development Services"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Youth Development Services"
                        color="primary"
                      />
                    }
                    label="Youth Development Services"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Substance Overuse Services"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Substance Overuse Services"
                        color="primary"
                      />
                    }
                    label="Substance Overuse Services"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Public Benefits"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Public Benefits"
                        color="primary"
                      />
                    }
                    label="Public Benefits"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Peer Support"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Peer Support"
                        color="primary"
                      />
                    }
                    label="Peer Support"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Basic Needs Resource"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Basic Needs Resource"
                        color="primary"
                      />
                    }
                    label="Basic Needs Resource"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Crisis Intervention"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Crisis Intervention"
                        color="primary"
                      />
                    }
                    label="Crisis Intervention"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Entrepreneurship"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Entrepreneurship"
                        color="primary"
                      />
                    }
                    label="Entrepreneurship"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Leadership Development"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Leadership Development"
                        color="primary"
                      />
                    }
                    label="Leadership Development"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Food Access"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Food Access"
                        color="primary"
                      />
                    }
                    label="Food Access"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Child Welfare and Child Protection"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Child Welfare and Child Protection"
                        color="primary"
                      />
                    }
                    label="Child Welfare and Child Protection"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_services_offered.includes(
                          "Telephone Hotline"
                        )}
                        name="provider_services_offered"
                        onChange={handleCheckboxChange}
                        value="Telephone Hotline"
                        color="primary"
                      />
                    }
                    label="Telephone Hotline"
                  />
                </FormGroup>

                <label>Genders Served:</label>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Woman"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Woman"
                        color="primary"
                      />
                    }
                    label="Woman"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Trans Woman"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Trans Woman"
                        color="primary"
                      />
                    }
                    label="Trans Woman"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Man"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Man"
                        color="primary"
                      />
                    }
                    label="Man"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Trans Man"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Trans Man"
                        color="primary"
                      />
                    }
                    label="Trans Man"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Gender Queer"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Gender Queer"
                        color="primary"
                      />
                    }
                    label="Gender Queer"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Gender Non-Conforming"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Gender Non-Conforming"
                        color="primary"
                      />
                    }
                    label="Gender Non-Conforming"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Gender Non-Binary"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Gender Non-Binary"
                        color="primary"
                      />
                    }
                    label="Gender Non-Binary"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "Agender"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="Agender"
                        color="primary"
                      />
                    }
                    label="Agender"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_genders_served.includes(
                          "None of these"
                        )}
                        name="provider_genders_served"
                        onChange={handleCheckboxChange}
                        value="None of these"
                        color="primary"
                      />
                    }
                    label="None of these"
                  />
                </FormGroup>

                <label>Other Characteristics:</label>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_other_characteristics.includes(
                          "Drop-In Available"
                        )}
                        name="provider_other_characteristics"
                        onChange={handleCheckboxChange}
                        value="Drop-In Available"
                        color="primary"
                      />
                    }
                    label="Drop-in Available"
                  />
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_other_characteristics.includes(
                          "Open Now"
                        )}
                        name="provider_other_characteristics"
                        onChange={handleCheckboxChange}
                        value="Open Now"
                        color="primary"
                      />
                    }
                    label="Open Now"
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_other_characteristics.includes(
                          "Virtual Services"
                        )}
                        name="provider_other_characteristics"
                        onChange={handleCheckboxChange}
                        value="Virtual Services"
                        color="primary"
                      />
                    }
                    label="Virtual Services"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_other_characteristics.includes(
                          "Faith-Based"
                        )}
                        name="provider_other_characteristics"
                        onChange={handleCheckboxChange}
                        value="Faith-Based"
                        color="primary"
                      />
                    }
                    label="Faith-based"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_other_characteristics.includes(
                          "Children Allowed"
                        )}
                        name="provider_other_characteristics"
                        onChange={handleCheckboxChange}
                        value="Children Allowed"
                        color="primary"
                      />
                    }
                    label="Children Allowed"
                  />
                </FormGroup>

                {/* <label>County:</label>
                <FormGroup className="marginBottom20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Alameda County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Alameda County"
                        color="primary"
                      />
                    }
                    label="Alameda County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Contra Costa County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Contra Costa County"
                        color="primary"
                      />
                    }
                    label="Contra Costa County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Marin County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Marin County"
                        color="primary"
                      />
                    }
                    label="Marin County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Monterey County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Monterey County"
                        color="primary"
                      />
                    }
                    label="Monterey County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "San Diego County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="San Diego County"
                        color="primary"
                      />
                    }
                    label="San Diego County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "San Francisco County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="San Francisco County"
                        color="primary"
                      />
                    }
                    label="San Francisco County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "San Mateo County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="San Mateo County"
                        color="primary"
                      />
                    }
                    label="San Mateo County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Santa Cruz County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Santa Cruz County"
                        color="primary"
                      />
                    }
                    label="Santa Cruz County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Solano County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Solano County"
                        color="primary"
                      />
                    }
                    label="Solano County"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addProviderInfo.provider_counties_served.includes(
                          "Stanislaus County"
                        )}
                        name="provider_counties_served"
                        onChange={handleCheckboxChange}
                        value="Stanislaus County"
                        color="primary"
                      />
                    }
                    label="Stanislaus County"
                  />
                </FormGroup> */}

                <label>Address</label>
                {/* {hasFocus ? (
                  <div className="addresswarning">
                    <p>This information is public to users.</p>
                    <p>Do not disclose confidential information.</p>
                  </div>
                ) : null}
                <input
                  type="text"
                  name="provider_address1"
                  value={addProviderInfo.provider_address1}
                  onChange={handleChange}
                  className="marginBottom15"
                  placeholder="Address 1"
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                /> */}
                <div
                  className={
                    addProviderInfo.is_confidential
                      ? "flexRow alignCenter marginBottom15"
                      : "flexRow alignCenter width500"
                  }
                >
                  <Checkbox
                    checked={addProviderInfo.is_confidential}
                    name="is_confidential"
                    onChange={(e) => handleSingleCheckbox(e, "is_confidential")}
                    className="marginBottom15"
                    color="primary"
                  />
                  <span>
                    Our street address is confidential. (People can search for
                    us based on City, State only).
                  </span>
                </div>
                {!addProviderInfo.is_confidential && (
                  <div
                    className={
                      !addProviderInfo.is_confidential
                        ? "marginBottom30"
                        : "marginBottom15"
                    }
                  >
                    <AddressSearchBar
                      name="provider_addresses"
                      state="edit"
                      parentData={addProviderInfo.provider_addresses}
                      updateParent={saveProviderAddresses}
                      redSubLabel="Do not disclose confidential locations."
                      checkedLabel="Checked addresses show publicly in your organization profile."
                    />
                  </div>
                )}
                {/* <label>Address 2:</label>
                <input
                  type="text"
                  name="provider_address2"
                  value={addProviderInfo.provider_address2}
                  onChange={handleChange}
                  className="marginBottom15"
                  placeholder="Address 2"
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
                <label>Address 3:</label>
                <input
                  type="text"
                  name="provider_address3"
                  value={addProviderInfo.provider_address3}
                  onChange={handleChange}
                  className="marginBottom15"
                  placeholder="Address 3"
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                /> */}

                <label>Locations served:</label>
                <div
                  className={
                    addProviderInfo.is_national
                      ? "flexRow alignCenter marginBottom15"
                      : "flexRow alignCenter"
                  }
                >
                  <Checkbox
                    checked={addProviderInfo.is_national}
                    name="is_national"
                    // value={addProviderInfo.is_national}
                    onChange={(e) => handleSingleCheckbox(e, "is_national")}
                    className="marginBottom15"
                    color="primary"
                  />
                  <span>
                    This organization serves anyone located in the United
                    States.
                  </span>
                </div>
                {!addProviderInfo.is_national && (
                  <div
                    className={
                      !addProviderInfo.is_national
                        ? "marginBottom30"
                        : "marginBottom15"
                    }
                  >
                    <AddressSearchBar
                      name="provider_cities_served"
                      state="edit"
                      parentData={addProviderInfo.provider_cities_served}
                      updateParent={saveProviderAddresses}
                      placeholder="City, State"
                    />
                  </div>
                )}

                <label>Email Help:</label>
                <input
                  type="email"
                  name="email_help"
                  value={addProviderInfo.email_help}
                  onChange={(e) => handleChange(e, "email_help")}
                  className="marginBottom15 width300"
                  placeholder="name@email.com"
                />
                {error.email_help === "invalid email" ? (
                  <p className="colorRed fontSize14">
                    Please use this format:{" "}
                    <span className="bold">yourname@company.com</span>
                  </p>
                ) : null}
                {/* <label>Created by:</label>
                <input
                  type="text"
                  name="provider_creator"
                  value={addProviderInfo.provider_creator}
                  onChange={handleChange}
                  className="marginBottom40"
                /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width:
                      // size.width >= 768
                      size[0] >= 768 ? "500px" : "95vw",
                    marginTop: "30px",
                    marginBottom: "100px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <ClearButton
                    className="cancel-button"
                    // type="submit"
                    onClick={() => {
                      reset();
                      showForm();
                    }}
                  >
                    Cancel
                  </ClearButton>
                  {addProviderInfo.provider_ages_served[0] >=
                    addProviderInfo.provider_ages_served[1] ||
                  addProviderInfo.provider_ages_served[0] < 0 ||
                  addProviderInfo.provider_ages_served[0] > 119 ||
                  addProviderInfo.provider_ages_served[1] <=
                    addProviderInfo.provider_ages_served[0] ||
                  addProviderInfo.provider_ages_served[1] < 1 ||
                  addProviderInfo.provider_ages_served[1] > 120 ||
                  error.email ||
                  error.email_help ||
                  error.provider_phone ||
                  error.provider_hotline ||
                  error.provider_website ||
                  error.provider_facebook ||
                  error.provider_instagram ? (
                    <AddProviderButton type="submit" disabled>
                      Save
                    </AddProviderButton>
                  ) : (
                    <AddProviderButton type="submit" onClick={handleSubmit}>
                      Save
                    </AddProviderButton>
                  )}
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminAddProvider;
