import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  IconButton,
  Modal,
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import { ErrorOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import { Auth, API } from "aws-amplify";
import { useAppContext } from "../../useContext";
import mixpanel from "mixpanel-browser";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    fontFamily: "Helvetica",
    // margin: "auto",
  },
  loginWrapper: {
    padding: "30px 20px 20px",
    // background: "white",
    marginBottom: "100px",
    display: "flex",
    flexDirection: "column",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  primaryButton: {
    backgroundColor: "#a10070",
    borderRadius: "3px",
    color: "white",
    marginTop: "20px",
    alignSelf: "center",
    fontStyle: "Arial",
    fontWeight: "bolder",
    TypographyTransform: "none",
    width: "100px",
    height: "40px",
    "&:disabled": {
      opacity: "0.3",
      color: "white",
    },
  },
  forgotPassword: {
    color: "#28ADE3",
    cursor: "pointer",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export default function NewUser(props) {
  const size = props.windowSize;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [contactResourceFullModal, setContactResourceFullModal] =
    useState(false);
  const [tempPasswordModal, setTempPasswordModal] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, message: "" });
  const {
    userHasAuthenticated,
    setProvider,
    setProviderId,
    setUserId,
    setEmail,
    setIsVisible,
  } = useAppContext();
  mixpanel.track("P SignUp PgVw", {
    "Event Type": "Page View",
  });

  // email validation
  const [error, setError] = useState("initial email input");
  // user does not exist handling
  const [errorUserModal, setErrorUserModal] = useState({
    log: "",
    open: false,
  });
  // terms of service modal
  const [termsModal, setTermsModal] = useState(false);

  //password help Typography states
  const [passwordError, setPasswordError] = useState(["initial state"]);
  const defaultPwdHelpTypography =
    "Please make sure your password has 8 to 20 characters, a number, a lowercase letter, an uppercase letter, and a special character (e.g., !@#$%^&*()).";
  const [passwordHelpTypography, setPasswordHelpTypography] = useState(
    defaultPwdHelpTypography
  );

  //  fix Warning: Can't perform a React state update on an unmounted component
  useEffect(() => {
    return () => {
      setLoading(false);
      setUserData({});
      setContactResourceFullModal(false);
      setTempPasswordModal(false);
      setErrorModal({ open: false, message: "" });
      setError("initial email input");
      setErrorUserModal({
        log: "",
        open: false,
      });
      setTermsModal(false);
      setPasswordError(["initial state"]);
    };
  }, []);

  const isEmailValid = (value) => {
    const validRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value.match(validRegex)) {
      setError("invalid email");
      // console.log(error);
      return false;
    } else {
      setError("");
      // console.log(error);
      return true;
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.name === "password") {
      isPasswordValid(value);
      // let isValid = isPasswordValid(value);
      // console.log(isValid, passwordError);
    } else if (event.target.name === "email") {
      // let isValid = isEmailValid(value);
      // console.log(isValid, error);
      isEmailValid(value);
      doesUserExist(value);
      value = value.toLowerCase();
    } else if (event.target.name === "terms_of_service") {
      value = event.target.checked;
    }
    var newValue = { ...userData };
    newValue[event.target.name] = value;
    setUserData(newValue);
  };

  const doesUserExist = async (username) => {
    // cannot use Auth.signIn because user is already signed in, so errors out.
    // must use lambda to check Cognito for existing users.
    API.post("referall-provider", "checkuserexists", {
      body: { email: username },
    }).then((response) => {
      // console.log(response);
      if (response.userExists) {
        setErrorUserModal({ ...errorUserModal, log: "User exists." });
      } else {
        setErrorUserModal({ ...errorUserModal, log: "User does not exist." });
      }
      return response;
    });
    // .catch((error) => {
    //   mixpanel.track("P ConsoleLog Error", {
    //     "Event Type": "API Call",
    //     "Error Message": error,
    //     "API Name": "checkuserexists",
    //     "File Name": "NewUser.js",
    //     "File Line": 199,
    //   });
    // });
  };

  const handleClickShowPassword = () => {
    setUserData({
      ...userData,
      showPassword: !userData.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setUserData({
      ...userData,
      showConfirmPassword: !userData.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isPasswordValid = (value) => {
    const validPasswordRegex =
      // /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()-+_;:'"<>,.?]{8,20}$/;
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()]).{8,20}$/;
    const hasSyms = /^(?=.*?[!@#$%^&*()]).{1,20}$/;
    const hasNums = /^(?=.*?[0-9]).{1,20}$/;
    const hasCaps = /^(?=.*?[A-Z]).{1,20}$/;
    const hasMins = /^(?=.*?[a-z]).{1,20}$/;
    // const needLength = /^[\s\S]{1,7}$/;
    let needs = [...passwordError];
    if (value.match(validPasswordRegex)) {
      setPasswordError(["valid password"]);
      setPasswordHelpTypography("");
      // console.log(passwordError);
      return true;
    } else if (value === "") {
      setPasswordError(["invalid password"]);
      setPasswordHelpTypography(defaultPwdHelpTypography);
      return false;
    } else {
      if (needs.includes("valid password")) {
        let index = needs.findIndex((item) => item === "valid password");
        needs.splice(index, 1);
      }
      if (!needs.includes("invalid password")) {
        needs.push("invalid password");
      }
      if (value.match(hasSyms)) {
        if (!needs.includes("hasSyms")) {
          needs.push("hasSyms");
        }
      } else {
        needs = needs.filter((item) => item !== "hasSyms");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasNums)) {
        if (!needs.includes("hasNums")) {
          needs.push("hasNums");
        }
      } else {
        needs = needs.filter((item) => item !== "hasNums");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasCaps)) {
        if (!needs.includes("hasCaps")) {
          needs.push("hasCaps");
        }
      } else {
        needs = needs.filter((item) => item !== "hasCaps");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.match(hasMins)) {
        if (!needs.includes("hasMins")) {
          needs.push("hasMins");
        }
      } else {
        needs = needs.filter((item) => item !== "hasMins");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      if (value.length < 8 || value.length > 20) {
        // console.log("needLength");
        if (!needs.includes("needLength")) {
          needs.push("needLength");
        }
      } else {
        needs = needs.filter((item) => item !== "needLength");
        if (needs.length === 0) {
          needs = ["invalid password"];
        }
      }
      // console.log(needs);
      setPasswordError(needs);
      let helpTypography = [
        needs.includes("needLength") && "8 to 20 characters",
        !needs.includes("hasNums") && "a number",
        !needs.includes("hasMins") && "a lowercase letter",
        !needs.includes("hasCaps") && "an uppercase letter",
        !needs.includes("hasSyms") && "a special character (e.g. !@#$%^&*())",
      ].filter(Boolean);
      if (
        helpTypography.length === 0 &&
        needs &&
        needs.length === 1 &&
        needs.includes("invalid password")
      ) {
        helpTypography = defaultPwdHelpTypography;
      } else if (helpTypography.length === 1) {
        helpTypography =
          "Please make sure your password has " + helpTypography[0] + ".";
      } else if (helpTypography.length === 2) {
        helpTypography =
          "Please make sure your password has " +
          helpTypography.join(" and ") +
          ".";
      } else if (helpTypography.length > 2) {
        let lastItem = " and " + helpTypography.pop() + ".";
        helpTypography =
          "Please make sure your password has " +
          helpTypography.join(", ") +
          lastItem;
      }
      // console.log(needs, helpTypography);
      setPasswordHelpTypography(helpTypography);
      return false;
    }
  };

  const handleSendCodeClick = async () => {
    try {
      API.post("referall-provider", "resettemppassword", {
        body: {
          email: userData.email.toLowerCase(),
          // resendTempPassword: userData.resendTempPassword,
        },
      }).then((response) => {
        // console.log(response);
        if (response.resendTempPassword === false) {
          // console.log(response);
          // if (response) {
          setUserData({ ...userData, resendTempPassword: false });
        } else if (response.resendTempPassword === true) {
          Auth.forgotPassword(userData.email)
            .then((response) => {
              // console.log(response);
              setUserData({ ...userData, forgotPassword: true });
              // console.log(userData);
            })
            .catch((err) => {
              console.log(err);
              mixpanel.track("P ConsoleLog Error", {
                "Event Type": "API Call",
                "Error Message": err,
                "API Name": "Auth.forgotPassword",
                "File Name": "NewUser.js",
                "File Line": 363,
              });
            });
        }
      });
      // let response = await Auth.currentAuthenticatedUser(); //undefined
      // let response = await Auth.forgotPassword(userData.email.toLowerCase()); //cannot reset when UserStatus === 'FORCE_CHANGE_PASSWORD'
      // let response = await Auth.resendSignUp(userData.email.toLowerCase()); //'Cannot resend codes. Auto verification not turned on.'
      // let response = await Auth.signUp(
      //   userData.email.toLowerCase(),
      //   userData.temppassword
      // ); //'An account with the given email already exists.'
    } catch (e) {
      console.log(e); // "User password cannot be reset in the current state."
      if (e.message === "User password cannot be reset in the current state.") {
        userData.resendTempPassword = true;
      } else {
        // alert(e.message);
        setErrorModal({ open: true, message: e.message });
        mixpanel.track("P ConsoleLog Error", {
          "Event Type": "API Call",
          "Error Message": e,
          "API Name": "resettemppassword",
          "File Name": "NewUser.js",
          "File Line": 388,
        });
      }
    }
  };

  const handleSubmit = async () => {
    // console.log(userData);
    setLoading(true);
    if (
      userData.temppassword.startsWith("Ac12!") &&
      userData.resendTempPassword === true &&
      userData.password === userData.confirmpassword
    ) {
      setTempPasswordModal(true);
      setLoading(false);
    } else if (userData.password !== userData.confirmpassword) {
      // alert("Passwords do not match.");
      setErrorModal({ open: true, message: "Passwords do not match." });
      setLoading(false);
      return;
    } else if (userData.forgotPassword === true) {
      // console.log("existing user forgot password");
      setLoading(false);
      // console.log(userData);
      Auth.forgotPasswordSubmit(
        userData.email.toLowerCase(),
        userData.temppassword,
        userData.password
      )
        .then((response) => {
          // console.log(response); //undefined
          Auth.signIn(userData.email.toLowerCase(), userData.password).then(
            (user) => {
              // console.log(user);
              setEmail(user.attributes.email);
              API.post("referall-provider", "getuserprovider", {
                body: {
                  user_id: user.username,
                },
              })
                .then((response) => {
                  // console.log(response);
                  setProvider(response[0].provider);
                  setProviderId(response[0].provider_id);
                  userHasAuthenticated(true);
                  history.push("/serviceproviders");
                  setUserId(userData.user_id);
                  setIsVisible(response[0].is_visible);
                  setError("");
                })
                .catch(async (err) => {
                  console.log(err); //err appears when org deleted in db but user still exists in cognito
                  mixpanel.track("P ConsoleLog Error", {
                    "Event Type": "API Call",
                    "Error Message": err,
                    "API Name": "getuserprovider",
                    "File Name": "NewUser.js",
                    "File Line": 445,
                  });
                  setLoading(false);
                  await Auth.signOut(); //must sign out so user does not get stuck with blank page
                  userHasAuthenticated(false);
                  setProvider([]);
                  setProviderId([]);
                  // alert("Your password has been updated.");
                  setErrorModal({
                    open: true,
                    message: "Your password has been updated.",
                  });
                  history.push("/");
                });
            }
          );
        })
        .catch((err) => {
          console.log(err);
          mixpanel.track("P ConsoleLog Error", {
            "Event Type": "API Call",
            "Error Message": err,
            "API Name": "Auth.forgotPasswordSubmit",
            "File Name": "NewUser.js",
            "File Line": 468,
          });
        });
    } else {
      Auth.signIn(userData.email.toLowerCase(), userData.temppassword)
        .then((user) => {
          // console.log(user);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, userData.password)
              .then((user) => {
                // console.log(user);
                if (user.challengeName === undefined) {
                  API.post("referall-provider", "sendwelcomeemail", {
                    body: {
                      email: user.challengeParam.userAttributes.email,
                      // email: userData.email.toLowerCase(),
                      // user_id: user.username,
                      // resendTempPassword: userData.resendTempPassword,
                    },
                  });
                }
                return user;
              })
              .then((user) => {
                // console.log(user);
                setEmail(user.challengeParam.userAttributes.email);
                API.post("referall-provider", "getuserprovider", {
                  body: {
                    // email: user.challengeParam.userAttributes.email,
                    // email: userData.email.toLowerCase(),
                    user_id: user.username,
                    // resendTempPassword: userData.resendTempPassword,
                  },
                })
                  .then((response) => {
                    setProvider(response[0].provider);
                    setProviderId(response[0].provider_id);
                    userHasAuthenticated(true);
                    mixpanel.track("P SignUp Submit", {
                      "Event Type": "User Action",
                    });
                    history.push("/serviceproviders");
                    setUserId(userData.user_id);
                    setIsVisible(response[0].is_visible);
                    setError("");
                  })
                  .catch(async (err) => {
                    console.log(err); //err appears when org deleted in db but user still exists in cognito
                    mixpanel.track("P ConsoleLog Error", {
                      "Event Type": "API Call",
                      "Error Message": err,
                      "API Name": "getuserprovider",
                      "File Name": "NewUser.js",
                      "File Line": 520,
                    });
                    // alert("You need to update your password!");
                    // history.push("/newuser");
                    setLoading(false);
                    await Auth.signOut(); //must sign out so user does not get stuck with blank page
                    userHasAuthenticated(false);
                    setProvider([]);
                    setProviderId([]);
                    // alert("Your password has been updated.");
                    setErrorModal({
                      open: true,
                      message: "Your password has been updated.",
                    });
                    history.push("/");
                  });
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                if (err.code === "UserNotConfirmedException") {
                  // alert("User is not confirmed.");
                  setErrorModal({
                    open: true,
                    message: "User is not confirmed.",
                  });
                } else if (
                  err.message ===
                  "Temporary password has expired and must be reset by an administrator.'"
                ) {
                  setTempPasswordModal(true);
                  setUserData({ ...userData, resendTempPassword: true });
                } else if (err.code === "NotAuthorizedException") {
                  // alert(
                  //   "You entered the incorrect password. Please try again."
                  // );
                  setErrorModal({
                    open: true,
                    message:
                      "You entered the incorrect password. Please try again.",
                  });
                } else if (err.code === "UserNotFoundException") {
                  // alert("User not found. Please try again.");
                  setErrorModal({
                    open: true,
                    message: "User not found. Please try again.",
                  });
                } else if (err.code === "InvalidPasswordException") {
                  // alert(
                  //   "Password should have 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character "
                  // );
                  setErrorModal({
                    open: true,
                    message:
                      "Password should have 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character ",
                  });
                } else {
                  // alert(err.message);
                  setErrorModal({
                    open: true,
                    message: err.message,
                  });
                  mixpanel.track("P ConsoleLog Error", {
                    "Event Type": "API Call",
                    "Error Message": err,
                    "API Name": "Auth.completeNewPassword",
                    "File Name": "NewUser.js",
                    "File Line": 586,
                  });
                }
              });
          } else {
            console.log("error");
            mixpanel.track("P ConsoleLog Error", {
              "Event Type": "API Call",
              "Error Message": "error",
              "API Name": "Auth.signIn user.challengeName else",
              "File Name": "NewUser.js",
              "File Line": 597,
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (
            err.message ===
            "Temporary password has expired and must be reset by an administrator."
          ) {
            setTempPasswordModal(true);
            setUserData({ ...userData, resendTempPassword: true });
          } else if (err.code === "UserNotConfirmedException") {
            // alert("User is not confirmed.");
            setErrorModal({
              open: true,
              message: "User is not confirmed.",
            });
          } else if (err.code === "NotAuthorizedException") {
            // alert("You entered the incorrect password. Please try again.");
            // alert(
            //   "You entered the incorrect temporary password. Please check your email and try again."
            // ); //shows for expired passwords/passwords older than the most recent password. will also show after password has already been changed and somehow user goes back to sign up page instead of the sign in page.
            setErrorModal({
              open: true,
              message:
                "You entered the incorrect temporary password. Please check your email and try again.",
            });
          } else if (err.code === "UserNotFoundException") {
            // alert("User not found. Please try again.");
            setErrorModal({
              open: true,
              message: "User not found. Please try again.",
            });
          } else if (err.code === "InvalidParameterException") {
            // alert("You must enter the temporary password.");
            setErrorModal({
              open: true,
              message: "You must enter the temporary password.",
            });
          } else {
            // alert(err.message);
            setErrorModal({
              open: true,
              message: err.message,
            });
            mixpanel.track("P ConsoleLog Error", {
              "Event Type": "API Call",
              "Error Message": err,
              "API Name": "Auth.signIn",
              "File Name": "NewUser.js",
              "File Line": 650,
            });
          }
        });
    }
  };

  return (
    <div
      className={
        // size.width < 768
        size[0] < 768 ? `${classes.root} marginBottom60` : `${classes.root}`
      }
    >
      <div
        className={
          // size.width < 768
          size[0] < 768
            ? `${classes.loginWrapper}`
            : `${classes.loginWrapper} width500`
        }
      >
        <h1 className="TypographyAlignCenter fontHelvetica">
          ResourceFull Sign Up
        </h1>
        <TextField
          className={classes.margin}
          id="new-email-TextField"
          name="email"
          label="Email"
          fullWidth
          onChange={handleChange}
        />
        {error === "invalid email" ? (
          <p className="colorRed fontSize14">
            Please use this format:{" "}
            <span className="bold">yourname@company.com</span>
          </p>
        ) : null}
        <TextField
          className={classes.margin}
          id="temp-password-TextField"
          name="temppassword"
          fullWidth
          label="Temporary Password"
          onChange={handleChange}
        />
        <p className="fontSize14 colorGrey3 marginTop0">
          Check your email for your temporary password or{" "}
          <span
            className="TypographyDecorationUnderline colorBlue0"
            onClick={() => {
              if (!userData.email && errorUserModal.log === "") {
                setErrorUserModal({ ...errorUserModal, open: true });
              } else if (errorUserModal.log === "User does not exist.") {
                setContactResourceFullModal(true);
              } else {
                setTempPasswordModal(true);
              }
            }}
          >
            click resend
          </span>
          .
        </p>
        <TextField
          className={classes.margin}
          id="new-password-TextField"
          name="password"
          type={userData.showPassword ? "Typography" : "password"}
          fullWidth
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordError.includes("invalid password") && <ErrorOutline />}
                <IconButton
                  className="icon-eye-login"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {userData.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
        />
        {passwordError.includes("initial state") &&
        passwordError.length === 1 ? (
          <p className="colorGrey3 fontSize14 marginTop0">
            {passwordHelpTypography}
          </p>
        ) : (
          passwordError.includes("invalid password") && (
            <p className="colorRed fontSize14 marginTop0">
              {passwordHelpTypography}
            </p>
          )
        )}
        <TextField
          className={classes.margin}
          id="confirm-password-TextField"
          name="confirmpassword"
          fullWidth
          type={userData.showConfirmPassword ? "Typography" : "password"}
          label="Confirm Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className="icon-eye-login"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {userData.showConfirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
        />
        <div className="flexRow">
          <FormControlLabel
            control={
              <Checkbox
                checked={userData.terms_of_service ? true : false}
                onChange={handleChange}
                name="terms_of_service"
                color="primary"
              />
            }
          />
          <p className="marginTop5 marginLeftMinus5 colorGrey3">
            I agree to the{" "}
            <span
              className="colorBlue0 TypographyDecorationUnderline"
              onClick={() => setTermsModal(true)}
            >
              terms of service
            </span>
            .
          </p>
        </div>
        <Button
          className={classes.primaryButton}
          onClick={handleSubmit}
          disabled={
            userData.email &&
            !error &&
            userData.temppassword &&
            userData.password &&
            userData.password.toString().length >= 8 &&
            passwordError.includes("valid password") &&
            userData.confirmpassword &&
            userData.confirmpassword.toString().length >= 8 &&
            userData.terms_of_service &&
            !loading
              ? false
              : true
          }
        >
          {loading ? "Loading.." : "Sign Up"}
        </Button>
      </div>
      <p>
        Already a ResourceFull User? <Link to="/">Sign in here</Link>
      </p>
      {/* modal shows when user clicks resend temporary password link and the user does not exist in Cognito so can never receive a temporary password link */}
      <Modal
        open={contactResourceFullModal}
        onClose={() => {
          setContactResourceFullModal(false);
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box sx={style} className={size[0] < 480 ? "width75vw" : "width400"}>
          <Typography className="modal-heading" variant="h6" component="h2">
            Contact ResourceFull
          </Typography>
          <Typography className="modal-Typography">
            You don't have an assigned organization, please contact{" "}
            <a href={"mailto:resourcefull@anniecannons.com"}>
              resourcefull@anniecannons.com
            </a>
            .
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last TypographyDecorationUnderline"
            onClick={() => {
              setContactResourceFullModal(false);
            }}
          >
            OK
          </Typography>
        </Box>
      </Modal>
      {/* modal shows when email input field is blank and user clicks to resend code */}
      <Modal
        open={errorUserModal.open}
        onClose={() => {
          // console.log(errorUserModal);
          setErrorUserModal({ ...errorUserModal, open: false });
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box
          sx={style}
          className={props.windowSize[0] < 480 ? "width75vw" : "width400"}
        >
          <Typography className="modal-Typography">
            Please enter a valid email:{" "}
            <span className="bold">yourname@company.com</span>
          </Typography>
          <Typography
            className="modal-button-stacked modal-button-last TypographyDecorationUnderline"
            onClick={() => {
              setErrorUserModal({ ...errorUserModal, open: false });
            }}
          >
            OK
          </Typography>
        </Box>
      </Modal>
      {/* modal shows when user clicks resend temporary password link */}
      <Modal
        open={tempPasswordModal}
        onClose={() => {
          setTempPasswordModal(false);
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box sx={style} className={size[0] < 480 ? "width75vw" : "width400"}>
          <Typography className="modal-heading" variant="h6" component="h2">
            Resend Temporary Password
          </Typography>
          <Typography className="modal-text">
            We need to email you a new temporary password to set up your
            account. The password will expire in 7 days.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-first TypographyDecorationUnderline"
            onClick={() => {
              setTempPasswordModal(false);
              handleSendCodeClick();
            }}
          >
            Send Password
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last TypographyDecorationUnderline"
            onClick={() => {
              setTempPasswordModal(false);
              setLoading(false);
            }}
          >
            Cancel
          </Typography>
        </Box>
      </Modal>
      {/* Error Modal */}
      <Modal
        open={errorModal.open}
        onClose={() => {
          // console.log(errorModal);
          setErrorModal({ ...errorModal, open: false });
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box
          sx={style}
          className={props.windowSize[0] < 480 ? "width75vw" : "width400"}
        >
          <Typography className="modal-Typography">
            {errorModal.message}
          </Typography>
          <Typography
            className="modal-button-stacked modal-button-last TypographyDecorationUnderline"
            onClick={() => {
              setErrorModal({ ...errorModal, open: false });
            }}
          >
            OK
          </Typography>
        </Box>
      </Modal>
      {/* Terms of Service Modal */}
      <Modal
        open={termsModal}
        onClose={() => {
          setTermsModal(false);
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box
          sx={style}
          className={
            props.windowSize[0] < 480
              ? "width75vw overflowScroll maxHeight85vh"
              : "width400 overflowScroll maxHeight55vh"
          }
        >
          <Typography className="modal-Typography fontSize18">
            TERMS OF SERVICE; TERMS OF USE
          </Typography>
          <Typography className="marginBottom10">
            Effective Date: June 13, 2023
          </Typography>
          <Typography className="marginBottom10">
            PLEASE READ THESE TERMS OF USE (“TERMS”) CAREFULLY BEFORE ACCESSING
            OR USING RESOURCEFULL (THE “SERVICES”), an application that helps
            survivors of trafficking find and connect with organizations that
            provide services to survivors. This application is provided by
            AnnieCannons (“we,” “our,” or “us”). By accessing or using the
            Services, you agree to be bound by these Terms, which are a legal
            agreement between us and you.
          </Typography>
          <Typography className="marginBottom10">
            THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES, DISCLAIMERS OF
            LIABILITY, AND A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER
            THAT AFFECT YOUR RIGHTS FOR RESOLVING ANY DISPUTE WITH US, AS WELL
            AS AN INDEMNIFICATION PROVISION THAT MAKES YOU RESPONSIBLE FOR
            CERTAIN LOSSES. PLEASE READ THEM CAREFULLY.
          </Typography>
          <Typography className="marginBottom10 bold">THE TERMS</Typography>
          <Typography className="marginBottom10">
            We reserve the right to make changes to these Terms at any time, in
            accordance with applicable law. If we make changes to these Terms,
            such amended Terms will be posted on the Services or through some
            other reasonable method, and such amended Terms will take effect
            immediately when they are posted. If you do not accept a change,
            then you are free to stop using the Services. Your use of the
            Services following a change to these Terms shall constitute your
            acceptance of that change. Certain provisions of these Terms may be
            supplemented or superseded by expressly designated legal notices or
            terms located on particular pages within the Terms, and we will use
            reasonable efforts to make you aware of such legal notices and/or
            terms. Any headings used in these Terms, or any additional FAQs we
            provide, are for reference purposes only and are not intended to
            change the meaning of the Terms.
          </Typography>
          <Typography className="marginBottom10 bold">REGISTRATION</Typography>
          <Typography className="marginBottom10">
            You must register and create an account in order to use the
            Services. When creating your account, you must provide true,
            accurate, and current information about yourself and maintain and
            promptly update this information. If you provide any information
            that is untrue, inaccurate, or outdated, or if we have reasonable
            grounds to suspect that the information is untrue, inaccurate, or
            outdated, we have the right to suspend or terminate your account and
            access to the Services.
          </Typography>
          <Typography className="marginBottom10">
            During the registration process, you will be asked to provide an
            email address and a password. You are responsible for maintaining
            the confidentiality of your password, and are solely responsible for
            all activities that occur with your account. In order to protect
            yourself and your information, you should create a strong password
            that others will not know. You must immediately notify us of any
            unauthorized use of your account or any other breach of security.
            You are responsible for obtaining access to the Services, which
            access may involve third party fees (such as Internet service
            provider charges). In addition, you must provide and are responsible
            for all equipment necessary to access the Services.
          </Typography>
          <Typography className="marginBottom10 bold">
            YOUR USE OF THE SERVICES
          </Typography>
          <Typography className="marginBottom10">
            You may not access or use, or attempt to access or use, the Services
            to take any action that could harm us or any third party, interfere
            with the operation of the Services, or use the Services in a manner
            that violates any laws. For example, and without limitation, you may
            not:
          </Typography>
          <ul>
            {[
              "surveil, monitor, track, harass, any other user of the Services or attempt to do any of the foregoing;",
              "impersonate any person or entity or otherwise misrepresent your affiliation or the origin of any materials you transmit;",
              "engage in unauthorized spidering, “scraping,” or harvesting of content or personal information, or use any other unauthorized automated means to compile information;",
              "take any action that imposes an unreasonable or disproportionately large load on our network or infrastructure;",
              "use any device, software, or routine to interfere or attempt to interfere with the proper working of the Services or any activity conducted on the Services or attempt to probe, scan, test the vulnerability of, or breach the security of any system or network;",
              "attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of the Services;",
              "publish, reproduce, distribute, display, perform, edit, adapt, modify, or otherwise exploit any part of the Services without our written consent; or",
              "engage in any other conduct that restricts or inhibits any person from using or enjoying the Services, or that, in our sole judgment, exposes us or any of our users, affiliates, or any other third party to any liability, damages, or detriment of any type.",
            ].map((item, key) => (
              <Typography key={key}>{`\u2022 ${item}`}</Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            Violations of system or network security may result in civil or
            criminal liability. We may investigate and work with law enforcement
            authorities to prosecute users who violate the Terms. We may suspend
            or terminate your access to the Services for any or no reason at any
            time without notice.
          </Typography>
          <Typography className="marginBottom10 bold">
            LICENSE AND INTELLECTUAL PROPERTY
          </Typography>
          <Typography className="marginBottom10">
            As between you and us, except with respect to the User Content
            (defined below), we own the Services, including, without limitation,
            all Typography, graphics, artwork, layout, interface, logos, links,
            photographs, audio files, video, still images, and other content or
            material provided in or through use of the Services (all of which
            form part of and are collectively referred to as the “Services” in
            these Terms), and all worldwide intellectual property rights in the
            foregoing.
          </Typography>
          <Typography className="marginBottom10">
            We grant you a limited, personal, revocable, non-transferable,
            non-sublicensable, and non-exclusive right and license to access and
            use the Services for your intended use only and subject to any other
            restrictions, as provided through the Services. The Services may
            only be used for bona fide non-commercial purposes except with the
            prior authorization of AnnieCannons. Except as expressly permitted
            in these Terms, you must not, nor enable any other person to, rent,
            lease, lend, sell, redistribute, sublicense, copy, reverse engineer,
            decompile, translate, modify, rent, use as a service bureau, lease,
            sublicense, distribute copies of, adapt, create derivative works
            based on, or otherwise use the Services.
          </Typography>
          <Typography className="marginBottom10">
            We, in our sole discretion, may make available updates, and/or
            future versions of or to the Services, and such updates and/or
            future versions may not include all previous or existing features,
            functionality or components of the Services. These Terms will govern
            the use of such updates and future versions.
          </Typography>
          <Typography className="marginBottom10">
            Any trademark, service mark, logo or trade name contained in the
            Services, whether or not appearing in large print or with the
            trademark symbol, belongs exclusively to us or our licensors, and
            you may not use or display such trademarks without our permission.
            Nothing in these Terms grants you any right to use any trademark,
            service mark, logo or trade name of ours or any third party.
          </Typography>
          <Typography className="marginBottom10">
            You may have opportunities to upload or submit to the Services
            profile, organizational, or other information or content (“User
            Content”). You agree not to provide User Content that: infringes or
            misappropriates any third party intellectual property right;
            degrades others on the basis of gender, race, class, ethnicity,
            national origin, religion, sexual preference, accommodations or
            other classification; is unlawful, defamatory, libelous, inaccurate,
            deceiving, or that could reasonably be considered objectionable,
            profane, obscene, sexually explicit, indecent, pornographic,
            harassing, abusive threatening, embarrassing, hateful, intended to
            intimidate or incite violence, or otherwise inappropriate; promotes
            illegal activity, or advocates, promotes, or assists in any unlawful
            act; impersonates any person, or misrepresents your identity or
            affiliation with any person or organization; gives the impression
            that it comes from or is endorsed by AnnieCannons or any other
            entity or third party, if that is not the case. As between you and
            us, you own and are responsible for the User Content, including
            without limitation for ensuring that you have all rights, consents,
            and authorizations to upload or submit to the Services the User
            Content and to grant the rights to AnnieCannons set forth in this
            paragraph. By providing this User Content, you agree to and hereby
            grant to AnnieCannons and its affiliates, agents, and contractors an
            irrevocable, perpetual, royalty-free, fully sublicensable, fully
            paid up, worldwide license to use, copy, and distribute such
            information for purposes of connecting users with Survivor Services
            and otherwise operating and improving the Services, including, in
            the case of individual users, to provide such information to
            Survivor Services (defined below) that you designate for the
            purposes of evaluating your eligibility for and providing you the
            requested services.
          </Typography>
          <Typography className="marginBottom10">
            AnnieCannons encourages you to report material or content that you
            believe warrants removal from the Services. In particular, if you
            believe that any material on the Services infringes your work or the
            work of any third party in a manner that constitutes infringement,
            then you may notify AnnieCannons in accordance with this section. To
            notify AnnieCannons of copyright infringement on the Services,
            please send AnnieCannons a written notice by email to the address or
            email below that includes all of the following information:
          </Typography>
          <ul>
            {[
              "identification of the work you believe is being infringed;",
              "identification of the work you believe is infringing, with information that is reasonably sufficient for AnnieCannons to locate it;",
              "your name, address, phone number and email address;",
              "a statement that you have a good faith belief that use of the work in the manner complained of is not authorized by the copyright owner, its agent or the law;",
              "a statement that the information in your notification is accurate, and under penalty of perjury, you are the copyright owner or are authorized to act on behalf of the owner of an exclusive right that has allegedly been infringed; and",
              "a physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right that has allegedly been infringed.",
            ].map((item, key) => (
              <Typography key={key}>{`\u2022 ${item}`}</Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            AnnieCannons Contact: info@anniecannons.com
          </Typography>
          <Typography className="marginBottom10">
            Please note that if you knowingly give false, misleading, or
            inaccurate information regarding the existence of infringing
            content, we may suspend your account, and you may face other legal
            consequences.
          </Typography>
          <Typography className="marginBottom10">
            AnnieCannons will treat notices that meet these requirements in
            accordance with the U.S. Digital Millennium Copyright Act of 1998
            (“DMCA”). If AnnieCannons has taken down any material or content
            that you believe should not have been removed, you can send
            AnnieCannons a written counter-notice by email to
            info@anniecannons.com. Please be sure your counter-notice meets the
            DMCA requirements and AnnieCannons will follow the process set out
            in the DMCA. You may find out more about the DMCA at
            http://copyright.gov.
          </Typography>
          <Typography className="marginBottom10">
            AnnieCannons will promptly terminate without notice the accounts of
            users who have been notified of infringing activity or have had User
            Content removed from the Services at least three times (“Repeat
            Infringers”). Repeat Infringers will not be tolerated on the
            Services.
          </Typography>
          <Typography className="marginBottom10 bold">
            INDEPENDENT SERVICE PROVIDERS
          </Typography>
          <Typography className="marginBottom10">
            The Services contain information about independent organizations
            that work with survivors of human trafficking (“Survivor Services”).
            AnnieCannons disclaims any liability for your interactions with and
            reliance on the Survivor Services. AnnieCannons is not a referral
            service, and we do not refer, recommend, endorse, sponsor, or
            otherwise accept responsibility for any particular organization or
            any information or content provided by the Survivor Services. If you
            interact with a Survivor Service and/or rely on any information or
            content accessible through the Services, you do so solely at your
            own risk. AnnieCannons takes no responsibility for the conduct of
            Survivor Services or other third parties.
          </Typography>
          <Typography className="marginBottom10 bold">
            Disclaimer of Warranties; Limitation of Liability
          </Typography>
          <Typography className="marginBottom10">
            YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE
            PROVIDED “AS IS” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
            IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR OTHER
            VIOLATION OF RIGHTS. WE DO NOT WARRANT THE ADEQUACY, CURRENCY,
            ACCURACY, LIKELY RESULTS, OR COMPLETENESS OF THE SERVICES OR THAT
            THE FUNCTIONS PROVIDED WILL BE UNINTERRUPTED, VIRUS, OR ERROR-FREE.
            WE EXPRESSLY DISCLAIM ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN
            THE CONTENT INCLUDED IN THE SERVICES. SOME JURISDICTIONS MAY NOT
            ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE
            EXCLUSIONS MAY NOT APPLY TO YOU.
          </Typography>
          <Typography className="marginBottom10">
            IN NO EVENT WILL WE, OR OUR ASSIGNS, BE LIABLE FOR ANY DIRECT OR
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
            LOST PROFITS, OR OTHER DAMAGES WHATSOEVER ARISING IN CONNECTION WITH
            (1) THE USE OF THE SERVICES, (2) ANY INTERRUPTION IN AVAILABILITY OF
            THE SERVICES OR DELAY IN OPERATION OR TRANSMISSION, (3) ANY COMPUTER
            VIRUS, LOSS OF DATA, OR USE, MISUSE, RELIANCE, REVIEW, MANIPULATION,
            OR OTHER UTILIZATION IN ANY MANNER WHATSOEVER OF THE SERVICES OR THE
            DATA COLLECTED THROUGH THE SERVICES; IN EACH CASE, EVEN IF ONE OR
            MORE OF THEM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR
            LOSS.
          </Typography>
          <Typography className="marginBottom10 bold">
            Indemnification
          </Typography>
          <Typography className="marginBottom10">
            YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD US AND OUR ASSIGNS HARMLESS
            FROM AND AGAINST ANY AND ALL LOSS, COSTS, EXPENSES (INCLUDING
            REASONABLE ATTORNEYS’ FEES AND EXPENSES), CLAIMS, DAMAGES, AND
            LIABILITIES RELATED TO OR ASSOCIATED WITH YOUR USE OF THE SERVICES
            AND ANY ALLEGED VIOLATION BY YOU OF THESE TERMS. WE RESERVE THE
            RIGHT TO ASSUME THE EXCLUSIVE DEFENSE OF ANY CLAIM FOR WHICH WE ARE
            ENTITLED TO INDEMNIFICATION UNDER THIS SECTION. IN SUCH EVENT, YOU
            SHALL PROVIDE US WITH SUCH COOPERATION AS WE REASONABLY REQUEST.
          </Typography>
          <Typography className="marginBottom10 bold">
            GOVERNING LAW; MANDATORY OBLIGATION
          </Typography>
          <Typography className="marginBottom10">
            PLEASE READ THIS SECTION CAREFULLY BECAUSE IT AFFECTS YOUR RIGHTS.
            BY AGREEING TO MANDATORY, BINDING ARBITRATION, YOU WAIVE YOUR RIGHT
            TO LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY
            DECIDE YOUR CASE.
          </Typography>
          <Typography className="marginBottom10">
            These Terms shall be governed by, and construed in accordance with,
            the laws of the United States and the State of California, without
            regards to the conflicts of law principles thereof. If any clause or
            provision of these Terms is found to be invalid by any court having
            competent jurisdiction, the invalidity of such clause or provision
            shall not affect the validity of the remaining clauses or provisions
            of these Terms, including but not limited to the class action
            waiver. You expressly agree that if you dispute the validity or
            enforceability of the individual arbitration provision and/or class
            action waiver in these Terms, you shall bring such dispute in the
            courts of the State of California, and you expressly consent and
            agree to submit to the exclusive personal jurisdiction and venue of
            such courts.
          </Typography>
          <Typography className="marginBottom10">
            All claims arising in connection with your use of the Services
            should be reported and brought to our attention as soon as possible
            in a written statement delivered to info@anniecannons.com. To the
            maximum extent permitted by law, you permanently and irrevocably
            waive your rights to bring any claim in connection with your use of
            the Services unless you bring it within one (1) year of the date of
            the event giving rise to such claim.
          </Typography>
          <Typography className="marginBottom10">
            EXCEPT FOR DISPUTES THAT QUALIFY TO BE HEARD IN SMALL CLAIMS COURT,
            TO THE FULLEST EXTENT PERMITTED BY LAW, ALL DISPUTES ARISING OUT OF
            OR RELATED TO THESE TERMS OR ANY ASPECT OF THE RELATIONSHIP BETWEEN
            YOU AND ANNIECANNONS WHETHER BASED IN CONTRACT, TORT, STATUTE,
            FRAUD, MISREPRESENTATION, OR ANY OTHER THEORY, WILL BE RESOLVED
            THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR
            INSTEAD OF IN A COURT BY A JUDGE OR JURY. THIS INCLUDES DISPUTES
            ARISING OUT OF OR RELATING TO THE INTERPRETATION OR APPLICATION OF
            THIS MANDATORY OBLIGATION SECTION, INCLUDING ITS SCOPE,
            ENFORCEABILITY, REVOCABILITY, OR VALIDITY.
          </Typography>
          <Typography className="marginBottom10">
            YOU AGREE THAT ANNIECANNONS AND YOU ARE EACH WAIVING THE RIGHT TO
            TRIAL BY A JURY. YOU FURTHER AGREE THAT ANY ARBITRATION WILL TAKE
            PLACE ON AN INDIVIDUAL BASIS ONLY AND THAT CLASS ARBITRATIONS, CLASS
            ACTIONS AND OTHER REPRESENTATIVE ACTIONS ARE NOT PERMITTED. YOU
            HEREBY AGREE TO GIVE UP THE ABILITY TO BRING OR PARTICIPATE IN A
            CLASS ACTION OR OTHER REPRESENTATIVE ACTION IN COURT OR ARBITRATION.
          </Typography>
          <Typography className="marginBottom10">
            The arbitration will be administered by the American Arbitration
            Association (the “AAA”) under the Consumer Arbitration Rules (the
            “Rules”) (available from the AAA at www.adr.org), as amended by
            these Terms. The arbitrator will conduct hearings, if any, by
            teleconference or videoconference, rather than by personal
            appearances, unless the arbitrator determines upon request by you or
            by us that an in-person hearing is appropriate. Any in-person
            appearances will be held at a location that is reasonably convenient
            to the parties with due consideration of their ability to travel and
            other pertinent circumstances. If the parties are unable to agree on
            a location, such determination will be made by the AAA or by the
            arbitrator. If you are able to demonstrate that the costs of
            arbitration will be prohibitive as compared to the costs of
            litigation, we will, to the extent required to make this Mandatory
            Obligation section enforceable, pay as much of your filing and
            hearing fees in connection with the arbitration as the arbitrator
            deems necessary to prevent the arbitration from being
            cost-prohibitive. The arbitrator will be bound by these Terms, when
            not in conflict with applicable law, and the arbitrator’s decision
            will be binding and final, subject only to limited right of judicial
            review provided under the Federal Arbitration Act. The arbitrator
            will have authority to award temporary, interim or permanent
            injunctive relief or relief providing for specific performance of
            these Terms, but only to the extent necessary to provide relief
            warranted by the individual claim before the arbitrator. If the
            arbitrator decides that either the substance of your claim or the
            remedy you asked for is frivolous or brought for an improper
            purpose, we may use the applicable Rules to determine whether you
            are responsible for the filing, administrative and arbitrator fees.
            The award rendered by the arbitrator may be confirmed and enforced
            in any court having jurisdiction thereof. Notwithstanding any of the
            foregoing, nothing in these Terms will preclude you from bringing
            issues to the attention of federal, state or local government
            agencies or from seeking public injunctive relief in court where
            that right cannot be waived under applicable state law. You and we
            agree that litigation of any such claim or request for public
            injunctive relief shall be stayed pending the resolution of any
            individual claim(s) or request(s) for relief in arbitration.
          </Typography>
          <Typography className="marginBottom10">
            You can decline this agreement to arbitrate by contacting us at
            info@anniecannons.com within 30 days of first registering your
            account and stating your desire to opt-out. However, if you agreed
            to a previous version of these Terms that allowed you to opt out of
            arbitration, your previous choice to opt out or not opt out remains
            binding.
          </Typography>
          <Typography className="marginBottom10">
            If any part of this Mandatory Obligation section is found to be
            illegal or unenforceable, the remainder will remain in effect,
            except that if a finding of partial illegality or unenforceability
            would allow class or representative arbitration, this Mandatory
            Obligation section will be unenforceable in its entirety.
          </Typography>
          <Typography className="marginBottom10 bold">Miscellaneous</Typography>
          <Typography className="marginBottom10">
            These Terms constitute the entire agreement between you and us,
            superseding any prior or contemporaneous communications and
            proposals (whether oral, written or electronic) between you and us.
            In the event any provision of these Terms is held unenforceable, it
            will not affect the validity or enforceability of the remaining
            provisions and will be replaced by an enforceable provision that
            comes closest to the intention underlying the unenforceable
            provision. You agree that no joint venture, partnership, employment,
            or agency relationship exists between you and us as a result of
            these Terms or your access to and use of the Services.
          </Typography>
          <Typography className="marginBottom10">
            Our failure to enforce any provisions of these Terms or respond to a
            violation by any party does not waive our right to subsequently
            enforce any terms or conditions of the Terms or respond to any
            violations. Nothing contained in these Terms is in derogation of our
            right to comply with governmental, court, and law enforcement
            requests or requirements relating to your use of the Services or
            information provided to or gathered by us with respect to such use.
          </Typography>
          <Typography className="marginBottom10 bold">
            Privacy Policy
          </Typography>
          <Typography className="marginBottom10">
            Effect Date: May 15, 2022
          </Typography>
          <Typography className="marginBottom10">
            Welcome to ResourceFull!
          </Typography>
          <Typography className="marginBottom10">
            AnnieCannons (“us”, “we”, or “our”) operates ResourceFull, a free
            application that helps survivors of human trafficking find and
            connect with organizations that provide services to survivors. We
            refer to the ResourceFull app in this Policy as the “Services.”
          </Typography>
          <Typography className="marginBottom10">
            We are committed to protecting the privacy and security of the
            information we collect and to being transparent about the ways in
            which we collect and process your information. This statement (the
            “Privacy Policy”) sets forth our policies and practices for handling
            the information we collect from or about you when you use the
            Services. Any headings used in this Policy, or any additional FAQs
            we provide, are for reference purposes only and are not intended to
            change the meaning of the Policy.
          </Typography>
          <Typography className="marginBottom10 bold">
            WHAT INFORMATION DO WE COLLECT?
          </Typography>
          <Typography className="marginBottom10">
            When you use the Services, information may be collected about you
            and your use of the Services in a variety of ways.
          </Typography>
          <Typography className="marginBottom10">
            First, we collect information that you provide us directly or that
            we collect through your engagement with the Services, such as:
          </Typography>
          <ul>
            {[
              {
                bold: "Login information",
                text: "such as password, and email address.",
              },
              {
                bold: "Profile information",
                text: "that you optionally provide, such as name, date of birth, location, and phone number.",
              },
              {
                bold: "Intake information,",
                text: "such as gender, survivor status, housing status, and other information that you use the Services to provide to Survivor Services (defined below). This may include sensitive information, including Social Security number, depending on the Survivor Service.",
              },
              {
                bold: "Status,",
                text: "such as if you have a pending application with or are receiving services from a Survivor Service.",
              },
              {
                bold: "Feedback and comments",
                text: "you provide to us regarding the Services and other information that you provide us.",
              },
            ].map((item, key) => (
              <Typography key={key}>
                {`\u2022`} <span className="bold">{item.bold} </span>
                {item.text}
              </Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            Most of this information is optional. In order to access the
            Services, the only information you need to provide to us is your
            password and email address.
          </Typography>
          <Typography className="marginBottom10">
            If you are employed by or associated with an organization that uses
            the Services to connect with survivors (“Survivor Services”), then
            we may collect, in addition to the information above, the following
            additional information:
          </Typography>
          <ul>
            {[
              {
                bold: "Business contact information,",
                Typography: "such as phone number and address.",
              },
              {
                bold: "Information about the organization,",
                Typography:
                  "such as hours, organization description, and services descriptions.",
              },
            ].map((item, key) => (
              <Typography key={key}>
                {`\u2022`} <span className="bold">{item.bold} </span>
                {item.Typography}
              </Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            We also collect some information automatically, including through
            cookies and similar tracking technologies, when users use the
            Services. For example, we track information on browser type and
            operating system, IP address and/or other device IDs, and time of
            access/viewing/downloading.
          </Typography>
          <Typography className="marginBottom10">
            We may aggregate or de-identify the information described above.
            Aggregated or de-identified data is not subject to this Privacy
            Policy.
          </Typography>
          <Typography className="marginBottom10 bold">
            HOW DO WE USE AND DISCLOSE THE INFORMATION WE COLLECT?
          </Typography>
          <Typography className="marginBottom10">
            AnnieCannons uses the collected information to provide the services
            you request, including:
          </Typography>
          <ul>
            {[
              "To provide, maintain, and improve the Services, including to connect survivors with Survivor Services that may be available to them and to allow you to participate in interactive features of the Services when you choose to do so.",
              "To monitor use of the Services and detect, prevent, and address technical issues and engage in data analytics and troubleshooting.",
              "To assist users with use of the Services, including to respond to your questions.",
              "If you are employed by or associated with one or more Survivor Services, we may reach out with communications to help you engage with our community.",
              "To protect the Services and its users, including by helping to detect, prevent and respond to fraud, abuse, and security risks, and in connection with compliance and safety activities.",
              " For any other purpose with your consent.",
            ].map((item, key) => (
              <Typography key={key}>{`\u2022 ${item}`}</Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            AnnieCannons may disclose your information to third parties in
            certain circumstances, including in the following circumstances:
          </Typography>
          <ul>
            {[
              "We may share information about you with Survivor Services, with your consent or at your direction. You may apply to Survivor Services through the Services or by contacting the Survivor Services directly. Survivor Services that you apply to through the Services may later transfer your information to physical or digital internal documents.",
              "We rely on third-party vendors to perform a variety of contractual services on our behalf. To do so, we may need to share your information with them. For example, we may rely on vendors to store data on AnnieCannons’ behalf or enable us to perform any of the actions described above.",
              "We may share personal information to comply with legal obligations or respond to legal process, such as search warrants and subpoenas, or a request by a government entity if we believe there is an emergency involving danger of serious harm to you.",
              "We may disclose your information in the unlikely event we sell or transfer all or a portion of our assets to a third party, such as in connection with a merger or in the event of a bankruptcy.",
            ].map((item, key) => (
              <Typography key={key}>{`\u2022 ${item}`}</Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            COOKIES AND SIMILAR TECHNOLOGIES
          </Typography>
          <Typography className="marginBottom10">
            We use cookies and similar tracking technologies to analyze the
            activity on our Services and we hold certain information collected
            by these technologies.
          </Typography>
          <Typography className="marginBottom10">
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags, and scripts to collect and track
            information and to improve and analyze our Services.
          </Typography>
          <Typography className="marginBottom10">
            Examples of Cookies we use:
          </Typography>
          <ul>
            {[
              "Session Cookies: We use Session Cookies to operate our Services.",
              "Analytics Cookies: We use Analytics Cookies to analyze the performance of our Services.",
            ].map((item, key) => (
              <Typography key={key}>{`\u2022 ${item}`}</Typography>
            ))}
          </ul>
          <Typography className="marginBottom10 bold">
            HOW DO WE KEEP YOUR INFORMATION SECURE?
          </Typography>
          <Typography className="marginBottom10">
            While we take reasonable measures to maintain secure Services,
            electronic communications and databases are subject to errors,
            tampering, and breaches, and we cannot, and do not, guarantee or
            warrant that such events will not take place.
          </Typography>
          <Typography className="marginBottom10 bold">
            HOW LONG DO WE RETAIN PERSONAL INFORMATION?
          </Typography>
          <Typography className="marginBottom10">
            AnnieCannons maintains information you submit to AnnieCannons for as
            long as you remain a registered user of the Services. Thereafter, it
            is scheduled for deletion from our production environment except
            where we need to retain it for our legal or compliance purposes.
          </Typography>
          <Typography className="marginBottom10 bold">
            HOW DO WE HANDLE CHILDREN’S PERSONAL INFORMATION?
          </Typography>
          <Typography className="marginBottom10">
            Our Services are directed to a general audience comprised of adult
            users and teenagers who are 13 years old or older. Therefore, we do
            not intentionally collect personal information from children under
            the age of 13. If AnnieCannons receives notice that it has collected
            personal information of a child under the age of 13, AnnieCannons
            will delete such information unless AnnieCannons receives parental
            consent to retain such information.
          </Typography>
          <Typography className="marginBottom10 bold">
            HOW CAN YOU EXERCISE YOUR PRIVACY AND DATA PROTECTION RIGHTS?
          </Typography>
          <Typography className="marginBottom10">
            Depending on where you live, you may have certain rights around your
            personal data. Where available under local laws, the rights you may
            be able to exercise include:
          </Typography>
          <ul>
            {[
              {
                bold: "Right to Access.",
                Typography:
                  "You may request that AnnieCannons provide access to your personal information. Unless a legally binding exception applies, AnnieCannons will provide you with access to your personal information within a reasonable period of time.",
              },
              {
                bold: "Right to Correction.",
                Typography:
                  "You may request that AnnieCannons correct, update or supplement your personal information. Unless a legally binding exception applies, AnnieCannons will update your personal information within a reasonable period of time.",
              },
              {
                bold: "Right to Deletion.",
                Typography:
                  "You may request that we delete personal information we may hold about you.",
              },
            ].map((item, key) => (
              <Typography key={key}>
                {`\u2022`} <span className="bold">{item.bold} </span>
                {item.Typography}
              </Typography>
            ))}
          </ul>
          <Typography className="marginBottom10">
            These rights are not absolute. For example, even if AnnieCannons
            honors a request for erasure, to the extent permitted by law, such a
            request will not apply to AnnieCannons’ backup and/or disaster
            recovery systems, which will continue to be subject to deletion
            pursuant to AnnieCannons’ ordinary course backup and disaster
            recovery procedures.
          </Typography>
          <Typography className="marginBottom10">
            To make a request to exercise your data subject rights, you or your
            authorized representative should please contact AnnieCannons via any
            of the below means. We will seek to verify your identity in
            accordance with applicable laws, including by requesting appropriate
            identification.
          </Typography>
          <Typography className="marginBottom10">
            By email: info@anniecannons.com
          </Typography>
          <Typography className="marginBottom10">
            By visiting this page on our website: anniecannons.org/contact
          </Typography>
          <Typography className="marginBottom10 bold">
            WHAT HAPPENS IF THIS PRIVACY POLICY CHANGES?
          </Typography>
          <Typography className="marginBottom10">
            We may modify this Privacy Policy from time to time. We will inform
            users of changes to this Policy by posting on the Services or
            through some other reasonable method. We recommend that you review
            the Privacy Policy each time you visit the Services to stay informed
            of our privacy practices.
          </Typography>
          <Typography className="marginBottom10 bold">
            HOW MAY WE BE CONTACTED?
          </Typography>
          <Typography className="marginBottom10">
            If you have any questions about this Privacy Policy, please contact
            us:
          </Typography>
          <Typography>
            AnnieCannons, 1423 Broadway #1144, Oakland CA 94612
          </Typography>
          <Typography>Email: info@anniecannons.com</Typography>
          <Typography>Phone: 707-297-9190</Typography>
          <Typography>Webform: anniecannons.org/contact</Typography>
          <Typography
            className="modal-button-stacked modal-button-last colorBlue0"
            onClick={() => {
              setTermsModal(false);
            }}
          >
            OK
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
