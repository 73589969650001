import React, { Component } from "react";

import { Box, Checkbox, Divider, Modal, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@mui/material";
import Tippy from "@tippyjs/react";

import Snackbar from "@material-ui/core/Snackbar";
import greyCloseIcon from "../../Assets/greyCloseIcon.png";

import EditSavedSearchTitleModal from "./EditSavedSearchTitleModal";
import { SaveASearchModal } from "./SaveASearchModal";
import { SavedSearchesModal } from "./SavedSearchesModal";
import { AddressSearchBar } from "../../Components/AddressSearchBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export class ProviderTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exactAgeInput: null, //ternary operator only works for exact age TextField error
      ageRangeInput: [], //minimize code by using this.setState, which must run for age range TextField error to kick in
      showAgeRangeInputs: true,
      showAgeSave: false,
      location: [], //provider's physical location
      showLocationSave: false,
      locations: [], //locations served
      showIsNational: true,
      showLocationsSave: false,
      isAuthenticated: this.props.isAuthenticated,
      unAuthSaveClick: false,
      savedSearches: [],
      // showFilterSave: "", // in parent to set as "showServicesSave", "showLocationSave", "showGendersSave", "showMoreFiltersSave"
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleLocationChange = (addresses, index, stateName) => {
    let addressesCopy = [...addresses];
    let addressesFiltered = addressesCopy.filter((item) => item.checked);
    // index to distinguish between provider's physical address location vs locations served
    this.setState({
      [index]: addressesFiltered,
    });
    // stateName is for save button activation/deactivation
    if (addresses.length > 0) {
      this.setState({ [stateName]: true });
    } else {
      this.setState({ [stateName]: false });
    }
  };

  handleSaveSearchClick = () => {
    this.setState({ unAuthSaveClick: true });
  };

  saveAllSearches = (array) => {
    // console.log(array);
    this.setState({ savedSearches: array });
    // this.props.saveAllSearches(array);
  };

  render() {
    const { parentState } = this.props;
    const width = parentState.windowSize[0];
    // const isAuthenticated = parentState.isAuthenticated;
    // const width = parentState.windowSize.innerWidth;
    // const width = parentState.windowSize.width;
    // console.log(parentState);
    // console.log(this.state);
    // console.log("toolbar props: ", this.props);
    // console.log("parent age range", parentState.AgeRange);
    // console.log("parent exact age", parentState.ExactAge);
    // console.log("parentState", parentState);
    return (
      <>
        <div className="flexRow alignCenter flexWrap padding11-5-0">
          {/* Age Filter */}
          <div className="age filterContainer positionRelative">
            <Tippy
              interactive={true}
              visible={parentState.ageFilterPopoverOpen}
              className={"tippyTippy"}
              onClickOutside={() =>
                this.props.closeFilterOverlay("ageFilterPopoverOpen")
              }
              placement={"bottom"}
              content={
                <div className="filterPopovers">
                  {this.state.showAgeRangeInputs &&
                  parentState.ExactAge === null ? (
                    <div className="flexColumn alignFlexStart marginBottom16">
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#000000",
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      >
                        {" "}
                        Age Range:
                      </p>
                      <div className="flexRow ageRangeWrapper ageWrapper">
                        <TextField
                          name="AgeRangeMin"
                          className="ageTextField"
                          type="number"
                          style={{
                            width: "45%",
                            height: "10%",
                            borderRadius: "5px",
                            // more styles in App.css
                          }}
                          variant="outlined"
                          defaultValue={
                            parentState.AgeRange[0] === 0
                              ? 0
                              : parentState.AgeRange[0]
                              ? parentState.AgeRange[0]
                              : null
                          }
                          InputProps={{
                            inputProps: { min: "0", max: "119" },
                          }}
                          onChange={(e) => {
                            const ageValue = +e.target.value;
                            // console.log(ageValue);
                            const ageRangeInputCopy = [
                              ...this.state.ageRangeInput,
                            ];
                            ageRangeInputCopy[0] = ageValue;
                            this.setState({ ageRangeInput: ageRangeInputCopy });
                            parentState.AgeRange[0] = ageValue;
                            this.props.handleAgeChange(e);
                            if (
                              // handles both input changes
                              (ageRangeInputCopy[0] >= 0 &&
                                ageRangeInputCopy[0] <= 119 &&
                                ageRangeInputCopy[1] > 1 &&
                                ageRangeInputCopy[1] <= 120 &&
                                ageRangeInputCopy[0] < ageRangeInputCopy[1]) ||
                              // handles one input change compared to one parent state input saved
                              (ageRangeInputCopy[0] >= 0 &&
                                ageRangeInputCopy[0] <= 119 &&
                                parentState.AgeRange[1] > 1 &&
                                parentState.AgeRange[1] <= 120 &&
                                ageRangeInputCopy[0] < parentState.AgeRange[1])
                            ) {
                              this.setState({ showAgeSave: true });
                            } else {
                              this.setState({ showAgeSave: false });
                            }
                          }}
                          error={
                            parentState.AgeRange[0] >=
                              parentState.AgeRange[1] ||
                            parentState.AgeRange[0] < 0 ||
                            parentState.AgeRange[0] > 119
                          }
                          helperText={
                            parentState.AgeRange[0] > 119
                              ? "Must be 119 or less"
                              : parentState.AgeRange[0] >
                                  parentState.AgeRange[1] &&
                                parentState.AgeRange[0] !== 0
                              ? "Enter a lower number"
                              : parentState.AgeRange[0] ===
                                parentState.AgeRange[1]
                              ? "Ages cannot be equal"
                              : parentState.AgeRange[0] < 0
                              ? "Age must be 0+"
                              : null
                          }
                        />
                        <p className="marginBottom0 marginTop0">to</p>
                        <TextField
                          name="AgeRangeMax"
                          className="ageTextField"
                          type="number"
                          style={{
                            width: "45%",
                            height: "10%",
                            borderRadius: "5px",
                            // more styles in App.css
                          }}
                          InputProps={{
                            inputProps: { min: "1", max: "120" },
                          }}
                          defaultValue={
                            parentState.AgeRange[1] === 0
                              ? 0
                              : parentState.AgeRange[1]
                              ? parentState.AgeRange[1]
                              : null
                          }
                          onChange={(e) => {
                            const ageValue = +e.target.value;
                            // console.log(ageValue);
                            const ageRangeInputCopy = [
                              ...this.state.ageRangeInput,
                            ];
                            ageRangeInputCopy[1] = ageValue;
                            this.setState({ ageRangeInput: ageRangeInputCopy });
                            parentState.AgeRange[1] = ageValue;
                            this.props.handleAgeChange(e);
                            if (
                              // handles both input changes
                              (ageRangeInputCopy[0] >= 0 &&
                                ageRangeInputCopy[0] <= 119 &&
                                ageRangeInputCopy[1] > 1 &&
                                ageRangeInputCopy[1] <= 120 &&
                                ageRangeInputCopy[0] < ageRangeInputCopy[1]) ||
                              // handles one input change compared to one parent state input saved
                              (parentState.AgeRange[0] >= 0 &&
                                parentState.AgeRange[0] <= 119 &&
                                ageRangeInputCopy[1] > 1 &&
                                ageRangeInputCopy[1] <= 120 &&
                                parentState.AgeRange[0] < ageRangeInputCopy[1])
                            ) {
                              this.setState({ showAgeSave: true });
                            } else {
                              this.setState({ showAgeSave: false });
                            }
                          }}
                          error={
                            parentState.AgeRange[1] <=
                              parentState.AgeRange[0] ||
                            parentState.AgeRange[1] < 1 ||
                            parentState.AgeRange[1] > 120
                          }
                          helperText={
                            parentState.AgeRange[1] <= parentState.AgeRange[0]
                              ? "Enter a higher number"
                              : parentState.AgeRange[1] > 120
                              ? "Must be 120 or less"
                              : parentState.AgeRange[1] < 1
                              ? "Age must be 1+"
                              : null
                          }
                        />
                      </div>
                      <p
                        className="textDecorationUnderline colorBlue0"
                        onClick={(e) => {
                          parentState.AgeRange = [];
                          this.setState({ showAgeRangeInputs: false });
                        }}
                      >
                        or set exact age
                      </p>
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          name="Under18yearsold"
                          checked={parentState.Under18yearsold}
                          className={
                            parentState.Under18yearsold
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Under 18 years old"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="16to24yearsold"
                          checked={parentState["16to24yearsold"]}
                          className={
                            parentState["16to24yearsold"]
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="16 - 24 years old"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="18yearsoldandolder"
                          checked={parentState["18yearsoldandolder"]}
                          className={
                            parentState["18yearsoldandolder"]
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="18 years old and older"
                    /> */}
                    </div>
                  ) : (
                    // Allow user to search for a single exact age AND/OR select one or more of the age brackets in the check boxes.
                    // User should be able to enter a whole numbers from 0 to 120 in the input field only
                    // When exact age is selected, display organizations who’s age range includes this selected value.
                    <div className="ageWrapper">
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#000000",
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      >
                        {" "}
                        Exact Age:
                      </p>
                      {this.state.exactAgeInput === null ? (
                        <TextField
                          // label="Exact Age"
                          name="ExactAge"
                          className="ageTextField"
                          type="number"
                          style={{
                            width: "45%",
                            height: "10%",
                            // border: "1px solid #D5D8DD",
                            borderRadius: "5px",
                            // marginTop: "-10px",
                          }}
                          defaultValue={
                            parentState.ExactAge === 0
                              ? 0
                              : parentState.ExactAge
                              ? parentState.ExactAge
                              : null
                          }
                          // onChangeTextInput={this.props.onChangeTextInput}
                          onChange={
                            //   // this.props.handleAgeChange
                            //   // (e) => this.props.handleAgeChange(e)
                            (e) => {
                              const ageValue = +e.target.value;
                              // const ageValue = Math.max(
                              //   0,
                              //   Math.min(120, Number(e.target.value))
                              // );
                              // console.log(ageValue);
                              if (
                                ageValue >= 0 &&
                                ageValue <= 120 &&
                                ageValue !== null
                              ) {
                                parentState.AgeRange = [];
                                parentState.ExactAge = ageValue;
                                this.props.handleAgeChange(e);
                                this.setState({ showAgeSave: true });
                                this.setState({ exactAgeInput: null });
                              } else {
                                this.setState({ showAgeSave: false });
                                this.setState({ exactAgeInput: ageValue });
                              }
                              //     //   this.props.setState({
                              //     //     ...this.state,
                              //     //     ExactAge: ageValue,
                              //     //   });
                              //     //   console.log(this.props.ExactAge);
                              //     // if (
                              //     //   ageValue >= data from backend - each provider's provider_ages_served[0] &&
                              //     //   ageValue <= data from backend - each provider's provider_ages_served[1]
                              //     // ) {
                              //     //   console.log(ageValue);
                              //     // }
                              //     // if (ageValue >= 0 && ageValue < 16) {
                              //     //   parentState.Under18yearsold = true;
                              //     // } else if (ageValue >= 16 && ageValue < 18) {
                              //     //   parentState.Under18yearsold = true;
                              //     //   parentState["16to24yearsold"] = true;
                              //     // } else if (ageValue >= 18 && ageValue <= 24) {
                              //     //   parentState["16to24yearsold"] = true;
                              //     //   parentState["18yearsoldandolder"] = true;
                              //     // } else if (ageValue >= 25 && ageValue <= 120) {
                              //     //   parentState["18yearsoldandolder"] = true;
                              //     // }
                            }
                          }
                        />
                      ) : (
                        <TextField
                          error
                          // label="Exact Age"
                          name="ExactAge"
                          className="ageTextField"
                          type="number"
                          defaultValue={
                            parentState.ExactAge ? parentState.ExactAge : null
                          }
                          InputProps={{
                            inputProps: { min: "0", max: "120" },
                          }}
                          style={{
                            width: "45%",
                            height: "10%",
                            borderRadius: "5px",
                            // more styles in App.css
                          }}
                          helperText="Enter a number 0 to 120."
                          onChange={(e) => {
                            // const ageValue = Math.max(
                            //   0,
                            //   Math.min(120, Number(e.target.value))
                            // );
                            let ageValue = +e.target.value;
                            // console.log(ageValue);
                            if (ageValue >= 0 && ageValue <= 120) {
                              parentState.AgeRange = [];
                              parentState.ExactAge = ageValue;
                              this.props.handleAgeChange(e);
                              this.setState({ exactAgeInput: null });
                            } else {
                              this.setState({ exactAgeInput: ageValue });
                            }
                          }}
                        />
                      )}
                      <p
                        className="textDecorationUnderline colorBlue0 marginTop15 marginBottom30"
                        onClick={(e) => {
                          parentState.ExactAge = null;
                          this.setState({ showAgeRangeInputs: true });
                        }}
                      >
                        or set age range
                      </p>
                    </div>
                  )}

                  <div className="flexRow justifySpaceBetween marginTop15">
                    <button
                      className="fontSize16 lineHeight20 btnNoStyle btnNoFocus colorBlue1 underline"
                      onClick={() =>
                        this.props.clearOneSetOfFilters(
                          [
                            // "Under 18 years old",
                            // "16 - 24 years old",
                            // "18 years old and older",
                            "Age Range",
                            "Exact Age",
                          ],
                          "ageFilterPopoverOpen"
                        )
                      }
                    >
                      Clear
                    </button>
                    {this.state.showAgeSave ? (
                      <button
                        className="btnWithStyle btnMagenta0 fontSize14 lineHeight16 borderRadius4"
                        onClick={() => {
                          this.props.handleAgeSave(parentState);
                          this.props.closeFilterOverlay("ageFilterPopoverOpen");
                          this.props.updateCurrentFilters(
                            [
                              // "Under 18 years old",
                              // "16 - 24 years old",
                              // "18 years old and older",
                              "Age Range",
                              "Exact Age",
                            ],
                            "ageFilterPopoverOpen"
                          );
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        disabled
                        className="btnWithStyle btnMagenta0 opacity3 fontSize14 lineHeight16 borderRadius4"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              }
            >
              <Chip
                label="Age"
                aria-describedby={
                  Boolean(parentState.ageFilterPopoverOpen)
                    ? "simple-popover"
                    : undefined
                }
                // ref={}
                className={
                  parentState.ageFilterPopoverOpen
                    ? "border1Grey filterBtns open"
                    : parentState.AgeRange.length > 0 ||
                      parentState.ExactAge !== null
                    ? //   parentState.Under18yearsold ||
                      //   parentState["16to24yearsold"] ||
                      //   parentState["18yearsoldandolder"]
                      "border1Grey filterBtns active"
                    : "border1Grey filterBtns"
                }
                clickable={true}
                onClick={() =>
                  this.props.openFilterOverlay("ageFilterPopoverOpen")
                }
              />
            </Tippy>
          </div>

          <div className="filterContainer positionRelative services">
            <Tippy
              interactive={true}
              visible={parentState.servicesFilterPopoverOpen}
              onClickOutside={() =>
                this.props.closeFilterOverlay("servicesFilterPopoverOpen")
              }
              placement={"bottom"}
              content={
                <div className="filterPopovers services">
                  <div className="flexRow alignFlexStart marginBottom16">
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="HousingandShelter"
                            value="Housing and Shelter"
                            checked={parentState.HousingandShelter}
                            className={
                              parentState.HousingandShelter
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Housing and Shelter"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="WorkforceDevelopment"
                            value="Workforce Development"
                            checked={parentState.WorkforceDevelopment}
                            className={
                              parentState.WorkforceDevelopment
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Workforce Development"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="LegalAid"
                            value="Legal Aid"
                            checked={parentState.LegalAid}
                            className={
                              parentState.LegalAid
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Legal Aid"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="FinancialAssistance"
                            value="Financial Assistance"
                            checked={parentState.FinancialAssistance}
                            className={
                              parentState.FinancialAssistance
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Financial Assistance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="MentalHealth"
                            value="Mental Health"
                            checked={parentState.MentalHealth}
                            className={
                              parentState.MentalHealth
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Mental Health"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="CaseManagement"
                            value="Case Management"
                            checked={parentState.CaseManagement}
                            className={
                              parentState.CaseManagement
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Case Management"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Healthcare"
                            value="Healthcare"
                            checked={parentState.Healthcare}
                            className={
                              parentState.Healthcare
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Healthcare"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Hotlines"
                            value="24-Hour Hotlines"
                            checked={parentState.Hotlines}
                            className={
                              parentState.Hotlines
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="24-Hour Hotlines"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DropInCenter"
                            value="Drop-In Center"
                            checked={parentState.DropInCenter}
                            className={
                              parentState.DropInCenter
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Drop-in Center"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="SupportGroupServices"
                            value="Support Group Services"
                            checked={parentState.SupportGroupServices}
                            className={
                              parentState.SupportGroupServices
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Support Group Services"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Education"
                            value="Education"
                            checked={parentState.Education}
                            className={
                              parentState.Education
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Education"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="HealingArts"
                            value="Healing Arts"
                            checked={parentState.HealingArts}
                            className={
                              parentState.HealingArts
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Healing Arts"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="YouthDevelopmentServices"
                            value="Youth Development Services"
                            checked={parentState.YouthDevelopmentServices}
                            className={
                              parentState.YouthDevelopmentServices
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Youth Development Services"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="SubstanceOveruseServices"
                            value="Substance Overuse Services"
                            checked={parentState.SubstanceOveruseServices}
                            className={
                              parentState.SubstanceOveruseServices
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Substance Overuse Services"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="PublicBenefits"
                            value="Public Benefits"
                            checked={parentState.PublicBenefits}
                            className={
                              parentState.PublicBenefits
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Public Benefits"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="PeerSupport"
                            value="Peer Support"
                            checked={parentState.PeerSupport}
                            className={
                              parentState.PeerSupport
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Peer Support"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="BasicNeedsResource"
                            value="Basic Needs Resource"
                            checked={parentState.BasicNeedsResource}
                            className={
                              parentState.BasicNeedsResource
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Basic Needs Resource"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="CrisisIntervention"
                            value="Crisis Intervention"
                            checked={parentState.CrisisIntervention}
                            className={
                              parentState.CrisisIntervention
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Crisis Intervention"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Entrepreneurship"
                            value="Entrepreneurship"
                            checked={parentState.Entrepreneurship}
                            className={
                              parentState.Entrepreneurship
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Entrepreneurship"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="LeadershipDevelopment"
                            value="Leadership Development"
                            checked={parentState.LeadershipDevelopment}
                            className={
                              parentState.LeadershipDevelopment
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Leadership Development"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="FoodAccess"
                            value="Food Access"
                            checked={parentState.FoodAccess}
                            className={
                              parentState.FoodAccess
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Food Access"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ChildWelfareandChildProtection"
                            value="Child Welfare and Child Protection"
                            checked={parentState.ChildWelfareandChildProtection}
                            className={
                              parentState.ChildWelfareandChildProtection
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Child Welfare and Child Protection"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="TelephoneHotline"
                            value="Telephone Hotline"
                            checked={parentState.TelephoneHotline}
                            className={
                              parentState.TelephoneHotline
                                ? "checkbox checked"
                                : "checkbox"
                            }
                            onChange={this.props.handleCheckBoxChange}
                          />
                        }
                        label="Telephone Hotline"
                      />
                    </div>
                  </div>
                  <div className="flexRow justifySpaceBetween">
                    <button
                      className="fontSize16 lineHeight20 btnNoStyle btnNoFocus colorBlue1 underline"
                      onClick={() =>
                        this.props.clearOneSetOfFilters(
                          [
                            "Housing and Shelter",
                            "Workforce Development",
                            "Legal Aid",
                            "Financial Assistance",
                            "Mental Health",
                            "Case Management",
                            "Healthcare",
                            "24-Hour Hotlines",
                            "Drop-In Center",
                            "Support Group Services",
                            "Education",
                            "Healing Arts",
                            "Youth Development Services",
                            "Substance Overuse Services",
                            "Public Benefits",
                            "Peer Support",
                            "Basic Needs Resource",
                            "Crisis Intervention",
                            "Entrepreneurship",
                            "Leadership Development",
                            "Food Access",
                            "Child Welfare and Child Protection",
                            "Telephone Hotline",
                          ],
                          "servicesFilterPopoverOpen"
                        )
                      }
                    >
                      Clear
                    </button>
                    {parentState.showFilterSave === "showServicesSave" ? (
                      <button
                        className="btnWithStyle btnMagenta0 fontSize14 lineHeight16 borderRadius4"
                        onClick={() => {
                          this.props.handleCheckBoxSave();
                          this.props.closeFilterOverlay(
                            "servicesFilterPopoverOpen"
                          );
                          this.props.updateCurrentFilters(
                            [
                              "Housing and Shelter",
                              "Workforce Development",
                              "Legal Aid",
                              "Financial Assistance",
                              "Mental Health",
                              "Case Management",
                              "Healthcare",
                              "24-Hour Hotlines",
                              "Drop-In Center",
                              "Support Group Services",
                              "Education",
                              "Healing Arts",
                              "Youth Development Services",
                              "Substance Overuse Services",
                              "Public Benefits",
                              "Peer Support",
                              "Basic Needs Resource",
                              "Crisis Intervention",
                              "Entrepreneurship",
                              "Leadership Development",
                              "Food Access",
                              "Child Welfare and Child Protection",
                              "Telephone Hotline",
                            ],
                            "servicesFilterPopoverOpen"
                          );
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        disabled
                        className="btnWithStyle btnMagenta0 opacity3 fontSize14 lineHeight16 borderRadius4"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              }
            >
              <Chip
                label="Types of Services"
                aria-describedby={
                  Boolean(parentState.servicesFilterPopoverOpen)
                    ? "simple-popover"
                    : undefined
                }
                // ref={testRef}
                className={
                  parentState.servicesFilterPopoverOpen
                    ? "border1Grey filterBtns open"
                    : parentState.HousingandShelter ||
                      parentState.WorkforceDevelopment ||
                      parentState.LegalAid ||
                      parentState.FinancialAssistance ||
                      parentState.MentalHealth ||
                      parentState.CaseManagement ||
                      parentState.Healthcare ||
                      parentState.Hotlines ||
                      parentState.DropInCenter ||
                      parentState.SupportGroupServices ||
                      parentState.Education ||
                      parentState.HealingArts ||
                      parentState.YouthDevelopmentServices ||
                      parentState.SubstanceOveruseServices ||
                      parentState.PublicBenefits ||
                      parentState.PeerSupport ||
                      parentState.BasicNeedsResource ||
                      parentState.CrisisIntervention ||
                      parentState.Entrepreneurship ||
                      parentState.LeadershipDevelopment ||
                      parentState.FoodAccess ||
                      parentState.ChildWelfareandChildProtection ||
                      parentState.TelephoneHotline
                    ? "border1Grey filterBtns active"
                    : "border1Grey filterBtns"
                }
                clickable={true}
                onClick={() =>
                  this.props.openFilterOverlay("servicesFilterPopoverOpen")
                }
              />
            </Tippy>
          </div>

          {/* Location Filter - Provider's physical Location, NOT locations served */}
          <div className="filterContainer positionRelative locationFilterContainer">
            <Tippy
              interactive={true}
              visible={parentState.locationFilterPopoverOpen}
              onClickOutside={() =>
                this.props.closeFilterOverlay("locationFilterPopoverOpen")
              }
              placement={"bottom"}
              content={
                <div className="filterPopovers">
                  <div className="flexColumn alignFlexStart marginBottom16">
                    <p className="margin0">City, State:</p>
                    <AddressSearchBar
                      name="location_filter"
                      state="edit"
                      index="location"
                      parentData={
                        parentState.Location ? parentState.Location : []
                      }
                      updateParent={(addresses) =>
                        this.handleLocationChange(
                          addresses,
                          "location",
                          "showLocationSave"
                        )
                      }
                      placeholder="City, State"
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          name="IsNational"
                          value="Is National"
                          checked={parentState.IsNational}
                          className={
                            parentState.IsNational
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Nationwide only"
                    /> */}
                  </div>
                  <div className="flexRow justifySpaceBetween">
                    <button
                      className="fontSize16 lineHeight20 btnNoStyle btnNoFocus colorBlue1 underline"
                      onClick={() =>
                        // this.setState()
                        this.props.clearOneSetOfFilters(
                          ["Location"],
                          "locationFilterPopoverOpen"
                        )
                      }
                    >
                      Clear
                    </button>
                    {
                      // parentState.showFilterSave === "showLocationSave"
                      this.state.showLocationSave ? (
                        <button
                          className="btnWithStyle btnMagenta0 fontSize14 lineHeight16 borderRadius4"
                          onClick={() => {
                            // this.props.handleCheckBoxSave();
                            parentState.Location = this.state.location;
                            this.props.handleLocationSave(parentState);
                            this.props.closeFilterOverlay(
                              "locationFilterPopoverOpen"
                            );
                            this.props.updateCurrentFilters(
                              ["Location"],
                              "locationFilterPopoverOpen"
                            );
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          disabled
                          className="btnWithStyle btnMagenta0 opacity3 fontSize14 lineHeight16 borderRadius4"
                        >
                          Save
                        </button>
                      )
                    }
                  </div>
                </div>
              }
            >
              <Chip
                label="Location"
                aria-describedby={
                  Boolean(parentState.locationFilterPopoverOpen)
                    ? "simple-popover"
                    : undefined
                }
                // ref={testRef}
                className={
                  parentState.locationFilterPopoverOpen
                    ? "border1Grey filterBtns open"
                    : parentState.Location.length > 0
                    ? "border1Grey filterBtns active"
                    : "border1Grey filterBtns"
                }
                clickable={true}
                onClick={() =>
                  this.props.openFilterOverlay("locationFilterPopoverOpen")
                }
              />
            </Tippy>
          </div>

          {/* Genders Served Filter */}
          <div className="filterContainer positionRelative">
            <Tippy
              interactive={true}
              visible={parentState.gendersFilterPopoverOpen}
              onClickOutside={() =>
                this.props.closeFilterOverlay("gendersFilterPopoverOpen")
              }
              placement={"bottom"}
              content={
                <div className="filterPopovers">
                  <div className="flexColumn alignFlexStart marginBottom16">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Woman"
                          value="Woman"
                          checked={parentState.Woman}
                          className={
                            parentState.Woman ? "checkbox checked" : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Woman"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="TransWoman"
                          value="Trans Woman"
                          checked={parentState.TransWoman}
                          className={
                            parentState.TransWoman
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Trans Woman"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Man"
                          value="Man"
                          checked={parentState.Man}
                          className={
                            parentState.Man ? "checkbox checked" : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Man"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="TransMan"
                          value="Trans Man"
                          checked={parentState.TransMan}
                          className={
                            parentState.TransMan
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Trans Man"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="GenderQueer"
                          value="Gender Queer"
                          checked={parentState.GenderQueer}
                          className={
                            parentState.GenderQueer
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Gender Queer"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="GenderNonConforming"
                          value="Gender Non-Conforming"
                          checked={parentState.GenderNonConforming}
                          className={
                            parentState.GenderNonConforming
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Gender Non-Conforming"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="GenderNonBinary"
                          value="Gender Non-Binary"
                          checked={parentState.GenderNonBinary}
                          className={
                            parentState.GenderNonBinary
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Gender Non-Binary"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Agender"
                          value="Agender"
                          checked={parentState.Agender}
                          className={
                            parentState.Agender
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Agender"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="NoneOfThese"
                          value="None of these genders"
                          checked={parentState.NoneOfThese}
                          className={
                            parentState.NoneOfThese
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="None of these"
                    />
                  </div>
                  <div className="flexRow justifySpaceBetween">
                    <button
                      className="fontSize16 lineHeight20 btnNoStyle btnNoFocus colorBlue1 underline"
                      onClick={() =>
                        this.props.clearOneSetOfFilters(
                          [
                            "Woman",
                            "Trans Woman",
                            "Man",
                            "Trans Man",
                            "Gender Queer",
                            "Gender Non-Conforming",
                            "Gender Non-Binary",
                            "Agender",
                            "None of these",
                          ],
                          "gendersFilterPopoverOpen"
                        )
                      }
                    >
                      Clear
                    </button>
                    {parentState.showFilterSave === "showGendersSave" ? (
                      <button
                        className="btnWithStyle btnMagenta0 fontSize14 lineHeight16 borderRadius4"
                        onClick={() => {
                          this.props.handleCheckBoxSave();
                          this.props.closeFilterOverlay(
                            "gendersFilterPopoverOpen"
                          );
                          this.props.updateCurrentFilters(
                            [
                              "Woman",
                              "Trans Woman",
                              "Man",
                              "Trans Man",
                              "Gender Queer",
                              "Gender Non-Conforming",
                              "Gender Non-Binary",
                              "Agender",
                              "None of these",
                            ],
                            "gendersFilterPopoverOpen"
                          );
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        disabled
                        className="btnWithStyle btnMagenta0 opacity3 fontSize14 lineHeight16 borderRadius4"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              }
            >
              <Chip
                label="Genders Served"
                aria-describedby={
                  Boolean(parentState.gendersFilterPopoverOpen)
                    ? "simple-popover"
                    : undefined
                }
                // ref={testRef}
                className={
                  parentState.gendersFilterPopoverOpen
                    ? "border1Grey filterBtns open"
                    : parentState.Woman ||
                      parentState.TransWoman ||
                      parentState.Man ||
                      parentState.TransMan ||
                      parentState.GenderQueer ||
                      parentState.GenderNonConforming ||
                      parentState.GenderNonBinary ||
                      parentState.Agender ||
                      parentState.NoneOfThese
                    ? "border1Grey filterBtns active"
                    : "border1Grey filterBtns"
                }
                clickable={true}
                onClick={() =>
                  this.props.openFilterOverlay("gendersFilterPopoverOpen")
                }
              />
            </Tippy>
          </div>

          {/* Locations Served Filter */}
          <div className="filterContainer positionRelative locationFilterContainer">
            <Tippy
              interactive={true}
              visible={parentState.locationsServedFilterPopoverOpen}
              onClickOutside={() =>
                this.props.closeFilterOverlay(
                  "locationsServedFilterPopoverOpen"
                )
              }
              placement={"bottom"}
              content={
                <div className="filterPopovers">
                  <div className="flexColumn alignFlexStart marginBottom16">
                    {!parentState.IsNational && (
                      <>
                        <p className="margin0">City, State:</p>
                        <AddressSearchBar
                          name="location_filter"
                          state="edit"
                          index="locations"
                          parentData={
                            parentState.LocationsServed
                              ? parentState.LocationsServed
                              : []
                          }
                          updateParent={(addresses) =>
                            this.handleLocationChange(
                              addresses,
                              "locations",
                              "showLocationsSave"
                            )
                          }
                          placeholder="City, State"
                        />
                      </>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="IsNational"
                          value="Is National"
                          checked={parentState.IsNational}
                          className={
                            parentState.IsNational
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Nationwide only"
                    />
                  </div>
                  <div className="flexRow justifySpaceBetween">
                    <button
                      className="fontSize16 lineHeight20 btnNoStyle btnNoFocus colorBlue1 underline"
                      onClick={() =>
                        // this.setState()
                        this.props.clearOneSetOfFilters(
                          ["Locations Served", "Is National"],
                          "locationsServedFilterPopoverOpen"
                        )
                      }
                    >
                      Clear
                    </button>
                    {
                      // parentState.showFilterSave === "showLocationsServedSave"
                      this.state.showLocationsSave || parentState.IsNational ? (
                        <button
                          className="btnWithStyle btnMagenta0 fontSize14 lineHeight16 borderRadius4"
                          onClick={() => {
                            // this.props.handleCheckBoxSave();
                            parentState.LocationsServed = this.state.locations;
                            this.props.handleLocationSave(parentState);
                            this.props.closeFilterOverlay(
                              "locationsServedFilterPopoverOpen"
                            );
                            this.props.updateCurrentFilters(
                              ["Locations Served", "Is National"],
                              "locationsServedFilterPopoverOpen"
                            );
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          disabled
                          className="btnWithStyle btnMagenta0 opacity3 fontSize14 lineHeight16 borderRadius4"
                        >
                          Save
                        </button>
                      )
                    }
                  </div>
                </div>
              }
            >
              <Chip
                label="Locations Served"
                aria-describedby={
                  Boolean(parentState.locationsServedFilterPopoverOpen)
                    ? "simple-popover"
                    : undefined
                }
                // ref={testRef}
                className={
                  parentState.locationsServedFilterPopoverOpen
                    ? "border1Grey filterBtns open"
                    : parentState.LocationsServed.length > 0 ||
                      parentState.IsNational
                    ? "border1Grey filterBtns active"
                    : "border1Grey filterBtns"
                }
                clickable={true}
                onClick={() =>
                  this.props.openFilterOverlay(
                    "locationsServedFilterPopoverOpen"
                  )
                }
              />
            </Tippy>
          </div>

          {/* More Filters */}
          <div className="filterContainer positionRelative">
            <Tippy
              interactive={true}
              visible={parentState.moreFilterPopoverOpen}
              onClickOutside={() =>
                this.props.closeFilterOverlay("moreFilterPopoverOpen")
              }
              placement={"bottom"}
              content={
                <div className="filterPopovers">
                  <div className="flexColumn alignFlexStart marginBottom16">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="DropInAvailable"
                          value="Drop-In Available"
                          checked={parentState.DropInAvailable}
                          className={
                            parentState.DropInAvailable
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Drop-in Available"
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          name="OpenNow"
                          value="Open Now"
                          checked={parentState.OpenNow}
                          className={
                            parentState.OpenNow
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Open Now"
                    /> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="VirtualServices"
                          value="Virtual Services"
                          checked={parentState.VirtualServices}
                          className={
                            parentState.VirtualServices
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Virtual Services"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="FaithBased"
                          value="Faith-Based"
                          checked={parentState.FaithBased}
                          className={
                            parentState.FaithBased
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Faith-based"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="ChildrenAllowed"
                          value="Children Allowed"
                          checked={parentState.ChildrenAllowed}
                          className={
                            parentState.ChildrenAllowed
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onChange={this.props.handleCheckBoxChange}
                        />
                      }
                      label="Children Allowed"
                    />
                  </div>
                  <div className="flexRow justifySpaceBetween">
                    <button
                      className="fontSize16 lineHeight20 btnNoStyle btnNoFocus colorBlue1 underline"
                      onClick={() =>
                        this.props.clearOneSetOfFilters(
                          [
                            "Drop-In Available",
                            // "Open Now",
                            "Virtual Services",
                            "Faith-Based",
                            "Children Allowed",
                          ],
                          "moreFilterPopoverOpen"
                        )
                      }
                    >
                      Clear
                    </button>
                    {parentState.showFilterSave === "showMoreFiltersSave" ? (
                      <button
                        className="btnWithStyle btnMagenta0 fontSize14 lineHeight16 borderRadius4"
                        onClick={() => {
                          this.props.handleCheckBoxSave();
                          this.props.closeFilterOverlay(
                            "moreFilterPopoverOpen"
                          );
                          this.props.updateCurrentFilters(
                            [
                              "Drop-In Available",
                              // "Open Now",
                              "Virtual Services",
                              "Faith-Based",
                              "Children Allowed",
                            ],
                            "moreFilterPopoverOpen"
                          );
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        disabled
                        className="btnWithStyle btnMagenta0 opacity3 fontSize14 lineHeight16 borderRadius4"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              }
            >
              <Chip
                label="More Filters"
                aria-describedby={
                  Boolean(parentState.moreFilterPopoverOpen)
                    ? "simple-popover"
                    : undefined
                }
                // ref={testRef}
                className={
                  parentState.moreFilterPopoverOpen
                    ? "border1Grey filterBtns open"
                    : parentState.DropInAvailable ||
                      // parentState.OpenNow ||
                      parentState.VirtualServices ||
                      parentState.FaithBased ||
                      parentState.ChildrenAllowed
                    ? "border1Grey filterBtns active"
                    : "border1Grey filterBtns"
                }
                clickable={true}
                onClick={() =>
                  this.props.openFilterOverlay("moreFilterPopoverOpen")
                }
              />
            </Tippy>
          </div>
          <button
            className="btnNoStyle btnNoFocus fontSize14 lineHeight16 colorBlue1 underline"
            onClick={this.props.clearAllFilters}
          >
            Clear<br></br>Filters
          </button>
        </div>

        <div
          className={
            width < 768
              ? "flexColumnReverse order2"
              : "flexRow justifySpaceBetween"
          }
        >
          <div>
            {this.props.tableRefCurrentObj.state ? (
              <p
                className={
                  width < 768
                    ? "marginTopRevert fontSize14 lineHeight16 colorBlack2"
                    : "fontSize14 lineHeight16 colorBlack2"
                }
              >
                {this.props.tableRefCurrentObj.state.data.length} Organizations
              </p>
            ) : null}
          </div>
          <div>
            <button
              className="btnNoStyle btnNoFocus underline fontSize14 lineHeight16 colorBlue1 marginRight20"
              onClick={
                this.state.isAuthenticated
                  ? this.props.openSaveSearchModal
                  : this.handleSaveSearchClick
              }
            >
              <span>Save this Search</span>
            </button>

            <button
              className="btnNoStyle btnNoFocus fontSize14 lineHeight16 colorBlue1"
              onClick={
                this.state.isAuthenticated
                  ? this.props.openSavedSearchesModal
                  : this.handleSaveSearchClick
              }
            >
              <div className="flexRow alignFlexEnd">
                <span className="underline marginRight4">Saved Searches</span>
                <SearchIcon className="iconSize15 colorGrey2" />
              </div>
            </button>
          </div>
        </div>

        {/* modal shows when unauthenticated user clicks on save a search or saved searches */}
        <Modal
          open={this.state.unAuthSaveClick}
          onClose={() => this.setState({ unAuthSaveClick: false })}
          aria-labelledby="save-search-modal"
          aria-describedby="save-search-modal"
        >
          <Box
            sx={style}
            className={
              parentState.windowSize[0] < 480 ? "width90vw" : "width400"
            }
          >
            <Typography className="modal-text textAlignCenter">
              You must sign in to use this feature.
            </Typography>
            <Divider />
            <Typography
              className="modal-button-stacked modal-button-last textDecorationUnderline"
              onClick={() => {
                this.setState({ unAuthSaveClick: false });
              }}
            >
              OK
            </Typography>
          </Box>
        </Modal>

        {/* Save this Search modal */}
        <SaveASearchModal
          ageInput={
            parentState.ExactAge ? parentState.ExactAge : parentState.AgeRange
          }
          locations={parentState.Location}
          locationsServed={
            parentState.IsNational
              ? parentState.IsNational
              : parentState.LocationsServed
          }
          windowWidth={width}
          appContext={parentState.appContext}
          saveSearchModalOpen={parentState.saveSearchModalOpen}
          filters={this.props.filters}
          onChangeTextInput={this.props.onChangeTextInput}
          cancelSavedSearch={this.props.cancelSavedSearch}
          saveThisSearch={this.props.saveThisSearch}
        />

        {/* Save search success message */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={parentState.searchSaveSuccess}
          onClose={this.props.closeSearchSaveSuccess}
          message="Successfully saved this search!"
          key={"bottom center"}
          className="saveSearchSuccessSnackbar"
          action={
            <React.Fragment>
              <button
                className="btnNoStyle width14Height14"
                onClick={this.props.closeSearchSaveSuccess}
              >
                <img src={greyCloseIcon} className="width100Percent" alt="X" />
              </button>
            </React.Fragment>
          }
        />

        {/* Saved Searches modal */}
        <SavedSearchesModal
          appContext={parentState.appContext}
          savedSearchesModalOpen={parentState.savedSearchesModalOpen}
          // allSavedSearches={this.props.allSavedSearches}
          allSavedSearches={this.state.savedSearches}
          savedFiltersClick={this.props.savedFiltersClick}
          closeSavedSearchesModal={this.props.closeSavedSearchesModal}
          saveAllSearches={this.saveAllSearches}
          openSavedSearchItem={this.props.openSavedSearchItem}
          closeSavedSearchItem={this.props.closeSavedSearchItem}
          setSavedSearchObj={this.props.setSavedSearchObj}
        />

        {/* Saved Searches modal */}
        <EditSavedSearchTitleModal
          appContext={parentState.appContext}
          savedSearchItemModalOpen={parentState.editSavedSearchTitleModalOpen}
          // allSavedSearches={this.props.allSavedSearches}
          allSavedSearches={this.state.savedSearches}
          saveAllSearches={this.saveAllSearches}
          obj={parentState.savedSearchObj}
          openSavedSearchesModal={this.props.openSavedSearchesModal}
          savedFiltersClick={this.props.savedFiltersClick}
          closeSavedSearchItem={this.props.closeSavedSearchItem}
        />
      </>
    );
  }
}
