import React, { Component } from "react";
import { AppContext } from "../../useContext";
// import axios from "axios"; // npm instal axios
import API from "@aws-amplify/api";
import {
  ArrowDownward,
  ChevronLeft,
  ChevronRight,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  Search,
} from "@material-ui/icons";
// import { TextField, IconButton } from "@material-ui/core";
// import { InputAdornment } from "@mui/material";
// import MaterialTable, { MTableToolbar } from "material-table";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import rightArrowColorD5D8DD from "../../Assets/rightArrowColor#D5D8DD.png";
// import locationIconColor8F98A3 from "../../Assets/locationIconColor#8F98A3.png";
import { OrgDetailPage } from "./OrgDetailPage";
import { ProviderTableToolbar } from "./ProviderTableToolbar";
import mixpanel from "mixpanel-browser";

const tableIcons = {
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

export default class ServiceProviders extends Component {
  // getWindowSize() {
  //   const { innerWidth, innerHeight } = window;
  //   console.log(innerWidth);
  //   return { innerWidth, innerHeight };
  // }

  // useEffect() {
  //   function handleWindowResize() {
  //     this.state.windowSize();
  //   }

  //   window.addEventListener("resize", handleWindowResize);
  //   window.addEventListener("scroll", this.disableMobileScroll);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //     window.removeEventListener("scroll", this.disableMobileScroll);
  //   };
  // }

  constructor(props) {
    super(props);
    this.state = {
      // windowSize: this.getWindowSize(),
      windowSize: this.props.windowSize, //[width,height]
      appContext: {},
      providersDataFromBackend: [],

      page: "table",
      person: [],
      ageFilterPopoverAnchor: null,
      ageFilterPopoverOpen: false,
      // Under18yearsold: false,
      // "16to24yearsold": false,
      // "18yearsoldandolder": false,
      //grab the column field name from backend that is storing the age data
      AgeRange: [], // [minAge, maxAge]
      ExactAge: null, // integer

      servicesFilterPopoverOpen: false,
      HousingandShelter: false,
      WorkforceDevelopment: false,
      LegalAid: false,
      FinancialAssistance: false,
      MentalHealth: false,
      CaseManagement: false,
      Healthcare: false,
      Hotlines: false,
      DropInCenter: false,
      SupportGroupServices: false,
      Education: false,
      HealingArts: false,
      YouthDevelopmentServices: false,
      SubstanceOveruseServices: false,
      PublicBenefits: false,
      PeerSupport: false,
      BasicNeedsResource: false,
      CrisisIntervention: false,
      Entrepreneurship: false,
      LeadershipDevelopment: false,
      FoodAccess: false,
      ChildWelfareandChildProtection: false,
      TelephoneHotline: false,

      locationFilterPopoverOpen: false,
      Location: [],

      gendersFilterPopoverOpen: false,
      Woman: false,
      TransWoman: false,
      Man: false,
      TransMan: false,
      GenderQueer: false,
      GenderNonConforming: false,
      GenderNonBinary: false,
      Agender: false,
      NoneOfThese: false,

      locationsServedFilterPopoverOpen: false,
      LocationsServed: [],
      IsNational: false,

      moreFilterPopoverOpen: false,
      DropInAvailable: false,
      OpenNow: false,
      VirtualServices: false,
      FaithBased: false,
      ChildrenAllowed: false,

      showFilterSave: "", // "showServicesSave", "showLocationSave", "showGendersSave", "showMoreFiltersSave"
      saveSearchModalOpen: false,
      searchSaveSuccess: false,
      saveSearchName: "",

      savedSearchesModalOpen: false,
      savedSearches: [],
      editSavedSearchTitleModalOpen: false,
      savedSearchObj: {
        isEditing: false,
        search_id: "",
        search_name: "",
        search_filter_configuration: [],
      },

      orgDetailsToShow: {},
      filters: [],
      trueFilterStateNames: [],
      // dataToShow: [],
      searchInput: "",
      searchSubmit: "",
    };

    this.tableRef = React.createRef();
    this.tableRefCurrentObj = {};
  }

  componentDidMount(prevProps, prevState) {
    // Get all service providers from the backend and add app context to state as well
    this.tableRefCurrentObj = this.tableRef.current;
    if (this.state !== prevState) {
      API.get("referall-provider", "getallproviders").then((response) => {
        let alphabeticalOrder = response.sort(function (a, b) {
          let textA = a.provider_name.toUpperCase();
          let textB = b.provider_name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        let isVisibleFilter = alphabeticalOrder.filter(
          (item) => item.is_visible === true
        );

        this.setState({
          providersDataFromBackend: isVisibleFilter,
          appContext: this.context, // Get app context
        });
        // console.log(
        //   "providersDataFromBackend",
        //   this.state.providersDataFromBackend
        // ); //140 objects in collection
      });
    }
    if (this.props !== prevProps) {
      const compProps = this.props;
      // console.log("compProps: ", compProps);

      // If path from people's 'see matching providers' button, update filters
      if (compProps && compProps.location && compProps.location.state) {
        const pathState = compProps.location.state;
        // console.log("from state: ", pathState);

        if (
          compProps.location.state.dob &&
          compProps.location.state.services_in
        ) {
          // Survivor clicked on their see matching providers
          var servicesIn = pathState.services_in.replace(/ /g, "");
          if (servicesIn === "Noneofthese") {
            servicesIn = "NoneOfThese";
          } else if (servicesIn.includes("-")) {
            servicesIn = servicesIn.replace("-", "");
          }
          // console.log(servicesIn);

          // Get the users age from their dob
          var getTimeSinceDob = Math.round(
            new Date().getTime() - new Date(pathState.dob).getTime()
          );
          var day = 1000 * 60 * 60 * 24;
          var days = Math.round(getTimeSinceDob / day);
          var months = Math.round(days / 31);
          var usersAge = Math.round(months / 12);

          this.setState({
            ExactAge: usersAge,
          });
          // console.log(usersAge, this.state.ExactAge);
          //must map through collection to compare usersAge to providers_ages_served min-max array to display results
          if (usersAge || this.state.ExactAge) {
            let dataCopy = [...this.state.providersDataFromBackend];
            let dataFilter = dataCopy.filter(
              (item) =>
                this.state.ExactAge >= item.provider_ages_served[0] &&
                this.state.ExactAge <= item.provider_ages_served[1]
            );
            console.log(dataFilter);
          }
          // if (usersAge < 18) {
          //   this.setState(
          //     {
          //       ...this.state,
          //       Under18yearsold: true,
          //       [servicesIn]: true,
          //     },
          //     () => {
          //       this.updateCurrentFilters(
          //         [
          //           "Under 18 years old",
          //           "16 - 24 years old",
          //           "18 years old and older",
          //         ],
          //         "ageFilterPopoverOpen"
          //       );
          //       this.updateCurrentFilters(
          //         [
          //           "Alameda County",
          //           "Contra Costa County",
          //           "Marin County",
          //           "Monterey County",
          //           "San Diego County",
          //           "San Francisco County",
          //           "San Mateo County",
          //           "Santa Cruz County",
          //           "Solano County",
          //           "Stanislaus County",
          //         ],
          //         "locationFilterPopoverOpen"
          //       );
          //     }
          //   );
          // }
          // if (usersAge >= 16 && usersAge <= 24) {
          //   var between16and24 = "16to24yearsold";
          //   this.setState(
          //     {
          //       ...this.state,
          //       [between16and24]: true,
          //       [servicesIn]: true,
          //     },
          //     () => {
          //       this.updateCurrentFilters(
          //         [
          //           "Under 18 years old",
          //           "16 - 24 years old",
          //           "18 years old and older",
          //         ],
          //         "ageFilterPopoverOpen"
          //       );
          //       this.updateCurrentFilters(
          //         [
          //           "Alameda County",
          //           "Contra Costa County",
          //           "Marin County",
          //           "Monterey County",
          //           "San Diego County",
          //           "San Francisco County",
          //           "San Mateo County",
          //           "Santa Cruz County",
          //           "Solano County",
          //           "Stanislaus County",
          //         ],
          //         "locationFilterPopoverOpen"
          //       );
          //     }
          //   );
          // }
          // if (usersAge >= 18) {
          //   this.setState(
          //     {
          //       ...this.state,
          //       "18yearsoldandolder": true,
          //       [servicesIn]: true,
          //     },
          //     () => {
          //       // console.log(this.state["18yearsoldandolder"]);
          //       this.updateCurrentFilters(
          //         [
          //           "Under 18 years old",
          //           "16 - 24 years old",
          //           "18 years old and older",
          //         ],
          //         "ageFilterPopoverOpen"
          //       );
          //       this.updateCurrentFilters(
          //         [
          //           "Alameda County",
          //           "Contra Costa County",
          //           "Marin County",
          //           "Monterey County",
          //           "San Diego County",
          //           "San Francisco County",
          //           "San Mateo County",
          //           "Santa Cruz County",
          //           "Solano County",
          //           "Stanislaus County",
          //         ],
          //         "locationFilterPopoverOpen"
          //       );
          //     }
          //   );
          // }
        } else if (compProps.location.state.filter) {
          // Survivor clicked on a saved search of theirs
          // Array of filters

          const newFilterState = {};

          compProps.location.state.filter.forEach((key) => {
            let filterStateName = key.replace(/ /g, "");
            if (filterStateName === "Noneofthese") {
              filterStateName = "NoneOfThese";
            } else if (filterStateName.includes("-")) {
              filterStateName = filterStateName.replace("-", "");
            }
            // console.log(filterStateName);
            newFilterState[filterStateName] = true;
          });

          //must map through collection to compare usersAge to providers_ages_served min-max array to display results
          if (usersAge) {
            let dataCopy = [...this.state.providersDataFromBackend];
            let dataFilter = dataCopy.filter(
              (item) =>
                usersAge >= item.provider_ages_served[0] &&
                usersAge <= item.provider_ages_served[1]
            );
            console.log(dataFilter);
          } else if (this.state.ExactAge) {
            let dataCopy = [...this.state.providersDataFromBackend];
            let dataFilter = dataCopy.filter(
              (item) =>
                this.state.ExactAge >= item.provider_ages_served[0] &&
                this.state.ExactAge <= item.provider_ages_served[1]
            );
            console.log(dataFilter);
          }
          // map through collection to compare LocationsServed to providers_locations_served array to display results
          else if (this.state.LocationsServed) {
            let dataCopy = [...this.state.providersDataFromBackend];
            let dataFilter = dataCopy.filter((obj1) =>
              this.state.LocationsServed.some((obj2) =>
                obj1.provider_locations_served.includes(obj2.value)
              )
            );
            console.log(dataFilter);
          } else if (this.state.IsNational) {
            let dataCopy = [...this.state.providersDataFromBackend];
            let dataFilter = dataCopy.filter((obj1) => obj1.isNational);
            console.log(dataFilter);
          }
          // map through collection to compare Locations to provider_addresses' obj.city and obj.state
          else if (this.state.Location) {
            let dataCopy = [...this.state.providersDataFromBackend];
            let dataFilter = dataCopy.filter((obj1) =>
              this.state.Location.some(
                (obj2) => `${obj1.city}, ${obj1.state}` === obj2.value
              )
            );
            console.log(dataFilter);
          }
          this.setState(newFilterState, () => {
            // console.log(this.state);
            this.updateCurrentFilters(
              ["Age Range", "Exact Age"],
              "ageFilterPopoverOpen"
            );
            this.updateCurrentFilters(
              [
                "Housing and Shelter",
                "Workforce Development",
                "Legal Aid",
                "Financial Assistance",
                "Mental Health",
                "Case Management",
                "Healthcare",
                "24-Hour Hotlines",
                "Drop-In Center",
                "Support Group Services",
                "Education",
                "Healing Arts",
                "Youth Development Services",
                "Substance Overuse Services",
                "Public Benefits",
                "Peer Support",
                "Basic Needs Resource",
                "Crisis Intervention",
                "Entrepreneurship",
                "Leadership Development",
                "Food Access",
                "Child Welfare and Child Protection",
                "Telephone Hotline",
              ],
              "servicesFilterPopoverOpen"
            );
            this.updateCurrentFilters(
              ["Location"],
              "locationFilterPopoverOpen"
            );
            this.updateCurrentFilters(
              [
                "Woman",
                "Trans Woman",
                "Man",
                "Trans Man",
                "Gender Fluid",
                "Gender Non-Conforming",
                "Gender Non-Binary",
                "Agender",
                "None of these",
              ],
              "gendersFilterPopoverOpen"
            );
            this.updateCurrentFilters(
              ["Locations Served", "Is National"],
              "locationsServedFilterPopoverOpen"
            );
            this.updateCurrentFilters(
              [
                "Drop-In Available",
                "Open Now",
                "Virtual Services",
                "Faith-Based",
                "Children Allowed",
              ],
              "moreFilterPopoverOpen"
            );
          });

          // console.log("filters");
        }
      }
    } else {
      // Remove state props from location after for page refresh
      const stateCopy = { ...this.props.location.state };
      delete stateCopy.dob;
      delete stateCopy.services_in;
      this.props.history.replace({ state: stateCopy });
    }
    mixpanel.track(
      this.props.provider ? "P ServProvSignIn PgVw" : "P ServProv PgVw",
      {
        "Event Type": "Page View",
        Authenticated: this.props.provider ? true : false,
      }
    );
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      console.log(this.props);
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  // When the user clicks on a filter chip button, open the overlay associated with it
  openFilterOverlay = (filterPopOverOpen) => {
    this.setState({
      [filterPopOverOpen]: true,
      searchSaveSuccess: false,
    });
  };

  closeFilterOverlay = (filterPopoverOpenName) => {
    this.setState({
      [filterPopoverOpenName]: false,
      searchSaveSuccess: false,
    });
  };

  // attempt to create a separate search field for tracking.
  handleProviderSearch = (e) => {
    // console.log(e.target.name, e.target.value, e.key);
    // let child = document.getElementsByTagName("input");
    // child.classList.add("provider_search");
    this.setState({ searchSubmit: "", searchInput: e.target.value });
    if (e.key === "Enter") {
      this.setState({ searchSubmit: "clicked" });
      mixpanel.track(
        this.props.provider
          ? "P SearchContentAuth Submit"
          : "P SearchContentUnauth Submit",
        {
          "Search Input": this.state.searchInput,
          "Event Type": "User Action",
          Authenticated: this.props.provider ? true : false,
        }
      );
    }
  };

  handleProviderSearchSubmit = (e) => {
    // let child = document.getElementsByTagName("input");
    // child.classList.add("provider_search");
    // child.innerHTML = this.state.searchInput;
    // console.log(child);
    // insert mix panel tracking here
    this.setState({ searchSubmit: "clicked" });
    mixpanel.track(
      this.props.provider
        ? "P SearchContentAuth Submit"
        : "P SearchContentUnauth Submit",
      {
        "Search Input": this.state.searchInput,
        "Event Type": "User Action",
        Authenticated: this.props.provider ? true : false,
      }
    );
  };

  // When the user clicks a checkbox, update its state to be equal to it's built in checked property (will be true or false); any change will make respective filter popover's Save button enabled
  // Note: showAgeSave -> only input changes to show Age Filter's enabled Save button is in ProviderTableToolbar.js
  handleCheckBoxChange = (e) => {
    // only one setState allowed per function/condition
    // this.setState({
    //   ...this.state,
    //   [e.target.name]: e.target.checked,
    // });
    let filtersCopy = [...this.state.filters];
    let stateNamesCopy = [...this.state.trueFilterStateNames];
    if (filtersCopy.includes(e.target.value) === false && e.target.checked) {
      filtersCopy.push(e.target.value);
      stateNamesCopy.push(e.target.name);
    } else {
      stateNamesCopy.splice(stateNamesCopy.indexOf(e.target.name), 1);
      filtersCopy.splice(filtersCopy.indexOf(e.target.value), 1);
    }
    if (
      e.target.name === "HousingandShelter" ||
      e.target.name === "WorkforceDevelopment" ||
      e.target.name === "LegalAid" ||
      e.target.name === "FinancialAssistance" ||
      e.target.name === "MentalHealth" ||
      e.target.name === "CaseManagement" ||
      e.target.name === "Healthcare" ||
      e.target.name === "Hotlines" ||
      e.target.name === "DropInCenter" ||
      e.target.name === "SupportGroupServices" ||
      e.target.name === "Education" ||
      e.target.name === "HealingArts" ||
      e.target.name === "YouthDevelopmentServices" ||
      e.target.name === "SubstanceOveruseServices" ||
      e.target.name === "PublicBenefits" ||
      e.target.name === "PeerSupport" ||
      e.target.name === "BasicNeedsResource" ||
      e.target.name === "CrisisIntervention" ||
      e.target.name === "Entrepreneurship" ||
      e.target.name === "LeadershipDevelopment" ||
      e.target.name === "FoodAccess" ||
      e.target.name === "ChildWelfareandChildProtection" ||
      e.target.name === "TelephoneHotline"
    ) {
      // console.log(e.target.value);
      this.setState({
        ...this.state,
        [e.target.name]: e.target.checked,
        showFilterSave: "showServicesSave",
        filters: filtersCopy,
        trueFilterStateNames: stateNamesCopy,
      });
    } else if (e.target.name === "IsNational") {
      // if IsNational is checked, remove LocationsServed
      stateNamesCopy.splice(stateNamesCopy.indexOf("LocationsServed"), 1);
      filtersCopy.splice(filtersCopy.indexOf("Locations Served"), 1);
      this.setState({
        ...this.state,
        [e.target.name]: e.target.checked,
        showFilterSave: "showLocationsServedSave",
        filters: filtersCopy,
        trueFilterStateNames: stateNamesCopy,
      });
    } else if (
      e.target.name === "Woman" ||
      e.target.name === "TransWoman" ||
      e.target.name === "Man" ||
      e.target.name === "TransMan" ||
      e.target.name === "GenderQueer" ||
      e.target.name === "GenderNonConforming" ||
      e.target.name === "GenderNonBinary" ||
      e.target.name === "GenderQueer" ||
      e.target.name === "Agender" ||
      e.target.name === "NoneOfThese"
    ) {
      // console.log(e.target.name);
      this.setState({
        ...this.state,
        [e.target.name]: e.target.checked,
        showFilterSave: "showGendersSave",
        filters: filtersCopy,
        trueFilterStateNames: stateNamesCopy,
      });
    } else if (
      e.target.name === "DropInAvailable" ||
      e.target.name === "VirtualServices" ||
      e.target.name === "FaithBased" ||
      e.target.name === "ChildrenAllowed"
    ) {
      // console.log(e.target.name);
      this.setState({
        ...this.state,
        [e.target.name]: e.target.checked,
        showFilterSave: "showMoreFiltersSave",
        filters: filtersCopy,
        trueFilterStateNames: stateNamesCopy,
      });
    }
  };

  handleCheckBoxSave = () => {
    this.setState({ showFilterSave: "" });
  };

  //When user enters an age, get its input name to update the filters
  handleAgeChange = (e) => {
    console.log(e.target.name, e.target.value);
  };

  //When user saves entry of any age input, update its parent state
  handleAgeSave = (value) => {
    // console.log(value);
    // e.preventDefault();
    // var ageValue = e.target.value;
    //this.setState({...this.state, [e.target.name]: e.target.value})
    this.setState(value);
    // console.log(this.state.AgeRange, this.state.ExactAge);
    // console.log(this.state);
    if (this.state.AgeRange) {
      mixpanel.track(
        this.props.provider ? "P UpFilterAuth Save" : "P UpFilterUnauth Save",
        {
          "Event Type": "Page View",
          "Age Range": this.state.AgeRange,
        }
      );
    } else if (this.state.ExactAge) {
      mixpanel.track(
        this.props.provider ? "P UpFilterAuth Save" : "P UpFilterUnauth Save",
        {
          "Event Type": "Page View",
          "Exact Age": this.state.ExactAge,
        }
      );
    }
  };

  //When user saves locations in filter, update its parent state
  handleLocationSave = (value) => {
    // console.log(value);
    this.setState(value);
  };

  // When the user clicks save in a filter modal, update selected filters
  updateCurrentFilters = (array, filterPopoverOpenName) => {
    // console.log(array, filterPopoverOpenName);
    // let filterUpdates = [...this.state.filters];
    // console.log(filterUpdates); // only has the current change, not previous saved changes; same goes for state names.
    // let filterStateNames = [...this.state.trueFilterStateNames];
    const filtersCopy = [...this.state.filters];
    const stateNamesCopy = [...this.state.trueFilterStateNames];
    // console.log(filtersCopy, stateNamesCopy);
    array.map((filter) => {
      // Get the state name of the current filter in the array
      var stateName = filter.replace(/ /g, "");
      console.log(stateName);
      if (stateName === "Noneofthese") {
        stateName = "NoneOfThese";
      } else if (stateName === "24-HourHotlines") {
        stateName = "Hotlines";
      } else if (stateName.includes("-")) {
        stateName = stateName.replace("-", "");
      }
      // console.log(stateName);
      // console.log(stateName, this.state[stateName]);
      if (filter === "Age Range" || filter === "Exact Age") {
        stateNamesCopy.splice(
          stateNamesCopy.indexOf(this.state.ExactAge ? "AgeRange" : "ExactAge"),
          1
        );
        filtersCopy.splice(
          filtersCopy.indexOf(this.state.ExactAge ? "Age Range" : "Exact Age"),
          1
        );
        stateNamesCopy.push(this.state.ExactAge ? "ExactAge" : "AgeRange");
        return filtersCopy.push(
          this.state.ExactAge ? "Exact Age" : "Age Range"
        );
      } else if (filter === "Locations Served" || filter === "Is National") {
        stateNamesCopy.splice(
          stateNamesCopy.indexOf(
            this.state.IsNational ? "LocationsServed" : "IsNational"
          ),
          1
        );
        filtersCopy.splice(
          filtersCopy.indexOf(
            this.state.IsNational ? "Locations Served" : "Is National"
          ),
          1
        );
        stateNamesCopy.push(
          this.state.IsNational ? "IsNational" : "LocationsServed"
        );
        return filtersCopy.push(
          this.state.IsNational ? "Is National" : "Locations Served"
        );
      } else if (this.state[stateName] && filtersCopy.indexOf(filter) < 0) {
        stateNamesCopy.push(stateName);
        return filtersCopy.push(filter);
      } else {
        return "";
      }
    });
    // console.log(
    //   "array",
    //   array,
    //   "filters",
    //   filtersCopy,
    //   "trueFilterStateNames",
    //   stateNamesCopy
    // );
    // Then close the filter overlay
    this.setState({
      filters: filtersCopy,
      trueFilterStateNames: stateNamesCopy,
      [filterPopoverOpenName]: null,
    });
    // console.log(this.state.AgeRange, this.state.ExactAge); // no values
    mixpanel.track(
      this.props.provider ? "P UpFilterAuth Save" : "P UpFilterUnauth Save",
      {
        Filters: filtersCopy,
        "Event Type": "User Action",
        Authenticated: this.props.provider ? true : false,
      }
    );
  };

  // when the user clicks clear inside one of the filter overlays, clear the filters associated with that overlay only.
  clearOneSetOfFilters = (array, filterPopoverOpenName) => {
    // console.log(array);
    var itemsToUpdate = [];
    let filtersCopy = [...this.state.filters];
    let stateNamesCopy = [...this.state.trueFilterStateNames];
    array.map((filter) => {
      // console.log(filter);
      // get stateNames to update state later
      let stateName = filter.includes("-")
        ? filter.replace("-", "").split(" ").join("")
        : filter.replace(/ /g, "");
      // custom filter/stateNames adjustments for Save a Search content display clarity
      if (filter === "None of these") {
        filter = "None of these genders";
        stateName = "NoneOfThese";
      } else if (filter === "24-Hour Hotlines") {
        stateName = "Hotlines";
      }
      // then use splice to handle item removal
      if (filtersCopy.indexOf(filter) > -1) {
        filtersCopy.splice(filtersCopy.indexOf(filter), 1);
        // console.log("clear one filters", filtersCopy);
        stateNamesCopy.splice(stateNamesCopy.indexOf(stateName), 1);
        this.setState({
          filters: filtersCopy,
          trueFilterStateNames: stateNamesCopy,
        });
      }
      // console.log("filtersCopy", filtersCopy, "stateNamesCopy", stateNamesCopy);
      return itemsToUpdate.push(stateName);
    });
    // console.log("itemsToUpdate", itemsToUpdate, stateNamesCopy);
    // const itemsUpdated = [];
    // let count = 0;
    for (var i = 0; i < itemsToUpdate.length; i++) {
      //resets AgeRange to an empty array
      if (
        itemsToUpdate[i] === "AgeRange" ||
        itemsToUpdate[i] === "LocationsServed" ||
        itemsToUpdate[i] === "Location"
      ) {
        // itemsUpdated.push(itemsToUpdate[i]);
        this.setState({
          [itemsToUpdate[i]]: [],
          [filterPopoverOpenName]: null,
        });
      } else if (itemsToUpdate[i] === "ExactAge") {
        // itemsUpdated.push(itemsToUpdate[i]);
        this.setState({
          ExactAge: null,
          [filterPopoverOpenName]: null,
        });
      } else {
        // itemsUpdated.push(itemsToUpdate[i]);
        // count++;
        // console.log(count, itemsUpdated);
        this.setState({
          [itemsToUpdate[i]]: false,
          [filterPopoverOpenName]: null,
        });
      }
    }
    // console.log(
    //   // itemsUpdated,
    //   this.state
    // );
  };

  // Clear all filters from the table and set all filter checkboxes to false
  clearAllFilters = () => {
    this.setState({
      // deprecated checkboxes for age
      // Under18yearsold: false,
      // "16to24yearsold": false,
      // "18yearsoldandolder": false,
      AgeRange: [],
      ExactAge: null,

      Housing: false,
      WorkforceDevelopment: false,
      Legal: false,
      Financial: false,
      MentalHealth: false,
      CaseManagement: false,
      Healthcare: false,
      Hotlines: false,
      DropInServices: false,
      PeerGroups: false,
      Education: false,
      HealingArts: false,
      YouthDevelopmentServices: false,
      SubstanceOveruseServices: false,

      Location: [],

      Woman: false,
      TransWoman: false,
      Man: false,
      TransMan: false,
      GenderQueer: false,
      GenderNonConforming: false,
      GenderNonBinary: false,
      Agender: false,
      NoneOfThese: false,

      LocationsServed: [],
      IsNational: false,

      DropInAvailable: false,
      OpenNow: false,
      VirtualServices: false,
      FaithBased: false,
      ChildrenAllowed: false,

      filters: [],
      trueFilterStateNames: [],
    });
  };

  openSaveSearchModal = () => {
    this.setState({
      saveSearchModalOpen: true,
      searchSaveSuccess: false,
    });
  };

  // Reusable onChange func for all text inputs
  onChangeTextInput = (e, stateName) => {
    e.preventDefault();
    this.setState({
      [stateName]: e.target.value,
    });
  };

  cancelSavedSearch = () => {
    this.setState({
      saveSearchModalOpen: false,
    });
  };

  // Add new search object to the database and close the save search modal
  saveThisSearch = (saveSearchName, participantProfileId) => {
    const filtersCopy = [...this.state.filters];
    // console.log(filtersCopy);
    let newArray = filtersCopy.map((item) => {
      if (item.includes("Exact Age")) {
        return (item = `${item}: ${this.state.ExactAge}`);
      } else if (item.includes("Age Range")) {
        return (item = `${item}: ${this.state.AgeRange[0]}-${this.state.AgeRange[1]}`);
      } else if (item.includes("Location")) {
        let cities = this.state.Location.map((obj) => obj.value);
        let citiesStr = cities.join("; ");
        return (item = `${item}: ${citiesStr}`);
      } else if (item.includes("Locations Served")) {
        let cities = this.state.LocationsServed.map((obj) => obj.value);
        let citiesStr = cities.join("; ");
        return (item = `${item}: ${citiesStr}`);
      } else if (item.includes("Is National")) {
        return (item = `Locations Served: Nationwide`);
      } else {
        return (item = `${item}`);
      }
    });
    // console.log(newArray);
    const newSearchObj = {
      search_id:
        saveSearchName.replace(/\s+/g, "") +
        "" +
        Math.round(Math.random() * 1000),
      search_name: saveSearchName,
      participant_id: participantProfileId,
      search_filter_configuration: newArray,
    };
    // console.log("newSearchObj", newSearchObj, "this.props", this.props);
    API.post("referall-provider", "addprovidersearch", {
      body: {
        provider_email: this.props.email,
        provider: this.props.provider,
        provider_id: this.props.provider_id,
        newSearchObj,
      },
    }).then((response) => {
      // console.log(this.props.email);
      if (response === "The Name Search already exists.") {
        if (participantProfileId) {
          alert(
            "This Search name already exists in this person's saved searches. Please use another name."
          );
        } else {
          alert("This search name already exists. Please use another name.");
        }
      } else {
        this.setState({
          saveSearchModalOpen: false,
          saveSearchName: "",
          searchSaveSuccess: true,
        });
        mixpanel.track("P SaveSearch Submit", {
          "Saved Search": newSearchObj,
          "Event Type": "User Action",
        });

        setTimeout(() => {
          if (this.state.searchSaveSuccess === true) {
            this.setState({
              searchSaveSuccess: false,
            });
          }
        }, 5000);
      }
    });
  };

  closeSearchSaveSuccess = () => {
    this.setState({
      searchSaveSuccess: false,
    });
  };

  openSavedSearchesModal = () => {
    // console.log(this.state.savedSearches); //[]
    this.setState({
      savedSearchesModalOpen: true,
      searchSaveSuccess: false,
    });
  };

  // When an item is clicked in the saved searches modal
  savedFiltersClick = (filters, stateNames) => {
    this.clearAllFilters();
    // console.log(filters, stateNames); //['Age Range: 5-12'] ['AgeRange:5-12']
    const filtersCopy = [...filters];

    // for (var i = 0; i < stateNames.length; i++) {
    filtersCopy.map((item) => {
      // handle exact age and age range
      if (item.includes("Age Range")) {
        let range = item.split(": ")[1];
        this.setState({
          [item.split(": ")[0].replace(/\s/g, "")]: [
            Number(range.split("-")[0]),
            Number(range.split("-")[1]),
          ],
        });
      } else if (item.includes("Exact Age")) {
        this.setState({
          [item.split(":")[0].replace(/\s/g, "")]: item.split(": ")[1],
        });
      } else if (item.includes("Hotlines")) {
        this.setState({
          Hotlines: true,
        });
      } else if (item.includes("None of these")) {
        this.setState({
          NoneOfThese: true,
        });
      } else if (item.includes("Location")) {
        console.log(item);
        let location = item.split(": ")[1];
        let cities = location.split("; ");
        let citiesArr = cities.map((city) => {
          return {
            label: city,
            value: city,
            checked: true,
            category: "Searched",
          };
        });
        this.setState({ Location: citiesArr });
      } else if (item.includes("Locations Served")) {
        let locations = item.split(": ")[1];
        if (locations === "Nationwide") {
          this.setState({ IsNational: true });
        } else {
          let cities = locations.split("; ");
          let citiesArr = cities.map((city) => {
            return {
              label: city,
              value: city,
              checked: true,
              category: "Searched",
            };
          });
          this.setState({ LocationsServed: citiesArr });
        }
      } else {
        // Set the filter state names to true
        this.setState({
          [item.replace(/\s/g, "").replace("-", "")]: true,
        });
      }
      return "";
    });
    //set filtersCopy to state for value of new filters
    this.setState({ savedSearchesModalOpen: false, filters: filtersCopy });
  };

  // edit saved search item functions
  openSavedSearchItem = () => {
    this.setState({
      editSavedSearchTitleModalOpen: true,
    });
  };

  closeSavedSearchItem = (obj) => {
    // console.log(obj);
    const newSearchObj = { ...obj };
    // console.log(newSearchObj);
    this.setState({
      editSavedSearchTitleModalOpen: false,
    });
    if (obj.isEditing) {
      API.post("referall-provider", "addprovidersearch", {
        body: {
          provider_email: this.props.email,
          provider: this.props.provider,
          provider_id: this.props.providerId,
          newSearchObj,
        },
      }).then((response) => {
        // console.log(this.props.email);
        if (response === "The Name Search already exists.") {
          alert("This search name already exists. Please use another name.");
        } else {
          // console.log("handle isEditing success");
          // must check that isEditing is false
          // set state for savedSearchObj
          this.setState({
            savedSearchObj: {
              isEditing: false,
              search_id: "",
              search_name: "",
              search_filter_configuration: [],
            },
          });
          // this.setState({
          //   saveSearchModalOpen: false,
          //   saveSearchName: "",
          //   searchSaveSuccess: true,
          // });

          // setTimeout(() => {
          //   if (this.state.searchSaveSuccess === true) {
          //     this.setState({
          //       searchSaveSuccess: false,
          //     });
          //   }
          // }, 5000);
        }
      });
    }
  };

  setSavedSearchObj = (obj, index) => {
    obj.index = index;
    this.setState({ savedSearchObj: obj });
  };

  render() {
    var dataObjects = [];
    var dataToShow = [];
    let filtersCopy = [...this.state.filters];
    console.log(filtersCopy);
    //this.state.filters is the array storing all of the filters the user has selected
    // console.log(this.state.providersDataFromBackend, "provider data");
    // this.state.providersDataFromBackend is the array of all of the providers

    if (this.state.searchSubmit) {
      this.state.providersDataFromBackend.forEach((obj) => {
        let arr = Object.values(obj);
        let newArr = arr.flat();
        // console.log(newArr);
        let result = newArr.find((item) => {
          if (
            typeof item === "string" &&
            item.toLowerCase().includes(this.state.searchInput.toLowerCase())
          ) {
            // result logs actual string that starts with the search input
            return true;
          } else {
            // result is undefined
            return false;
          }
        });
        // console.log(result); //undefined or returns string
        if (result) {
          dataObjects.push(obj);
        }
      });
      // console.log(dataObjects);
      dataToShow = dataObjects;
      // console.log(dataToShow);
    } else if (filtersCopy.length > 0 && this.state.providersDataFromBackend) {
      this.state.providersDataFromBackend.forEach((obj) => {
        // If the org matches on any of the age filters, age variable will be true
        // var ageCategories = ["Age Range", "Exact Age"];
        // const found = ageCategories.some((r) => this.filters.indexOf(r) >= 0);
        var age;
        // if (found) {
        //   const isInAgeObj = (currentValue) =>
        //     obj.provider_ages_served.indexOf(currentValue) > -1;
        //   age = this.filters.find(isInAgeObj);
        // } else {
        //   age = true
        // }
        //replace above logic with integer value or array of integer value comparisons to update the dataToShow; we won't be checking each org to see if they have these strings in the database. when someone clicks on exact age, reset age range to be null and vice versa. make sure function is checking either or. if range !== null, then grab age range only; if exact age !== null, then grab exact age only. logic breaks when included below; so add the either/or logic in the child.
        // backend - provider_ages_served: (2)[(18, 65)];
        if (this.state.AgeRange.length > 0) {
          // console.log("age range"); //placeholder while building ExactAge logic
          if (
            // withinRange condition (min and max are within provider ages served range)
            (this.state.AgeRange[0] >= obj.provider_ages_served[0] &&
              this.state.AgeRange[1] <= obj.provider_ages_served[1]) ||
            // minInRange condition (max is out of provider ages served range)
            (this.state.AgeRange[0] >= obj.provider_ages_served[0] &&
              this.state.AgeRange[0] <= obj.provider_ages_served[1]) ||
            // maxInRange condition (min is out of provider ages served range)
            (this.state.AgeRange[1] >= obj.provider_ages_served[0] &&
              this.state.AgeRange[1] <= obj.provider_ages_served[1])
          ) {
            age = true;
          } else {
            age = false;
          }
        } else if (this.state.ExactAge !== null) {
          // console.log("exact age"); //placeholder while building ExactAge logic
          if (
            this.state.ExactAge >= obj.provider_ages_served[0] &&
            this.state.ExactAge <= obj.provider_ages_served[1]
          ) {
            // console.log(this.state.ExactAge, obj.provider_ages_served);
            age = true;
          } else {
            age = false;
          }
        } else {
          age = true;
        }
        // If the org matches on any of the services filters, services variable will be true
        var servicesCategories = [
          "Housing and Shelter",
          "Workforce Development",
          "Legal Aid",
          "Financial Assistance",
          "Mental Health",
          "Case Management",
          "Healthcare",
          "24-Hour Hotlines",
          "Drop-In Center",
          "Support Group Services",
          "Education",
          "Healing Arts",
          "Youth Development Services",
          "Substance Overuse Services",
          "Public Benefits",
          "Peer Support",
          "Basic Needs Resource",
          "Crisis Intervention",
          "Entrepreneurship",
          "Leadership Development",
          "Food Access",
          "Child Welfare and Child Protection",
          "Telephone Hotline",
        ];
        const servicesFound = servicesCategories.some(
          (r) => filtersCopy.indexOf(r) >= 0
        );
        var services;
        if (servicesFound) {
          const isInServicesObj = (currentValue) =>
            obj.provider_services_offered.indexOf(currentValue) > -1;
          services = filtersCopy.find(isInServicesObj);
          // console.log(services); //string
        } else {
          services = true;
        }

        // If the org matches on any of the provider's physical locations, location variable will be true
        var locationCategories = [...this.state.Location];
        // console.log(locationCategories); // array (empty/not empty)

        let addresses =
          obj.provider_addresses &&
          obj.provider_addresses.length > 0 &&
          obj.provider_addresses.map((obj1) => `${obj1.city}, ${obj1.state}`);

        const locationsProvider =
          locationCategories &&
          locationCategories.length > 0 &&
          locationCategories.some((obj2) => {
            if (addresses && addresses.length > 0) {
              return addresses.includes(obj2.value);
            } else {
              return null;
            }
          });
        console.log(locationsProvider, locationsProvider && "true");
        var location;
        if (locationCategories && locationCategories.length > 0) {
          if (locationsProvider) {
            location = true;
          } else {
            location = false;
          }
        } else {
          location = true;
        }
        // console.log(location);

        // If the org matches on any of the gender filters, gender variable will be true
        var genderCategories = [
          "Woman",
          "Trans Woman",
          "Man",
          "Trans Man",
          "Gender Queer",
          "Gender Non-Conforming",
          "Gender Non-Binary",
          "Agender",
          "None of these",
        ];
        const gendersFound = genderCategories.some(
          // (r) => this.filters.indexOf(r) >= 0
          (r) => filtersCopy.indexOf(r) >= 0
        );
        var genders;
        if (gendersFound) {
          const isInGenderObj = (currentValue) =>
            obj.provider_genders_served.indexOf(currentValue) > -1;
          genders = filtersCopy.find(isInGenderObj);
        } else {
          genders = true;
        }

        // If the org matches on any of the locations served filters, locationsServed variable will be true
        var locationsServedCategories = this.state.IsNational
          ? this.state.IsNational
          : [...this.state.LocationsServed];
        // console.log(locationCategories); //boolean, array (empty/not empty)

        // handle IsNational checkbox
        const locationsFound =
          typeof locationsServedCategories === "boolean"
            ? locationsServedCategories
            : locationsServedCategories &&
              locationsServedCategories.length > 0 &&
              locationsServedCategories.some((obj2) => {
                if (
                  obj.provider_locations_served &&
                  obj.provider_locations_served.length > 0
                ) {
                  return obj.provider_locations_served.includes(obj2.value);
                } else if (obj.is_national) {
                  return true;
                } else {
                  return null;
                }
              });
        var locationsServed;
        if (typeof locationsServedCategories === "boolean" && locationsFound) {
          if (obj.is_national) {
            locationsServed = true;
          } else {
            locationsServed = false;
          }
        } else if (
          typeof locationsServedCategories === "object" &&
          locationsServedCategories.length > 0
        ) {
          if (locationsFound) {
            locationsServed = true;
          } else {
            locationsServed = false;
          }
        } else {
          locationsServed = true;
        }
        // console.log(locationsServed);

        // If the org matches on any of the other filters, other variable will be true
        var otherCategories = [
          "Drop-In Available",
          "Open Now",
          "Virtual Services",
          "Faith-Based",
          "Children Allowed",
        ];
        const otherFound = otherCategories.some(
          (r) => filtersCopy.indexOf(r) >= 0
        );
        var other;
        if (otherFound) {
          const isInOtherObj = (currentValue) =>
            obj.provider_other_characteristics.indexOf(currentValue) > -1;
          other = filtersCopy.find(isInOtherObj);
        } else {
          other = true;
        }

        //revisit after age logic has been determined above
        if (
          age &&
          services &&
          location &&
          genders &&
          locationsServed &&
          other
        ) {
          dataObjects.push(obj);
          return;
        }

        // const isInObj = (currentValue) =>
        // 	Object.values(obj).toString().indexOf(currentValue) > -1;
        // if (this.filters.find(isInObj)) {
        // 	dataObjects.push(obj);
        // 	return;
        // }
        // console.log(this.filters);
        // console.log(obj);
      });
      dataToShow = dataObjects;
      // this.setState({ dataToShow: dataObjects });
    } else if (this.state.providersDataFromBackend) {
      // Else if no filters are selected and this.state.filters is empty, show all the data
      dataToShow = this.state.providersDataFromBackend;
      // this.setState({ dataToShow: this.state.providersDataFromBackend });
    }

    // Map each saved search as a button to display in the saved searches modal
    var allSavedSearches = "";

    if (this.state.savedSearches.length > 0) {
      allSavedSearches = this.state.savedSearches.map((obj, i) => {
        return (
          <button
            className="btnNoStyle displayBlock marginBottom30 textAlignLeft cursorPointer"
            key={i}
            onClick={() => {
              this.savedFiltersClick(obj.filters, obj.stateNames);
            }}
          >
            <p className="bold marginBottom5">{obj.name}</p>
            <p>{obj.filters && obj.filters.join(", ")}</p>
            {/* <span> {obj.id}</span> */}
          </button>
        );
      });
    }

    // Map and return each address for an organization to show on the org details page
    var orgDetailsToShow = this.state.orgDetailsToShow;
    var orgDetailsAddresses = "";
    var orgDetailsAddressArray = [];

    if (
      this.state.page === "showOrgDetails" &&
      orgDetailsToShow.provider_addresses_locations
      // (orgDetailsToShow.provider_address1 ||
      //   orgDetailsToShow.provider_address2 ||
      //   orgDetailsToShow.provider_address3)
    ) {
      // orgDetailsToShow.provider_address1 &&
      //   orgDetailsAddressArray.push(orgDetailsToShow.provider_address1);
      // orgDetailsToShow.provider_address2 &&
      //   orgDetailsAddressArray.push(orgDetailsToShow.provider_address2);
      // orgDetailsToShow.provider_address3 &&
      //   orgDetailsAddressArray.push(orgDetailsToShow.provider_address3);
      orgDetailsAddressArray = [
        ...orgDetailsToShow.provider_addresses_locations,
      ];

      orgDetailsAddresses = orgDetailsAddressArray.map((item, i) => {
        return (
          <div className="flexRow marginBottom15" key={i}>
            {this.state.windowSize[0] < 480 ? (
              <span>
                {item.split(",")[0]}
                <br></br>
                {item.substring(item.indexOf(",") + 1)}
              </span>
            ) : (
              <span>{item}</span>
            )}
            {/* <img
              src={locationIconColor8F98A3}
              className="height20 marginLeft15"
              alt=""
            /> */}
          </div>
        );
      });
    }

    return (
      <div className="maxWidth95Percent paddingBottom60 tables providerList">
        <h1>Service Providers</h1>
        {/* {dataToShow && this.state.windowSize.innerWidth >= 768 ? ( */}
        <div
          className={
            this.state.page === "showOrgDetails"
              ? "hideTable"
              : // this.state.windowSize.innerWidth < 768
              this.state.windowSize[0] < 768
              ? "width100vw"
              : ""
          }
        >
          <div
            className={this.state.windowSize[0] < 768 ? "hideTable" : "flexRow"}
          >
            <div className="flexRow">
              {/* <TextField
                id="searchbar-TextField"
                name="searchbar"
                type="text"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="search-icon"
                        onClick={this.handleProviderSearchSubmit}
                        onKeyDown={this.handleProviderSearchSubmit}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={this.handleProviderSearch}
              /> */}
              <input
                className="paddingLeft8 paddingRight8 width300 height35"
                onChange={(e) => this.handleProviderSearch(e)}
                onKeyDown={(e) => this.handleProviderSearch(e)}
                value={this.state.searchInput}
              />
              <div className="backgroundColorBlue0 colorWhite width44 height35 flexRow alignCenter justifyCenter marginLeftMinus5 borderRadiusRight5">
                <Search onClick={this.handleProviderSearchSubmit} />
              </div>
            </div>
            <p className="paddingLeft8 marginBottom5 marginTop5">
              Search for a specific organization or use the filters below.
            </p>
          </div>
          <MaterialTable
            tableRef={this.tableRef}
            data={dataToShow}
            title=""
            icons={tableIcons}
            options={{
              search: false,
              searchFieldAlignment: "left",
            }}
            // onSearchChange={(e) => {
            //   console.log("search", e);
            //   // e is the value of the input from user;
            //   // future iteration: set e to state so we can pass to parent/child for save a search and saved searches
            // }}
            columns={
              // this.state.windowSize.innerWidth >= 768
              this.state.windowSize[0] >= 768
                ? [
                    {
                      title: "Organization",
                      field: "provider_name",
                      // field: "name",
                      align: "left",
                      defaultSort: "asc",
                      sorting: true,
                      customSort: (a, b) => {
                        let textA = a.provider_name.toUpperCase();
                        let textB = b.provider_name.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      },
                      render: (rowData) => (
                        <div className="flexRow alignCenter">
                          <div
                            className="orgAvatars borderRadius5 marginRight20"
                            style={{
                              borderTopColor: rowData.avatar_colors
                                ? rowData.avatar_colors[0]
                                : "#808080",
                              borderLeftColor: rowData.avatar_colors
                                ? rowData.avatar_colors[0]
                                : "#808080",
                              borderRightColor: rowData.avatar_colors
                                ? rowData.avatar_colors[1]
                                : "#D3D3D3",
                              borderBottomColor: rowData.avatar_colors
                                ? rowData.avatar_colors[1]
                                : "#D3D3D3",
                            }}
                            // style={{
                            // 	borderTopColor: "#808080",
                            // 	borderLeftColor: "#808080",
                            // 	borderRightColor: "#D3D3D3",
                            // 	borderBottomColor: "#D3D3D3",
                            // }}
                          />
                          <span>{rowData.provider_name}</span>
                        </div>
                      ),
                    },
                    {
                      title: "Types of Services",
                      // field: "typesOfServices",
                      field: "provider_services_offered",
                      sorting: false,
                      render: (rowData) =>
                        rowData.provider_services_offered.length > 0 &&
                        rowData.provider_services_offered.map((service, i) => {
                          return (
                            <p className="margin0" key={i}>
                              {service}
                            </p>
                          );
                        }),
                    },
                    {
                      title: "Clients Served",
                      // field: "whoWeHelp",
                      field: "org_for_who",
                      sorting: false,
                      render: (rowData) => (
                        <div className="flexRow justifySpaceBetween alignCenter hideColumnMobile">
                          <p className="marginRight11 marginBottom0">
                            {rowData.org_for_who ? rowData.org_for_who : "  "}
                          </p>
                          <img
                            src={rightArrowColorD5D8DD}
                            className="height17"
                            alt=""
                          />
                        </div>
                      ),
                    },
                  ]
                : [
                    {
                      title: "Organization",
                      field: "provider_name",
                      // field: "name",
                      align: "left",
                      defaultSort: "asc",
                      sorting: true,
                      customSort: (a, b) => {
                        let textA = a.provider_name.toUpperCase();
                        let textB = b.provider_name.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      },
                      render: (rowData) => (
                        <div className="flexRow">
                          <div
                            className="orgAvatars borderRadius5 marginRight20"
                            style={{
                              borderTopColor: rowData.avatar_colors
                                ? rowData.avatar_colors[0]
                                : "#808080",
                              borderLeftColor: rowData.avatar_colors
                                ? rowData.avatar_colors[0]
                                : "#808080",
                              borderRightColor: rowData.avatar_colors
                                ? rowData.avatar_colors[1]
                                : "#D3D3D3",
                              borderBottomColor: rowData.avatar_colors
                                ? rowData.avatar_colors[1]
                                : "#D3D3D3",
                            }}
                          />
                          <div className="flexGrow2">
                            <p>{rowData.provider_name}</p>
                            <p className="margin0">
                              {rowData.provider_services_offered.join(", ")}
                            </p>
                          </div>
                          <img
                            src={rightArrowColorD5D8DD}
                            className="height17"
                            alt=""
                          />
                        </div>
                      ),
                    },
                  ]
            }
            onRowClick={(e, rowData) =>
              this.setState({
                page: "showOrgDetails",
                orgDetailsToShow: rowData,
              })
            } // KEEP "e" AS A PARAMETER ELSE ERROR!
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div className="fontSize18 lineHeight20 colorBlack2">
                    No organizations found which match your filters. <br></br>
                    <br></br> Change your filters or{" "}
                    <button
                      className="btnNoStyle btnNoFocus fontSize18 lineHeight20 colorBlack2 underline"
                      onClick={() => this.clearAllFilters()}
                    >
                      clear all filters now.
                    </button>
                  </div>
                ),
              },
            }}
            components={{
              Toolbar: React.memo((props) => {
                // console.log(props);
                return (
                  <div className="toolbarMobile">
                    {/* <MTableToolbar {...props} /> */}
                    <ProviderTableToolbar
                      parentState={this.state}
                      isAuthenticated={this.state.appContext.isAuthenticated}
                      tableRefCurrentObj={this.tableRefCurrentObj}
                      handleCheckBoxChange={this.handleCheckBoxChange}
                      handleCheckBoxSave={this.handleCheckBoxSave}
                      handleAgeChange={this.handleAgeChange}
                      handleAgeSave={this.handleAgeSave}
                      // handleLocationChange={this.handleLocationChange}
                      handleLocationSave={this.handleLocationSave}
                      openFilterOverlay={this.openFilterOverlay}
                      closeFilterOverlay={this.closeFilterOverlay}
                      clearOneSetOfFilters={this.clearOneSetOfFilters}
                      updateCurrentFilters={this.updateCurrentFilters}
                      clearAllFilters={this.clearAllFilters}
                      filters={this.state.filters}
                      openSaveSearchModal={this.openSaveSearchModal}
                      onChangeTextInput={this.onChangeTextInput}
                      cancelSavedSearch={this.cancelSavedSearch}
                      saveThisSearch={this.saveThisSearch}
                      closeSearchSaveSuccess={this.closeSearchSaveSuccess}
                      openSavedSearchesModal={this.openSavedSearchesModal}
                      allSavedSearches={allSavedSearches}
                      saveAllSearches={this.saveAllSearches}
                      savedFiltersClick={this.savedFiltersClick}
                      closeSavedSearchesModal={() =>
                        this.setState({ savedSearchesModalOpen: false })
                      }
                      openSavedSearchItem={this.openSavedSearchItem}
                      closeSavedSearchItem={this.closeSavedSearchItem}
                      setSavedSearchObj={this.setSavedSearchObj}
                      // getSavedSearchObjTitle={this.getSavedSearchObjTitle}
                    />
                    <div
                      className={
                        this.state.windowSize[0] > 768 ? "hideTable" : "flexRow"
                      }
                    >
                      <div className="flexRow">
                        <input
                          className="paddingLeft8 paddingRight8 width300"
                          onFocus={(e) => {
                            this.setState({
                              searchSubmit: "",
                              searchInput: "",
                            });
                            e.target.select();
                          }}
                          onChange={(e) => this.handleProviderSearch(e)}
                          // onKeyDown={(e) => this.handleProviderSearch(e)}
                          value={this.state.searchInput}
                        />
                        <div
                          className={
                            this.state.windowSize[0] > 768
                              ? "backgroundColorBlue0 colorWhite width44 height35 flexRow alignCenter justifyCenter marginLeftMinus5 borderRadiusRight5"
                              : "backgroundColorBlue0 colorWhite width44 height35 flexRow alignCenter justifyCenter marginLeftMinus45 borderRadiusRight5"
                          }
                        >
                          <Search onClick={this.handleProviderSearchSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }),
            }}
          />
        </div>

        {/* On click of table row, show all of that orgs details */}
        {this.state.page === "showOrgDetails" && orgDetailsToShow ? (
          <OrgDetailPage
            orgDetailsToShow={orgDetailsToShow}
            orgDetailsAddresses={orgDetailsAddresses}
            backToProviderTable={() => this.setState({ page: "table" })}
            windowSize={this.state.windowSize}
          />
        ) : null}
      </div>
    );
  }
}

ServiceProviders.contextType = AppContext;
