import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useEffect, useRef, useState } from "react";

import DoneIcon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChildModal = (props) => {
  //   console.log(props, "child");
  // savedFiltersClick in props will open saved filters, please handle this in an on click

  // have state for the textfield; have this somehow linked to parent; declare state in child or parent? either way, data must be passed from child to parent
  const [savedSearchItem, setSavedSearchItem] = useState(props.obj);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const handleOpen = () => {
  //   setOpen(props.show);
  //   props.show();
  // };
  // useEffect hook to select text when modal is opened
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (props.editSavedSearchTitleModalOpen && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  }, [props.editSavedSearchTitleModalOpen]);

  //   const handleKeyDown = (event) => {
  //     if (event.key === "Delete") {
  //       // clears the text when the Delete key is pressed
  //       setEditTitleValue("");
  //     } else if (event.key === "ArrowLeft") {
  //       event.preventDefault();
  //       const textField = document.getElementById("edit-title-field");
  //       if (textField) {
  //         // moves the cursor to the beginning
  //         textField.selectionStart = 0;
  //         textField.selectionEnd = 0;
  //       }
  //     } else if (event.key === "ArrowRight") {
  //       event.preventDefault();
  //       const textField = document.getElementById("edit-title-field");
  //       if (textField) {
  //         // moves the cursor to the end
  //         textField.selectionStart = editTitleValue.length;
  //         textField.selectionEnd = editTitleValue.length;
  //       }
  //     }
  //   };

  const handleTitleChange = (event) => {
    setSavedSearchItem({
      ...savedSearchItem,
      isEditing: true,
      search_name: event.target.value,
    });
    // console.log(savedSearchItem);
    // must pass in an object into this function call:
    // props.getSavedSearchObjTitle(savedSearchItem);
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={props.savedSearchItemModalOpen}
        onClose={() => props.closeSavedSearchItem()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="bold">Saved Searches</h2>
          <h3 className="marginBottom10">Rename This Search</h3>
          <TextField
            id="edit-saved-search-title-input"
            // label="Edit Title"
            variant="outlined"
            // look for ways to instead of using a placeholder, make the text appear dark and once the user clicks on the field, the whole text gets highlighted and the user can delete the text if they want or use arrow key to get to beginning or end of the highlighted text to add more text..
            // placeholder={props.obj.search_name}
            defaultValue={props.obj.search_name}
            // find a way to use setEditTitleValue to update the value of editTitleValue
            onChange={(e) => handleTitleChange(e)}
            fullWidth
            inputProps={{
              onClick: (event) => {
                event.target.focus();
                event.target.select();
              },
              // selects the entire text when the field is focused
              onFocus: (event) => event.target.select(),
              // onMouseUp: (event) => event.preventDefault()
            }}
          />
          <p className="marginTop10 marginBottom15 fontSize16">
            Filters:{" "}
            {savedSearchItem.search_filter_configuration.length > 0
              ? savedSearchItem.search_filter_configuration.join(", ")
              : ""}
          </p>
          {/* comment back in for v2 launch */}
          {/* <Button
            sx={{
              pl: 0,
              mb: "16px !important",
              textTransform: "capitalize",
              textDecoration: "underline !important",
              color: "#0645ad !important",
              fontWeight: "bold",
            }}
            onClick={() => {
              // close the modal, isEditing set, pass item to parent(s) to send to backend
              savedSearchItem.isEditing = true;
              props.closeSavedSearchItem(savedSearchItem);
              //  filters are activated, Save New Filters button shows inactive and lights up when there are changes in the search filters.
              // after click of save new filters button, button disappears, should user see modal confirming save? 
            }}
          >
            Change Filter Settings
          </Button> */}
          <div className="flexRow justifySpaceBetween">
            <button
              className="btnOffWhite border1colorBlack1 colorBlack3 fontSize14 lineHeight16 borderRadius4 padding12-16"
              onClick={() => {
                savedSearchItem.isEditing = false;
                props.closeSavedSearchItem(savedSearchItem);
              }}
            >
              Cancel
            </button>
            <Button
              variant="contained"
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <DoneIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onClick={() => {
                // close the modal, isEditing set, pass item to parent(s) to send to backend
                savedSearchItem.isEditing = true;
                props.closeSavedSearchItem(savedSearchItem);

                // reopen saved searches modal
                props.openSavedSearchesModal();
              }}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default ChildModal;
