import React, { useState } from "react";
import { API, Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import {
  TextField,
  IconButton,
  InputAdornment,
  Modal,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useAppContext } from "../../useContext";
import ForgotPassword from "./ForgotPassword";
import mixpanel from "mixpanel-browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const Login = (props) => {
  // console.log(props);
  const [values, setValues] = useState({
    email: "",
    showPassword: false,
  });
  const history = useHistory();
  const {
    userHasAuthenticated,
    setUserId,
    setProvider,
    setProviderId,
    setEmail,
    setUsername,
    setColors,
    setRole,
    setIsVisible,
  } = useAppContext();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [tempPasswordModal, setTempPasswordModal] = useState(false);
  const [errorModal, setErrorModal] = useState({ open: false, message: "" });

  mixpanel.track("P Login PgVw", {
    "Event Type": "Page View",
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const callBack = () => {
    setForgotPassword(false);
  };

  const handleSendCodeClick = async () => {
    try {
      API.post("referall-provider", "resettemppassword", {
        body: {
          email: values.email.toLowerCase(),
          resendTempPassword: values.resendTempPassword,
        },
      }).then((response) => {
        // console.log(response);
        if (response.resendTempPassword === false) {
          setValues({ ...values, resendTempPassword: false });
        }
      });
    } catch (e) {
      // console.log(e); // "User password cannot be reset in the current state."
      if (e.message === "User password cannot be reset in the current state.") {
        values.resendTempPassword = true;
      } else {
        // alert(e.message);
        setErrorModal({ open: true, message: e.message });
        mixpanel.track("P ConsoleLog Error", {
          "Event Type": "API Call",
          "Error Message": e,
          "API Name": "resettemppassword",
          "File Name": "Login.js",
          "File Line": 101,
        });
      }
    }
  };

  const handleSubmit = () => {
    // if (values.password.startsWith("Ac12!")) {
    //   setTempPasswordModal(true);
    // } else {
    Auth.signIn(values.email.toLowerCase().trim(), values.password)
      .then((response) => {
        // console.log(response);
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          // console.log("new user");
          setTempPasswordModal(true);
          setValues({ ...values, resendTempPassword: true });
        } else {
          // console.log("existing user");
          setUserId(response.username);
          setEmail(response.attributes.email);
          API.post("referall-provider", "getuserprovider", {
            body: {
              // email: response.attributes.email,
              // email: values.email,
              user_id: response.username,
            },
          })
            .then((res) => {
              // console.log(res);
              setProvider(res[0].provider);
              setProviderId(res[0].provider_id);
              setUsername(res[0].username);
              setColors(res[0].avatar_colors);
              setRole(res[0].role);
              setIsVisible(res[0].is_visible);
              // localStorage.setItem("provider", response[0].provider);
              // console.log(response);
              userHasAuthenticated(true);
              mixpanel.track("P SignIn Submit", {
                "Event Type": "User Action",
              });
              history.push("/serviceproviders");
            })
            .catch(async (err) => {
              console.log(err);
              mixpanel.track("P ConsoleLog Error", {
                "Event Type": "API Call",
                "Error Message": err,
                "API Name": "getuserprovider",
                "File Name": "Login.js",
                "File Line": 151,
              });
              await Auth.signOut();
              // alert(
              //   "You don't have an assigned organization, please contact resourcefull@anniecannons.com."
              // );
              setErrorModal({
                open: true,
                message:
                  "You don't have an assigned organization, please contact resourcefull@anniecannons.com.",
              });
            });
        }
      })
      .catch((err) => {
        // console.log(err.code, err.message);
        if (err.message === "Network error") {
          // alert("Please try again.");
          setErrorModal({
            open: true,
            message: "Please try again.",
          });
        } else if (
          err.message ===
          "Temporary password has expired and must be reset by an administrator."
        ) {
          setTempPasswordModal(true);
          setValues({ ...values, resendTempPassword: true });
        } else if (err.code === "NotAuthorizedException") {
          // alert("You entered the incorrect password. Please try again.");
          setErrorModal({
            open: true,
            message: "You entered the incorrect password. Please try again.",
          });
        } else if (err.code === "UserNotFoundException") {
          // alert(
          //   "You have entered an invalid e-mail address. Please try again."
          // );
          setErrorModal({
            open: true,
            message:
              "You have entered an invalid e-mail address. Please try again.",
          });
        } else if (err.code === "InvalidParameterException") {
          // alert("Please enter a password.");
          setErrorModal({
            open: true,
            message: "Please enter a password.",
          });
          // alert("Please check username or password."); // used .trim() to get rid of space after email
        } else if (err.code === undefined) {
          // alert("Fields can not be empty.");
          setErrorModal({
            open: true,
            message: "Fields can not be empty.",
          });
          // } else {
          //   alert(
          //     "Please contact resourcefull@anniecannons.com for help logging in."
          //   );
        }
      });
    // }
  };

  return (
    <div className="login-page">
      <h1 className="marginBottom60" style={{ textAlign: "center" }}>
        ResourceFull Sign In
      </h1>
      <div className="edit-settings">
        <form size="large">
          {/* <ErrorMessage error={errors.orgname} /> */}
          <div className="marginBottom36 flexColumn">
            <label>Email</label>
            <TextField
              className="paddingLeft8"
              type={values.email}
              placeholder="example@email.com "
              onChange={handlePasswordChange("email")}
              value={values.email}
              variant="outlined"
            />
            {/* <ErrorMessage error={errors.orgname} /> */}
          </div>

          <div className="flexColumn">
            <label className="marginBottom0">Password</label>
            <TextField
              className="paddingLeft8"
              type={values.showPassword ? "text" : "password"}
              onChange={handlePasswordChange("password")}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon-eye-login paddingRight0"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <p
            className="marginTop0 marginBottom60 fontSize14 button underline"
            onClick={handleForgotPassword}
          >
            Forgot your password?/Reset password
          </p>

          <ForgotPassword
            open={forgotPassword}
            callBack={callBack}
            windowSize={props.windowSize}
          />
        </form>
      </div>

      <div style={{ textAlign: "center" }}>
        {" "}
        <button
          className={
            values.email &&
            values.password &&
            values.password.toString().length >= 8
              ? "button-signin"
              : "button-signin opacity3"
          }
          disabled={
            values.email &&
            values.password &&
            values.password.toString().length >= 8
              ? false
              : true
          }
          onClick={handleSubmit}
        >
          Sign In
        </button>
      </div>
      <p className="marginBottom60 ">
        New ResourceFull User? <Link to="/providersignup">Sign Up here</Link>
      </p>
      {/* modal shows when user clicks resend temporary password link */}
      <Modal
        open={tempPasswordModal}
        onClose={() => {
          setTempPasswordModal(false);
        }}
        aria-labelledby="temp-password-modal"
        aria-describedby="temp-password-modal"
      >
        <Box
          sx={style}
          className={props.windowSize[0] < 480 ? "width75vw" : "width400"}
        >
          <Typography className="modal-heading" variant="h6" component="h2">
            Resend Temporary Password
          </Typography>
          <Typography className="modal-text">
            We need to email you a new temporary password to set up your
            account. The password will expire in 7 days.
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-first textDecorationUnderline"
            onClick={() => {
              setTempPasswordModal(false);
              handleSendCodeClick();
              history.push("/newuser");
            }}
          >
            Send Password
          </Typography>
          <Divider />
          <Typography
            className="modal-button-stacked modal-button-last textDecorationUnderline"
            onClick={() => {
              setTempPasswordModal(false);
            }}
          >
            Cancel
          </Typography>
        </Box>
      </Modal>
      {/* Error Modal */}
      {errorModal.open && (
        <Modal
          open={errorModal.open}
          onClose={() => {
            console.log(errorModal);
            setErrorModal({ ...errorModal, open: false });
          }}
          aria-labelledby="temp-password-modal"
          aria-describedby="temp-password-modal"
        >
          <Box
            sx={style}
            className={props.windowSize[0] < 480 ? "width75vw" : "width400"}
          >
            <Typography className="modal-text">{errorModal.message}</Typography>
            <Typography
              className="modal-button-stacked modal-button-last textDecorationUnderline"
              onClick={() => {
                setErrorModal({ ...errorModal, open: false });
              }}
            >
              OK
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Login;
