import React, { useState } from "react";
// import { Link } from "react-router-dom";
import "../Help/help.css";
// import ErrorMessage from "../../Components/errormsg";
// import ReactPlayer from "react-player";
import helpTutorial from "../../Assets/helpTutorial.png";
import mixpanel from "mixpanel-browser";

// imports originally here but not used
// import { API } from "aws-amplify";
// import { useAppContext } from "../../useContext";

// for accordion drop down
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Help1 = () => {
  // closing accordion when another is opened
  const [expanded, setExpanded] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
  });
  mixpanel.track("P Help PgVw", {
    "Event Type": "Page View",
  });
  // const handleExpansionChange = (panel) => (event, isExpanded) => {
  //   console.log(panel, isExpanded); // "panelX", true
  //   setExpanded(isExpanded ? panel : false); // true ? "panelX" : false
  // };

  const handleExpansionChange = (panel) => (event, isExpanded) => {
    // console.log(panel, isExpanded, event); // "panelX", true/false
    setExpanded(
      isExpanded
        ? { ...expanded, [panel]: true }
        : { ...expanded, [panel]: false }
    );
  };

  return (
    <div className="helpTutorialsSection">
      <h1>Help &#38; Tutorials</h1>
      <br></br>
      <h2>Overview of the ResourceFull Platform</h2>{" "}
      {/* this is the utube video */}
      {/* <ReactPlayer url="https://www.youtube.com/watch?v=mwqIZ0eafgQ" />
      <Link className="link-instructions">
        <p>Read step-by-step instructions instead</p>
      </Link> */}
      {/* place image in here  */}
      <img
        className="help-tutorial-img"
        src={helpTutorial}
        alt=" help tutorial info "
      />
      <h2>FAQs</h2>
      <div className="helpAccordionSection">
        <Accordion
          expanded={expanded.panel1}
          onChange={handleExpansionChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>
              <strong>What type of organization is ResourceFull?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className="client-data">
                ResourceFull is a software product built by AnnieCannons, a
                registered nonprofit organization. Powered by code and humans we
                bring people in need of services with those who provide them.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.panel2}
          onChange={handleExpansionChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>
              <strong>Is ResourceFull free?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className="client-data">
                ResourceFull will always be free to people seeking services.
                Currently there are no costs to providers. All features
                currently on the app will remain free to providers.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.panel3}
          onChange={handleExpansionChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>
              <strong>
                Why can’t my organization receive intakes on ResourceFull?
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className="client-data">
                Some organizations have chosen or been advised to not receive
                intakes on the platform. This may be because of internal intake
                processes, capacity or because your organization is a covered
                entity. If you think that should change, contact us.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Help1;
