import React, { useState, useEffect, useRef } from "react";
import useAutosizeTextArea from "../../Components/useAutosizeTextArea";
import { Link } from "react-router-dom";
import editbutton from "../../Assets/editbutton.png";
import { Button } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import { useAppContext } from "../../useContext";
import mixpanel from 'mixpanel-browser';

const ColorButton = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "40px",
    textAlign: "left",
    textTransform: "none",
    fontFamily: "Arial",
    fontSize: "smaller",
    fontWeight: "bolder",
    letterSpacing: "Open Sans",
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#A10070",
    "&:hover": {
      backgroundColor: "#A10070",
    },
  },
}))(Button);

const Details = (props) => {
  const [profileData, setProfileData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(true);
  const { providerId } = useAppContext();
  const windowSize = props.windowSize;
  //handles textarea auto-resizing
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const textAreaRef3 = useRef(null);
  useAutosizeTextArea(textAreaRef1.current, profileData.org_for_who);
  useAutosizeTextArea(textAreaRef2.current, profileData.org_our_services);
  useAutosizeTextArea(textAreaRef3.current, profileData.org_we_are);

  var handleEditButtonClick = () => {
    setFormSubmitted(!formSubmitted);
  };

  var handleChange = (event) => {
    var newData = profileData;
    newData[event.target.name] = event.target?.value;
    setProfileData(newData);
  };

  useEffect(() => {
    API.post("referall-provider", "getproviderprofiledata", {
      body: {
        provider_id: providerId,
      },
    }).then((response) => {
      // console.log(response[0]); // ! Data is coming into the front end as a nested array so will have to select nested object with [0]
      setProfileData(response[0]);
    });
  }, [providerId]);

  var handleSubmit = () => {
    // console.log(profileData);
    API.post("referall-provider", "addproviderprofiledetails", {
      body: {
        profileData: profileData,
        provider_id: providerId,
      },
    })
      .then((response) => {
        // console.log(response);
        mixpanel.track("P OurProfDetailsUpdate Save", {
          "Event Type": "User Action",
        });
      })
      .catch((error) => {
        console.log(error);
        mixpanel.track("P ConsoleLog Error", {
          "Event Type": "API Call",
          "Error Message": error,
          "API Name": "addproviderprofilebasics",
          "File Name": "Details.js",
          "File Line": 85,
        });
      });
  };

  return (
    <>
      {/*****************************DETAILS PAGE******************************/}
      {formSubmitted === true ? (
        <div className="detailsform">
          <div className="">
            <p>
              <strong>Who We Help</strong>
            </p>
            <p className="pdetails">{profileData.org_for_who}</p>
            <br></br>
            <p>
              <strong>Our Services</strong>
            </p>
            <p className="pdetails">{profileData.org_our_services}</p>
            <br></br>
            <p>
              <strong>Who We Are</strong>
            </p>
            <p className="pdetails">{profileData.org_we_are}</p>
          </div>
          <div className="edit-button">
            <Button className="edit-button" onClick={handleEditButtonClick}>
              <figure>
                <img src={editbutton} alt="Icon" />
                <figcaption>Edit</figcaption>
              </figure>
            </Button>
          </div>
        </div>
      ) : (
        //   ********************EDIT DETAILS COMPONENT****************************************
        <div className="detailseditform">
          <div>
            <form size="large">
              <p>
                <label htmlFor="org_for_who">
                  <strong>Who We Help</strong>
                </label>
              </p>
              <textarea
                ref={textAreaRef1}
                name="org_for_who"
                id="org_for_who"
                // ref={register({ maxLength: 400 })}
                onChange={handleChange}
                rows={4}
                cols={windowSize.innerWidth >= 768 ? "80" : ""}
              >
                {profileData.org_for_who}
              </textarea>
              <p>
                <label htmlFor="org_our_services">
                  <strong>Our Services</strong>
                </label>
              </p>
              <textarea
                ref={textAreaRef2}
                name="org_our_services"
                id="org_our_services"
                // ref={register({ maxLength: 400 })}
                onChange={handleChange}
                rows={4}
                cols={windowSize.innerWidth >= 768 ? "80" : ""}
              >
                {profileData.org_our_services}
              </textarea>
              <p>
                <label htmlFor="org_we_are">
                  <strong>Who We Are</strong>
                </label>
              </p>
              <textarea
                ref={textAreaRef3}
                name="org_we_are"
                id="org_we_are"
                //className=" marginBottom20Percent "
                onChange={handleChange}
                // ref={register({ maxLength: 400 })}
                // rows="15"
                rows={15}
                cols={windowSize.innerWidth >= 768 ? "80" : ""}
              >
                {profileData.org_we_are}
              </textarea>
            </form>
            <div
              className={
                windowSize.innerWidth >= 768
                  ? "displayBlock flexRow justifySpaceBetween width80Percent"
                  : "flexRow justifySpaceBetween"
              }
            >
              <Link to="/ourprofile">
                <button className="cancel-button">Cancel</button>
              </Link>
              <ColorButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleEditButtonClick();
                  handleSubmit();
                }}
              >
                Save
              </ColorButton>
              {/* <div className="edit-button">
							{" "}
							<Button className="edit-button-edit" disabled>
								<figure>
									<img src={editbuttonedit} alt="Icon" />
									<figcaption>Edit</figcaption>
								</figure>
							</Button>
						</div> */}
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default Details;
