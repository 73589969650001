import React, { useState, useEffect } from "react";

import "../../Pages/People/people.css";
import Details from "./Details";
import Basics1 from "./Basics1";
import Basics1Mobile from "./Basics1Mobile";
import Nonpublic from "./Nonpublic";
import mixpanel from "mixpanel-browser";

const Ourprofilenav = () => {
  const [active, setActive] = useState("FirstComponent");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  mixpanel.track("P OurProf PgVw", {
    "Event Type": "Page View",
  });

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;

    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="our-profile">
        <h1>Our Profile</h1>
        <nav className="participant-navbar borderBottom1Grey0  borderBottom1Grey0-mobile ">
          <div
            // className={
            //   active === "FirstComponent" && active && <Basics1 />
            //     ? "underline-blue "
            //     : ""
            // }
            className={
              active === "FirstComponent" &&
              active &&
              (windowSize.innerWidth >= 768 ? <Basics1 /> : <Basics1Mobile />)
                ? "underline-blue "
                : ""
            }
            onClick={() => setActive("FirstComponent")}
          >
            BASICS
          </div>

          <div
            className={
              active === "SecondComponent" && <Details />
                ? "underline-blue "
                : ""
            }
            onClick={() => setActive("SecondComponent")}
          >
            DETAILS
          </div>
          <div
            className={
              active === "ThirdComponent" && (
                <Nonpublic windowSize={windowSize} />
              )
                ? "underline-blue  "
                : ""
            }
            onClick={() => setActive("ThirdComponent")}
          >
            <span>NON PUBLIC INFORMATION</span>
          </div>
        </nav>
        <div className="paddingTop25">
          {windowSize.innerWidth >= 768
            ? active === "FirstComponent" && <Basics1 />
            : active === "FirstComponent" && <Basics1Mobile />}
          {/* {active === "FirstComponent" && <Basics1 windowSize={windowSize} />} */}
          {active === "SecondComponent" && <Details windowSize={windowSize} />}
          {active === "ThirdComponent" && <Nonpublic windowSize={windowSize} />}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Ourprofilenav;
