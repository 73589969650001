// import React, { useState } from 'react'
import "../ProviderSignUp/ProviderSignUp.css";


export const Eligibility = (props) => {

    return (
        <div>
            <h1>Provider Eligibility</h1>
        </div>
    )
}